import React, {useEffect, useState} from 'react';
import ApiDataBase from 'server'
import UseContext from "hooks/useContext";
import {InformationIcon} from "assets/icons";
import Tooltip from "components/tooltips/Tooltips";
import Chip from "components/chip/chip";

const Salary = () => {
    const [smic, setSmic] = useState('')
    const [defaultValue, setDefaultValue] = useState(false)
    const {data, setData} = UseContext()

    const handleSelect = (e) => {
        setDefaultValue(!defaultValue)
    }

    useEffect(() => {
        ApiDataBase.getMinimumWage(
            (data) => {
                if(data?.hourly?.gross){
                    setSmic(data.hourly.gross)
                }
            },
            (err) => {
                console.log(err)
            }
        )
    }, [])

    useEffect(() => {
        if (defaultValue) {
            setData(prevData => ({
                ...prevData,
                salary: smic
            }))
        } else if (data.salary.length < 0) {
            setData(prevData => ({
                ...prevData,
                salary: ''
            }))
        }
    }, [defaultValue])

    return (
        <div className={'flex flex-col gap-4 p-8'}>
            <div className='ft-xl font-medium'>
                Rémunération de l’intérimaire
            </div>
            <p className={'ft-sm text-gray-500'}>
                Indiquez ci-dessous les informations permettant le calcul de la rémunérations de vos intérimaires.
            </p>
            <div className="relative">
                <input
                    name="metier"
                    pattern="([A-Z])[\w+.]{1,}"
                    value={data.salary}
                    onChange={(e) => setData(prevData => ({
                        ...prevData,
                        salary: e.target.value
                    }))}
                    className="w-full h-10 pl-10 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                    type="text"
                />
                <div
                    className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                    h/€
                </div>
                <Tooltip
                    text={'La rémunération comprend le salaire ou traitement de base et tous les autres avantages et accessoires payés directement ou indirectement, en espèces ou en nature.'}
                    placement="bottom"
                >
                    <div
                        className="absolute inset-y-0 right-0 z-20 flex items-center px-2 cursor-pointer">

                        <InformationIcon color={'#2CAFFF'} wh={15}/>
                    </div>
                </Tooltip>
            </div>
            <div className={'w-44'}>
                <Chip icon={true} select={defaultValue}
                      type={defaultValue ? 'SELECTON' : 'SELECTOFF'}
                      onClick={handleSelect}>SMIC : {smic}€/h</Chip>

            </div>
        </div>
    );
};

export default Salary;