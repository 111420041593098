import React from 'react';
import Thead from "components/table/components/thead";
import AdminCard from "components/card/admin";

const TableAdmin = ({champsHeader, champsBody}) => {
    return (
        <>
            <div className={'mb-auto'}>
                <table className="table-auto w-full text-gray-500 border-collapse  ft-sm">
                    <thead>
                    <Thead champsHeader={champsHeader}/>
                    </thead>
                    <tbody>
                    {champsBody.map(item=>
                        <AdminCard key={item._id} item={item} />
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TableAdmin;