import React from 'react';
import PageForm from "components/pageForm";
import {Outlet} from "react-router-dom";
import FilesSubNav from "components/subNav/files.subNav";

const Files = () => {
    return (
        <>
            <PageForm>
                <div className="flex items-center justify-between col-span-12 bg-white   top-16 ">
                    <FilesSubNav/>
                </div>
                <div className="col-span-12 mt-8 mb-28 px-8">
                    <Outlet/>
                </div>
            </PageForm>
        </>
    );
};

export default Files;