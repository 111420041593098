import {createContext, useState, useEffect} from "react";
import {LangFr} from "assets/locales/fr";
import {LangEn} from "assets/locales/en";
import {Language as LanguageManager} from "assets/locales/fLang";
import {useLocation} from "react-router-dom";
import UseModal from "components/modal/useModal";

const roadContext = createContext({})

function ContextProvider({children}) {
    const [userDatas, setUserDatas] = useState([])
//     ********     Section Info Role       **********
    const [roleUser,setRoleUser]=useState(localStorage.getItem('rUser'))
// *******   Section Sidebar   *******
    const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
    );
// *******   Section Refresh   *******
    const [refreshUser, setRefreshUser] = useState(false)
    const refreshLoadUser = () => {
        setRefreshUser(!refreshUser)
    }
    const [refreshWorker, setRefreshWorker] = useState(false)
    const refreshLoadWorker = () => {
        setRefreshWorker(!refreshWorker)
    }
    const [refreshCompany, setRefreshCompany] = useState(false)
    const refreshLoadCompany = () => {
        setRefreshCompany(!refreshCompany)
    }
// *******   Section Language   *******
//    instancie le manager
    const language = new LanguageManager()
//    charge les fichiers de langues (ils seront chargés qu'une fois)
    language.loadLanguage(LangFr)
    language.loadLanguage(LangEn)
//    spécifie la langue en cours
    language.setLanguage('fr')

// *******   Section Modal   *******
    const [modalShow, setModalShow] = useState(false)
    const [modalType, setModalType] = useState('')

// *******   Section URL   *******
    const route = useLocation()
    const {pathname} = route
    //1 - interimaire 2- entreprise
    const [typeNav, setTypeNav] = useState(1)
    const [email, setEmail] = useState('')
    const [name, setName] = useState({
        firstname: '',
        lastname: ''
    })
    useEffect(() => {
            if (pathname.includes('entreprise')) setTypeNav(2)
            else setTypeNav(1)
        }
        , [pathname])

    const [auth, setAuth] = useState({})
    const updateUser = (pToken = '-1', pRole) => {
        if (pToken !== '-1') {
            localStorage.setItem('xsrfToken', pToken)
            localStorage.setItem('rUser', pRole)
        }
    }

// *******   Section OBJ   *******
    const [expID, setExpID] = useState('')
    const [objID, setObjID] = useState('')
    const [objType, setObjType] = useState('')

// ****** Section Form Create Mission ******
    const [page, setPage] = useState(0)
    const [missionCostEstimate, setMissionCostEstimate] = useState(0)
    const [data, setData] = useState({
        activity: '',
        qualification: '',
        tools: [],
        licences: [],
        languages: [],
        contact_name: '',
        contact_poste: '',
        location: '',
        dateStart: '',
        dateEnd: '',
        schedule: [],
        isScheduleCheck: true, // if the company choose to have a schedule
        isConfirmedSchedule: false, // if the company valid schedule with empty periods
        isScheduleRequired: false, // if the company have a 1 day mission
        users_invite: [],
        motif: '',
        other: '',
        other_interim: '',
        salary: '',
    })
    const [activeScheduleWeekDay, setActiveScheduleWeekDay] = useState([])
    const [updSchedule, setUpdSchedule] = useState(false)
    const [isDateValid, setIsDateValid] = useState(false)

    const handlePrev = () => setPage(prev => prev - 1)

    const handleNext = () => setPage(prev => prev + 1)

// *******   Section Info Company   *******
    const [companyData, setCompanyData] = useState([])
// *******   Section TimeSheet Company   *******
    const [timeCheck, setTimeCheck] =useState( {})
    const [refreshTime, setRefreshTime] = useState(false)
// *******   Section Signature contract Company   *******
    const [dataYousign,setDataYousign]=useState({})
    const [signatureLink, setSignatureLink] = useState('')
// *******  Section Admin Data      ******
    const [adminData,setAdminData]=useState({
        id:'',
        email:'',
        firstname:'',
        lastname:'',
        role:''
    })
    const [staffData,setStaffData]=useState([])
    const [refreshStaff,setRefreshStaff]=useState(false)
    const [staff,setStaff]=useState(0)
    const [standby,setStandby]=useState(0)
    const [rest,setRest]=useState(10)

    const [switchCompany, setSwitchCompany] = useState([])
    const [refreshSwitchCompany, setRefreshSwitchCompany] = useState(false)

    const [idTempWorker, setIdTempWorker] = useState('')
    const [idMission, setIdMission] = useState('')
    const [idTimeSheet, setIdTimeSheet] = useState('')

    const [updateRatingTempWorker, setUpdateRatingTempWorker] = useState(false)

    return (
        <roadContext.Provider value={{
            roleUser,
            language,
            modalShow, setModalShow, modalType, setModalType,
            typeNav,
            email, setEmail,
            name, setName,
            updateUser,
            auth, setAuth,
            refreshUser, setRefreshUser, refreshLoadUser,
            refreshWorker, setRefreshWorker, refreshLoadWorker,
            refreshCompany, setRefreshCompany, refreshLoadCompany,
            sidebarOpen, setSidebarOpen,
            sidebarExpanded, setSidebarExpanded,
            expID, setExpID,
            objID, setObjID, objType, setObjType,
            page, setPage, data, setData, handlePrev, handleNext, activeScheduleWeekDay, setActiveScheduleWeekDay, updSchedule, setUpdSchedule, isDateValid, setIsDateValid,
            companyData, setCompanyData,
            refreshTime, setRefreshTime,timeCheck, setTimeCheck,
            signatureLink, setSignatureLink,dataYousign,setDataYousign,
            adminData,setAdminData,staffData,setStaffData,refreshStaff,setRefreshStaff,
            staff,setStaff,standby,setStandby,rest,setRest,
            switchCompany, setSwitchCompany,
            refreshSwitchCompany, setRefreshSwitchCompany,
            missionCostEstimate, setMissionCostEstimate,
            userDatas, setUserDatas,
            idTempWorker, setIdTempWorker,
            idMission, setIdMission,
            idTimeSheet, setIdTimeSheet,
            updateRatingTempWorker, setUpdateRatingTempWorker,
        }}>
            {children}
        </roadContext.Provider>
    )
}

export {ContextProvider, roadContext}