import React from 'react';
import Modalheader from "components/modal/components/header";
import UseModal from "components/modal/useModal";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import UseContext from "hooks/useContext";
import ApiDatabase from 'server'
import {toast} from "react-toastify";

const DeleteRole = () => {
    const {toggle}=UseModal()
    const {adminData,setRefreshStaff,refreshStaff}=UseContext()
    const handleSubmit=(e)=>{
        e.preventDefault()
        ApiDatabase.deleteStaff({adminData},
            (data)=>{
            toast.success(`Suppression de ${adminData.firstname} ${adminData.lastname}`)
                setRefreshStaff(!refreshStaff)
                toggle()
            },
            (err)=>{
                console.log(err)
                toast.error('Une erreur est survenue')
            })
    }
    return (
        <>
         <Modalheader hide={toggle} hideShow={true}>
             Exclure un membre
         </Modalheader>
            <ModalBody>
                Êtes-vous sûr de vouloir exclure {adminData.firstname} {adminData.lastname} de votre équipe ?
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'}
                        onClick={handleSubmit}>Confirmer</Button>

            </ModalFooter>
        </>
    );
};

export default DeleteRole;