import React from 'react';
import SidebarUnitDesktop
    from "components/sidebar/components/SidebarUnitDesktop";
import {DashboardIcon} from "assets/icons";

const DashboardLink = ({pathname}) => {
    return (
        <SidebarUnitDesktop routeName={"Tableau de Bord"} routeSidebar={"/dashboard"}>
            <span className='shrink-0'>
                            <DashboardIcon wh={24} color={`${pathname.includes("dashboard") ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("dashboard") && "!text-[#FF9600]"
                }`}
            >
          {/*{language.getValue('sidebar.dashboard','Tableau de bord')}*/}
                Tableau de bord
        </span>
        </SidebarUnitDesktop>
    );
};

export default DashboardLink;