import {ChevronLeft, ChevronRight} from "../../assets/icons";

const Pagination = ({ currentPage, countPerPage, countTotal, totalPages, setCurrentPage }) => {

    const nbElementStart = (countPerPage * currentPage) - (countPerPage - 1)
    const nbElementEnd = (countPerPage * currentPage) > countTotal ? countTotal : (countPerPage * currentPage)

    return (
        <div className={'flex justify-between ft-sm'}>
            <div className={'my-auto'}>
                <div className={'text-gray-500'}>
                    Affichage de {nbElementStart}-{nbElementEnd} sur {countTotal} résultats
                </div>
            </div>
            <div className={'my-auto select-none'}>
                <div className={'flex'}>
                    <div className={'flex'}>
                        {
                            (currentPage !== 1) ? (
                                <button className={'w-9 h-9 rounded-l-lg border border-gray-200 flex justify-center items-center'} onClick={() => setCurrentPage(--currentPage)}>
                                    <ChevronLeft wh={16} color={'#4A5568'}/>
                                </button>
                            ) : (
                                <button className={'w-9 h-9 rounded-l-lg border border-gray-200 flex justify-center items-center'}>
                                    <ChevronLeft wh={16} color={'#4A5568'}/>
                                </button>
                            )
                        }
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page, index) => {
                            if(page === currentPage) {
                                return (
                                    <button key={index} className={'w-9 h-9 border border-gray-200 border-l-0 bg-yellow-500 text-white flex justify-center items-center'}>
                                        {page}
                                    </button>
                                )
                            } else if (page === 1 || page === totalPages || (page >= currentPage - 2 && page <= currentPage + 2)) {
                                return (
                                    <button key={index} className={'w-9 h-9 border border-gray-200 border-l-0 flex justify-center items-center'} onClick={() => setCurrentPage(page)}>
                                        {page}
                                    </button>
                                )
                            } else if (page === currentPage - 3 || page === currentPage + 3) {
                                return (
                                    <button key={index} className={'w-9 h-9 border border-gray-200 border-l-0 flex justify-center items-center'}>
                                        ...
                                    </button>
                                )
                            }
                        })}
                        {
                            (currentPage !== totalPages) ? (
                                <button className={'w-9 h-9 rounded-r-lg border border-gray-200 border-l-0 flex justify-center items-center'} onClick={() => setCurrentPage(++currentPage)}>
                                    <ChevronRight wh={16} color={'#4A5568'}/>
                                </button>
                            ) : (
                                <button className={'w-9 h-9 rounded-r-lg border border-gray-200 border-l-0 flex justify-center items-center'}>
                                    <ChevronRight wh={16} color={'#4A5568'}/>
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pagination;
