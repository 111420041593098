import React from 'react';
import {
    EmptyComingSoon,
    EmptyStateContract,
    EmptyStateMission,
    EmptyStatePayslip, EmptyStateTimeRecord, NoMobile
} from "assets/icons";
import Button from "components/button";
import {useNavigate} from "react-router-dom";


const EmptyStateCompany = ({type, children, ...props}) => {
    const navigate = useNavigate()
    const logo = {
        'mission_create': <div className={'mx-auto my-2'}><EmptyStateMission wh={256}/></div>,
        'mission_done': <div className={'mx-auto my-2'}><EmptyStateMission wh={256}/></div>,
        'mission_in_progress': <div className={'mx-auto my-2'}><EmptyStateMission wh={256}/></div>,
        'mission_soon': <div className={'mx-auto my-2'}><EmptyStateMission wh={256}/></div>,
        'contract': <div className={'mx-auto my-2'}><EmptyStateContract wh={256}/></div>,
        'contract_sign': <div className={'mx-auto my-2'}><EmptyStateContract wh={256}/></div>,
        'time_sheet': <div className={'mx-auto my-2'}><EmptyStateTimeRecord wh={256}/></div>,
        'time_sheet_litige': <div className={'mx-auto my-2'}><EmptyStateTimeRecord wh={256}/></div>,
        'time_sheet_check':<div className={'mx-auto my-2'}><EmptyStateTimeRecord wh={256}/></div>,
        'invoices_nopay': <div className={'mx-auto my-2'}><EmptyStatePayslip wh={256}/></div>,
        'invoices_pay': <div className={'mx-auto my-2'}><EmptyStatePayslip wh={256}/></div>,
        'coming': <div className={'mx-auto my-2'}><EmptyComingSoon wh={256}/></div>,
        'desktop': <div className={'mx-auto my-2'}><NoMobile wh={256}/></div>,
    }

    const title = {
        'mission_done': 'Aucune mission terminées',
        'mission_in_progress': 'Aucune mission en cours',
        'mission_soon': 'Aucune mission prochainement',
        'mission_search': 'Pas de missions disponibles',
        'time_sheet': 'Aucun relevé d’heure à compléter',
        'time_sheet_litige': 'Aucun relevé d’heure en litige',
        'time_sheet_check': 'Aucun relevé d’heure vérifié',
        'contract':'Aucun contrat signé disponible',
        'contract_sign':'Aucun contrat à signer disponible',
        'invoices_nopay': 'Aucune facture à régler',
        'invoices_pay':'Aucune facture réglée',
        'coming':'Fonctionnalité a venir',
        'desktop':'Jobbiz n\'est pas encore optimisée pour cette taille d\'écran',
        'schedule': 'Nouvelle fonctionnalité Planning',
        'schedule_missing': 'Planning non disponible',
        'mission_invitation': 'Aucun intérimaire à inviter',
    }
    const description = {
        'mission_create':'Vous vous apprêtez à créer une mission',
        'mission_done': 'Vous n’avez pas de mission terminées, les missions étant déjà passées s’afficheront ici.',
        'mission_in_progress': 'Vous n’avez pas de mission en cours disponible pour le moment',
        'mission_soon': 'Vous n’avez pas de mission à venir, les missions n’ayant pas encore débutées s’afficheront ici.',
        'contract': 'Il n’y a pas de contrats à signer disponible pour le moment.',
        'contract_sign':'Il n’y a pas de contrats signé disponible pour le moment.',
        'time_sheet': 'Il n’y a pas de relevés d’heures à vérifier disponibles pour le moment.',
        'time_sheet_litige':'Il n’y a pas de relevés d’heures en litige disponibles pour le moment.',
        'time_sheet_check':'Il n’y a pas de relevés d’heures vérifié disponibles pour le moment.',
        'invoices_nopay': 'Il n’y a pas de factures à régler disponible pour le moment.',
        'invoices_pay':'Il n’y a pas de factutre réglée  disponible pour le moment.',
        'coming':'Oops, il n’y a pas encore la fonctionnalité pour le moment, un peu de patience',
        'desktop':'Pour profiter d\'une expérience optimale, nous vous recommandons d\'avoir une résolution supérieure à 1024 x 768 pixels.',
        'schedule': 'Activez la fonctionnalité planning pour un suivi clair et efficace de vos missions d\'intérim.',
        'schedule_missing': 'Pas de planning disponible pour cette mission',
        'mission_invitation': 'Pas d\'intérimaire à inviter pour le moment',
    }

    const button={
        'mission_soon': <div className={'my-3'}><Button color={'PRIMARY'} size={'LG'} onClick={e => navigate('/createOfMission')}>Création
            de missions</Button></div>,
        'mission_in_progress': <div className={'my-3'}><Button color={'PRIMARY'}
                                                               size={'LG'}
                                                               onClick={e => navigate('/createOfMission')}>Création
            de missions</Button></div>,
        'mission_done': <div className={'my-3'}><Button color={'PRIMARY'} size={'LG'} onClick={e => navigate('/createOfMission')}>Création
            de missions</Button></div>,
    }

    return (
        <>
            <div className={'max-w-[535px] my-16 mx-auto text-center'}>
                <div className={'flex'}>
                    {logo[type]}
                </div>
                <div className={'font-medium ft-xl text-[#5C616D]'}>
                    {title[type]}
                </div>
                <div className={'font-normal ft-b mt-2 text-[#757575]'}>
                    {description[type]}
                </div>
                <div className={'flex'}>
                    <div className={'mx-auto'}>
                        {button[type]}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmptyStateCompany;