import React, {useEffect, useState} from 'react';
import {CheckIcon, Close, ExclamationCircle} from "assets/icons";
import Divider from "components/divider/divider";
import UseContext from "hooks/useContext";
import Button from "../../../components/button";
import ApiDatabase from "server";
import {toast} from "react-toastify";
import {dateWordDay} from "../../../helper/helper";

const TBodyHoursCompany = ({champsBody, setTotalHour, isDisplaySuggested, setIsDisplaySuggested}) => {
    const {timeCheck, setTimeCheck} = UseContext()
    const [work, setWork] = useState(true)
    const [iTime, setITime] = useState(1)
    const [pTime, setPTime] = useState(true)
    const [error1, setError1] = useState('')
    const [error2, setError2] = useState('')
    const [error3, setError3] = useState('')
    const [error7, setError7] = useState(false)
    const [error8, setError8] = useState(false)
    const [error9, setError9] = useState(false)
    const [error10, setError10] = useState(false)
    const [error11, setError11] = useState(false)
    const [error12, setError12] = useState(false)
    const [timeData, setTimeData] = useState({})
    const [timeSheet, setTimeSheet] = useState({})
    const [isDisplaySuggestionLoading, setIsDisplaySuggestionLoading] = useState([])

    const token = localStorage.getItem('xsrfToken')

    const pColorBg={
        2:'bg-red-50',
        3:'bg-green-100'
    }
    const pColorIcon={
        2:'#B91C1C',
        3:'#047857'
    }

    const checkIcon = (e = false, p, pS) => {
        if(e){
            e.preventDefault()
        }
        let updateTimeSheet = true;
        for (let i in timeCheck) {
            if(timeCheck[i] === pS && i === p){
                updateTimeSheet = false
            }
        }
        if(updateTimeSheet){
            setTimeCheck(prevData => ({
                ...prevData,
                [p]: pS
            }))
        }
        else{
            setTimeCheck(prevData => ({
                ...prevData,
                [p]: ""
            }))
        }

        if((pS === 3 && updateTimeSheet) || (pS === 2 && !updateTimeSheet) || (pS === 2 && updateTimeSheet)){
            let status = 3
            if((pS === 3 && updateTimeSheet) || (pS === 2 && !updateTimeSheet)) status = 3
            if(pS === 2 && updateTimeSheet) status = 2

            ApiDatabase.postUpdateDayCompany({idTimeSheetDay: p, validated: status, token},
                (data) => {
                    setTimeSheet(prevData => ({
                        ...prevData,
                        days: prevData.days.map((day) => {
                            if (day._id === p) {
                                day.day_hours_ent = data.day_hours_ent
                            }
                            return day
                        }),
                        total_hours_ent: data.total_hours_ent
                    }))

                    champsBody.total_hours_ent = data.total_hours_ent
                    setTotalHour(data.total_hours_ent)
                },
                (err) => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        if(champsBody){
            setTimeSheet(champsBody)

            if(champsBody?.days){
                setTimeout(() => {
                    for (let i = 0; i < champsBody?.days?.length; i++) {
                        if(champsBody.days[i].validated !== 1){
                            setTimeCheck(prevData => ({
                                ...prevData,
                                [champsBody.days[i]._id]: champsBody.days[i].validated
                            }))
                        }
                    }
                }, 200)
            }
        }
    }, [])

    useEffect(() => {
        if(timeCheck){
            for (let i in timeCheck) {
                if(timeCheck[i] !== 2){
                    timeData[i] = {
                        periode_one_start: '',
                        periode_one_end: '',
                        periode_two_start: '',
                        periode_two_end: '',
                        periode_three_start: '',
                        periode_three_end: '',
                    }

                    setITime(prevData => ({
                        ...prevData,
                        [i]: 1
                    }))

                    setError1(prevData => ({
                        ...prevData,
                        [i]: '',
                    }))
                    setError2(prevData => ({
                        ...prevData,
                        [i]: '',
                    }))
                    setError3(prevData => ({
                        ...prevData,
                        [i]: '',
                    }))
                    setError7(prevData => ({
                        ...prevData,
                        [i]: false,
                    }))
                    setError8(prevData => ({
                        ...prevData,
                        [i]: false,
                    }))
                    setError9(prevData => ({
                        ...prevData,
                        [i]: false,
                    }))
                    setError10(prevData => ({
                        ...prevData,
                        [i]: false,
                    }))
                    setError11(prevData => ({
                        ...prevData,
                        [i]: false,
                    }))
                    setError12(prevData => ({
                        ...prevData,
                        [i]: false,
                    }))

                    setIsDisplaySuggested(prevData => ({
                        ...prevData,
                        [i]: null,
                    }))

                    /*setTimeSheet(prevData => ({
                        ...prevData,
                        days: prevData.days.map((day) => {
                            if (day._id === i) {
                                delete day.periode_one_company
                                delete day.periode_two_company
                                delete day.periode_three_company
                            }
                            return day
                        })
                    }))*/

                    setPTime(prevData => ({
                        ...prevData,
                        [i]: false
                    }))

                    setWork(prevData => ({
                        ...prevData,
                        [i]: false
                    }))
                } else {

                    let isWork = true
                    for (let j = 0; j < timeSheet?.days?.length; j++) {
                        if(timeSheet.days[j]._id === i && timeSheet.days[j].periode_one_company?.start){
                            if(timeSheet.days[j].periode_one_company?.start === '00:00'){
                                isWork = false
                                break;
                            }
                        }
                    }

                    if(!iTime[i]){
                        setITime(prevData => ({
                            ...prevData,
                            [i]: 1
                        }))
                    }

                    setWork(prevData => ({
                        ...prevData,
                        [i]: isWork
                    }))

                    if(pTime[i] !== true && pTime[i] !== false){
                        setPTime(prevData => ({
                            ...prevData,
                            [i]: true
                        }))
                    } else {
                        setPTime(prevData => ({
                            ...prevData,
                            [i]: false
                        }))
                    }

                    if(isDisplaySuggested[i] !== true && isDisplaySuggested[i] !== false){
                        setIsDisplaySuggested(prevData => ({
                            ...prevData,
                            [i]: true,
                        }))
                    }

                    if(!error1[i]) {
                        setError1(prevData => ({
                            ...prevData,
                            [i]: '',
                        }))
                    }
                    if(!error2[i]) {
                        setError2(prevData => ({
                            ...prevData,
                            [i]: '',
                        }))
                    }
                    if(!error3[i]) {
                        setError3(prevData => ({
                            ...prevData,
                            [i]: '',
                        }))
                    }
                    if(!error7[i]) {
                        setError7(prevData => ({
                            ...prevData,
                            [i]: false,
                        }))
                    }
                    if(!error8[i]) {
                        setError8(prevData => ({
                            ...prevData,
                            [i]: false,
                        }))
                    }
                    if(!error9[i]) {
                        setError9(prevData => ({
                            ...prevData,
                            [i]: false,
                        }))
                    }
                    if(!error10[i]) {
                        setError10(prevData => ({
                            ...prevData,
                            [i]: false,
                        }))
                    }
                    if(!error11[i]) {
                        setError11(prevData => ({
                            ...prevData,
                            [i]: false,
                        }))
                    }
                    if(!error12[i]) {
                        setError12(prevData => ({
                            ...prevData,
                            [i]: false,
                        }))
                    }

                    if(!timeData[i]){
                        timeData[i] = {
                            periode_one_start: '',
                            periode_one_end: '',
                            periode_two_start: '',
                            periode_two_end: '',
                            periode_three_start: '',
                            periode_three_end: '',
                        }
                    }

                    if(isWork){
                        for (let j = 0; j < timeSheet?.days?.length; j++) {
                            if(timeSheet.days[j]._id === i){
                                if(!timeSheet.days[j].periode_one_company?.start){
                                    if(timeSheet.days[j].periode_one?.start) timeData[i].periode_one_start = timeSheet.days[j].periode_one?.start
                                    if(timeSheet.days[j].periode_one?.end) timeData[i].periode_one_end = timeSheet.days[j].periode_one?.end
                                    if(timeSheet.days[j].periode_two?.start) timeData[i].periode_two_start = timeSheet.days[j].periode_two?.start
                                    if(timeSheet.days[j].periode_two?.end) timeData[i].periode_two_end = timeSheet.days[j].periode_two?.end
                                    if(timeSheet.days[j].periode_three?.start) timeData[i].periode_three_start = timeSheet.days[j].periode_three?.start
                                    if(timeSheet.days[j].periode_three?.end) timeData[i].periode_three_end = timeSheet.days[j].periode_three?.end

                                    if(timeSheet.days[j].periode_two){
                                        if(timeSheet.days[j].periode_two?.start !== '' && timeSheet.days[j].periode_two?.end !== ''){
                                            setITime(prevData => ({
                                                ...prevData,
                                                [i]: 2
                                            }))
                                        }
                                    }
                                    if (timeSheet.days[j].periode_three){
                                        if(timeSheet.days[j].periode_three?.start !== '' && timeSheet.days[j].periode_three?.end !== ''){
                                            setITime(prevData => ({
                                                ...prevData,
                                                [i]: 3
                                            }))
                                        }
                                    }
                                } else {
                                    if(timeSheet.days[j].periode_one_company?.start) timeData[i].periode_one_start = timeSheet.days[j].periode_one_company?.start
                                    if(timeSheet.days[j].periode_one_company?.end) timeData[i].periode_one_end = timeSheet.days[j].periode_one_company?.end
                                    if(timeSheet.days[j].periode_two_company?.start) timeData[i].periode_two_start = timeSheet.days[j].periode_two_company?.start
                                    if(timeSheet.days[j].periode_two_company?.end) timeData[i].periode_two_end = timeSheet.days[j].periode_two_company?.end
                                    if(timeSheet.days[j].periode_three_company?.start) timeData[i].periode_three_start = timeSheet.days[j].periode_three_company?.start
                                    if(timeSheet.days[j].periode_three_company?.end) timeData[i].periode_three_end = timeSheet.days[j].periode_three_company?.end

                                    if(timeSheet.days[j].periode_two_company){
                                        if(timeSheet.days[j].periode_two_company?.start !== '' && timeSheet.days[j].periode_two_company?.end !== ''){
                                            setITime(prevData => ({
                                                ...prevData,
                                                [i]: 2
                                            }))
                                        }
                                    }
                                    if (timeSheet.days[j].periode_three_company){
                                        if(timeSheet.days[j].periode_three_company?.start !== '' && timeSheet.days[j].periode_three_company?.end !== ''){
                                            setITime(prevData => ({
                                                ...prevData,
                                                [i]: 3
                                            }))
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        setITime(prevData => ({
                            ...prevData,
                            [i]: 1
                        }))
                    }

                    setTimeData(prevData => ({
                        ...prevData,
                        [i]: timeData[i]
                    }))
                }

                handleChangeTimeData(i)
            }
        }
    }, [timeCheck])

    const handleChange = (e, id) => {
        e.preventDefault()
        const type = e.type.target
        const name = e.target.name

        const value = type === 'checkbox'
            ? e.target.checked
            : e.target.value

        if(!timeData[id]){
            timeData[id] = {
                periode_one_start: '',
                periode_one_end: '',
                periode_two_start: '',
                periode_two_end: '',
                periode_three_start: '',
                periode_three_end: '',
            }
        }

        timeData[id][name] = value;

        setTimeData(prevData => ({
            ...prevData,
            [id]: timeData[id]
        }))

        handleChangeTimeData(id)
    }

    const handleITime = (id) => {
        setITime(prevData => ({
            ...prevData,
            [id]: iTime[id] + 1
        }))

        handleChangeTimeData(id)
    }

    const handleSITime = (id) => {
        setITime((prevData) => ({
            ...prevData,
            [id]: iTime[id] - 1
        }))

        switch (iTime[id]) {
            case 2 :
                setError2(prevData => ({
                    ...prevData,
                    [id]: ''
                }))
                timeData[id].periode_two_start=''
                timeData[id].periode_two_end=''
                break
            case 3:
                setError3(prevData => ({
                    ...prevData,
                    [id]: ''
                }))
                timeData[id].periode_three_start=''
                timeData[id].periode_three_end=''
                break
            default:
                break

        }
    }

    useEffect(() => {
        for (let i in work) {
            if(work[i] === false){
                /*setTimeSheet(prevData => ({
                    ...prevData,
                    days: prevData.days.map((day) => {
                        if (day._id === i) {
                            day.periode_one_company.start = '00:00'
                            day.periode_one_company.end = '00:00'
                            delete day.periode_two_company;
                            delete day.periode_three_company;
                        }
                        return day
                    })
                }))*/
                setTimeData(prevData => ({
                    ...prevData,
                    [i]: {
                        periode_one_start: '00:00',
                        periode_one_end: '00:00',
                        periode_two_start: '',
                        periode_two_end: '',
                        periode_three_start: '',
                        periode_three_end: '',
                    }
                }))

                setITime(prevData => ({
                    ...prevData,
                    [i]: 1
                }))
            }

            handleChangeTimeData(i)
        }
    }, [work])
    // const getMinutes = s => s.split(":").reduce((acc, curr) => acc * 60 + +curr, 0);
    const getTimeDay = s => new Date(2023, 0o1, 0o1, s.split(":")[0], s.split(":")[1], 0o0)

    const cTime1 = (f) => {
        if(f.periode_one_start !== '00:00' && f.periode_one_end !== '00:00'){
            if(f.periode_one_start !== '' && f.periode_one_end !== ''){
                return (getTimeDay(f.periode_one_start).getTime()) < (getTimeDay(f.periode_one_end).getTime())
            } else {
                return false
            }
        } else {
            return f.periode_one_start === '00:00' && f.periode_one_end === '00:00'
        }
    }

    const cTime2 = (f) => {
        if(f.periode_one_end !== '' && f.periode_two_start !== ''){
            return (getTimeDay(f.periode_one_end).getTime()) < (getTimeDay(f.periode_two_start).getTime())
        } else {
            return true
        }
    }

    const cTime3 = (f) => {
        if(f.periode_two_start !== '' && f.periode_two_end !== ''){
            return (getTimeDay(f.periode_two_start).getTime()) < (getTimeDay(f.periode_two_end).getTime())
        } else {
            return true
        }
    }

    const cTime4 = (f) => {
        if(f.periode_two_end !== '' && f.periode_three_start !== ''){
            return (getTimeDay(f.periode_two_end).getTime()) < (getTimeDay(f.periode_three_start).getTime())
        } else {
            return true
        }
    }

    const cTime5 = (f) => {
        if(f.periode_three_start !== '' && f.periode_three_end !== ''){
            return (getTimeDay(f.periode_three_start).getTime()) < (getTimeDay(f.periode_three_end).getTime())
        } else {
            return true
        }
    }

    const cTime7 = (f, id) => {
        if(f.periode_one_start === '' || f.periode_one_start === '00:00'){
            if(work[id] !== undefined){
                return !work[id]
            } else {
                return false
            }
        } else {
            return true
        }
    }

    const cTime8 = (f, id) => {
        if(f.periode_one_end === '' || f.periode_one_end === '00:00'){
            if(work[id] !== undefined){
                return !work[id]
            } else {
                return false
            }
        } else {
            return true
        }
    }

    const cTime9 = (f, id) => {
        if(f.periode_two_start === ''){
            if(iTime[id] >= 2){
                if(work[id] !== undefined){
                    return !work[id]
                } else {
                    return false
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const cTime10 = (f, id) => {
        if(f.periode_two_end === ''){
            if(iTime[id] >= 2){
                if(work[id] !== undefined){
                    return !work[id]
                } else {
                    return false
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const cTime11 = (f, id) => {
        if(f.periode_three_start === ''){
            if(iTime[id] >= 3){
                if(work[id] !== undefined){
                    return !work[id]
                } else {
                    return false
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const cTime12 = (f, id) => {
        if(f.periode_three_end === ''){
            if(iTime[id] >= 3){
                if(work[id] !== undefined){
                    return !work[id]
                } else {
                    return false
                }
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const handleChangeTimeData = (id) => {
        if (!cTime1(timeData[id])) {
            setError1(prevData => ({
                ...prevData,
                [id]: 'Veuillez indiquer une heure valide de fin pour la periode 1'
            }))
        } else {
            setError1(prevData => ({
                ...prevData,
                [id]: ''
            }))
        }
        if (!cTime2(timeData[id])) {
            setError2(prevData => ({
                ...prevData,
                [id]: 'Veuillez indiquer une heure de début valide pour la periode 2'
            }))
        } else {
            setError2(prevData => ({
                ...prevData,
                [id]: ''
            }))
        }
        if (!cTime3(timeData[id])) {
            setError2(prevData => ({
                ...prevData,
                [id]: 'Veuillez indiquer une heure de fin valide pour la periode 2'
            }))
        } else {
            if(cTime2(timeData[id])){
                setError2(prevData => ({
                    ...prevData,
                    [id]: ''
                }))
            }
        }
        if (!cTime4(timeData[id])) {
            setError3(prevData => ({
                ...prevData,
                [id]: 'Veuillez indiquer une heure de début valide pour la periode 3'
            }))
        } else {
            setError3(prevData => ({
                ...prevData,
                [id]: ''
            }))
        }
        if (!cTime5(timeData[id])) {
            setError3(prevData => ({
                ...prevData,
                [id]: 'Veuillez indiquer une heure de fin valide pour la periode 3'
            }))
        } else {
            if(cTime4(timeData[id])){
                setError3(prevData => ({
                    ...prevData,
                    [id]: ''
                }))
            }
        }
        if(!cTime7(timeData[id], id)){
            setError7(prevData => ({
                ...prevData,
                [id]: true
            }))
        } else {
            setError7(prevData => ({
                ...prevData,
                [id]: false
            }))
        }
        if(!cTime8(timeData[id], id)){
            setError8(prevData => ({
                ...prevData,
                [id]: true
            }))
        } else {
            setError8(prevData => ({
                ...prevData,
                [id]: false
            }))
        }
        if(!cTime9(timeData[id], id)){
            setError9(prevData => ({
                ...prevData,
                [id]: true
            }))
        } else {
            setError9(prevData => ({
                ...prevData,
                [id]: false
            }))
        }
        if(!cTime10(timeData[id], id)){
            setError10(prevData => ({
                ...prevData,
                [id]: true
            }))
        } else {
            setError10(prevData => ({
                ...prevData,
                [id]: false
            }))
        }
        if(!cTime11(timeData[id], id)){
            setError11(prevData => ({
                ...prevData,
                [id]: true
            }))
        } else {
            setError11(prevData => ({
                ...prevData,
                [id]: false
            }))
        }
        if(!cTime12(timeData[id], id)){
            setError12(prevData => ({
                ...prevData,
                [id]: true
            }))
        } else {
            setError12(prevData => ({
                ...prevData,
                [id]: false
            }))
        }

        if(cTime1(timeData[id]) && cTime2(timeData[id]) && cTime3(timeData[id]) && cTime4(timeData[id]) && cTime5(timeData[id]) && cTime7(timeData[id], id) && cTime8(timeData[id], id) && cTime9(timeData[id], id) && cTime10(timeData[id], id) && cTime11(timeData[id], id) && cTime12(timeData[id], id)) {
            setPTime(prevData => ({
                ...prevData,
                [id]: true
            }))
        } else {
            setPTime(prevData => ({
                ...prevData,
                [id]: false
            }))
        }
    }

    const handleSubmitSuggestedPeriod = async (id) => {
        setIsDisplaySuggestionLoading(prevData => [...prevData, id])
        await handleChangeTimeData(id)
        if (pTime[id]) {
            if (
                timeData[id]?.periode_one_start &&
                timeData[id]?.periode_one_end &&
                !(
                    timeData[id]?.periode_one_start === (timeSheet.days.find((day) => day._id === id).periode_one?.start || '00:00') &&
                    timeData[id]?.periode_one_end === (timeSheet.days.find((day) => day._id === id).periode_one?.end || '00:00') &&
                    timeData[id]?.periode_two_start === timeSheet.days.find((day) => day._id === id).periode_two?.start &&
                    timeData[id]?.periode_two_end === timeSheet.days.find((day) => day._id === id).periode_two?.end &&
                    timeData[id]?.periode_three_start === timeSheet.days.find((day) => day._id === id).periode_three?.start &&
                    timeData[id]?.periode_three_end === timeSheet.days.find((day) => day._id === id).periode_three?.end
                )
            ) {
                ApiDatabase.postTimeSheetSuggestedCompany({
                        token: token,
                        idTimeSheet: id,
                        periode_one_start: timeData[id].periode_one_start,
                        periode_one_end: timeData[id].periode_one_end,
                        periode_two_start: timeData[id].periode_two_start,
                        periode_two_end: timeData[id].periode_two_end,
                        periode_three_start: timeData[id].periode_three_start,
                        periode_three_end: timeData[id].periode_three_end,
                    },
                    (data) => {
                        setTimeSheet(prevData => ({
                            ...prevData,
                            days: prevData.days.map((day) => {
                                if (day._id === id) {
                                    day.periode_one_company = {
                                        start: timeData[id].periode_one_start,
                                        end: timeData[id].periode_one_end
                                    }
                                    day.periode_two_company = {
                                        start: timeData[id].periode_two_start,
                                        end: timeData[id].periode_two_end
                                    }
                                    day.periode_three_company = {
                                        start: timeData[id].periode_three_start,
                                        end: timeData[id].periode_three_end
                                    }
                                    day.day_hours_ent = data.day_hours_ent
                                }
                                return day
                            }),
                            total_hours_ent: data.total_hours_ent
                        }))

                        champsBody.total_hours_ent = data.total_hours_ent
                        setTotalHour(data.total_hours_ent)

                        setIsDisplaySuggested(prevData => ({
                            ...prevData,
                            [id]: false
                        }))

                        setIsDisplaySuggestionLoading(prevData => prevData.filter((item) => item !== id))
                    },
                    (err) => {
                        console.log(err)
                        setIsDisplaySuggestionLoading(prevData => prevData.filter((item) => item !== id))
                    })
            } else {
                toast.success('Les heures n\'ont pas été modifiées')
                setIsDisplaySuggestionLoading(prevData => prevData.filter((item) => item !== id))

                checkIcon(false, id, 3)
            }
        } else {
            toast.error('Le formulaire n\'est pas valide')
            setIsDisplaySuggestionLoading(prevData => prevData.filter((item) => item !== id))
        }
    }

    useEffect(() => {
        if(iTime){
            //for an object
            for (const [key, value] of Object.entries(iTime)) {
                handleChangeTimeData(key)
            }
        }
    }, [iTime])

    return (
        <>
            {timeSheet?.days?.map((item, index) =>
                <>
                    <div key={index} className={'flex items-center grid grid-cols-5'}>
                        <div className={'ft-sm w-auto text-gray-900 font-medium'}>
                            {item.name}
                        </div>
                        <div className={'col-span-3 flex flex-col gap-2 ft-sm text-gray-500'}>
                            <span className={'ft-sm text-gray-900 font-medium'}>{(item?.periode_one_company?.start !== '00:00' || timeCheck[item?._id] !== 2) ? ((item.day_hours_ent !== '0' && item.day_hours_ent !== '0h' && item.day_hours_ent !== '0h0') ? `${item.day_hours_ent} travaillées` : 'Jour non travaillé') : null}</span>
                            <div className={''}>
                                {
                                    (!item?.periode_one_company || timeCheck[item?._id] !== 2) ? (
                                        <>
                                            {item?.periode_one && item?.periode_one?.start !== '' ? `${item?.periode_one.start} > ${item?.periode_one.end}` : null}
                                            {item?.periode_two && item?.periode_two?.start !== '' ? `, ${item?.periode_two?.start} > ${item?.periode_two?.end}` : null}
                                            {item?.periode_three && item?.periode_three?.start !== '' ? `, ${item?.periode_three?.start} > ${item?.periode_three?.end}` : null}
                                        </>
                                    ) : (
                                        <>
                                            <del>
                                                {item?.periode_one && item?.periode_one?.start !== '' ? `${item?.periode_one.start} > ${item?.periode_one.end}` : null}
                                                {item?.periode_two && item?.periode_two?.start !== '' ? `, ${item?.periode_two?.start} > ${item?.periode_two?.end}` : null}
                                                {item?.periode_three && item?.periode_three?.start !== '' ? `, ${item?.periode_three?.start} > ${item?.periode_three?.end}` : null}
                                            </del>
                                            <div className={'text-yellow-500'}>
                                                {item?.periode_one_company && item?.periode_one_company?.start !== '00:00' ? (item?.periode_one_company?.start !== '' ? `${item?.periode_one_company.start} > ${item?.periode_one_company.end}` : null) : 'Jour non travaillé'}
                                                {item?.periode_two_company && item?.periode_two_company?.start !== '' ? `, ${item?.periode_two_company?.start} > ${item?.periode_two_company?.end}` : null}
                                                {item?.periode_three_company && item?.periode_three_company?.start !== '' ? `, ${item?.periode_three_company?.start} > ${item?.periode_three_company?.end}` : null}
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div className={'flex items-center justify-end gap-6'}>
                            <div onClick={(e) => {
                                checkIcon(e, item?._id, 2)
                            }}
                                 className={`flex items-center justify-center h-8 w-8 rounded-md ${timeCheck[item?._id] === 2 ? pColorBg[timeCheck[item?._id]]:''} cursor-pointer`}>
                                <Close wh={24} color={timeCheck[item?._id] === 2 ? pColorIcon[timeCheck[item?._id]] :'#374151'}/>
                            </div>
                            <div onClick={(e) => {
                                    checkIcon(e, item?._id, 3)
                                 }}
                                 className={`flex items-center justify-center h-8 w-8 rounded-md ${timeCheck[item?._id] === 3 ?  pColorBg[timeCheck[item?._id]]:''} cursor-pointer`}>
                                <CheckIcon w={15} color={timeCheck[item?._id] === 3 ? pColorIcon[timeCheck[item?._id]] :'#374151'}/>
                            </div>
                        </div>
                    </div>
                    {
                        (timeCheck[item?._id] === 2 && isDisplaySuggested[item?._id]) && (
                            <>
                                <div className={'my-4 rounded border p-4 flex flex-col gap-4'}>
                                    {
                                        work[item?._id] ? (
                                            <>
                                                <div className={'flex flex-col gap-6 mx-3'}>
                                                    <div className={`gap-4 flex flex-col ${error1[item?._id] !== '' ? 'border-2 border-red-600 rounded-md' : null}`}>
                                                        <p>Période travaillée 1</p>
                                                        {error1[item?._id] !== '' ? <p className={' col-span-2 ft-b text-red-700'}>{error1[item?._id]}</p> : null}
                                                        <div className={'flex gap-4'}>
                                                            <div className={'w-1/2'}>
                                                                <label htmlFor="periode_one_start">Heure de début</label>
                                                                <input type="time"
                                                                       onChange={(e) => handleChange(e, item?._id)}
                                                                       name={'periode_one_start'}
                                                                       value={timeData[item?._id]?.periode_one_start ?? ''}
                                                                       min={'00:00'}
                                                                       max={'23:59'}
                                                                       className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                                                />
                                                                {
                                                                    error7[item?._id] ? (
                                                                        <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                                            <div className={'my-auto'}>
                                                                                <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                                            </div>
                                                                            <div className={'my-auto'}>
                                                                                Ce champ est requis
                                                                            </div>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>
                                                            <div className={'w-1/2'}>
                                                                <label htmlFor="periode_one_end">Heure de Fin</label>
                                                                <input type="time"
                                                                       name={'periode_one_end'}
                                                                       value={timeData[item?._id]?.periode_one_end ?? ''}
                                                                       onChange={(e) => handleChange(e, item?._id)}
                                                                       className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                                                />
                                                                {
                                                                    error8[item?._id] ? (
                                                                        <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                                            <div className={'my-auto'}>
                                                                                <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                                            </div>
                                                                            <div className={'my-auto'}>
                                                                                Ce champ est requis
                                                                            </div>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {iTime[item?._id] > 1 ?
                                                        <div className={`flex flex-col gap-4 ${error2[item?._id] !== '' ? 'border-2 border-red-600 rounded-md' : null}`}>
                                                            <div className={'flex justify-between'}>
                                                                <p>Période travaillée 2</p>
                                                                <div onClick={() => handleSITime(item?._id)}
                                                                     className={'cursor-pointer'}
                                                                >
                                                                    <Close wh={24} color={'#6B7280'}/>
                                                                </div>
                                                            </div>
                                                            {error2[item?._id] !== '' ? <p className={' col-span-2 ft-b text-red-700'}>{error2[item?._id]}</p> : null}
                                                            <div className={'flex gap-4'}>
                                                                <div className={'w-1/2'}>
                                                                    <label htmlFor="periode_two_start">Heure de début</label>
                                                                    <input type="time"
                                                                           onChange={(e) => handleChange(e, item?._id)}
                                                                           value={timeData[item?._id]?.periode_two_start ?? ''}
                                                                           name={'periode_two_start'}
                                                                           className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                                                    />
                                                                    {
                                                                        error9[item?._id] ? (
                                                                            <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                                                <div className={'my-auto'}>
                                                                                    <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                                                </div>
                                                                                <div className={'my-auto'}>
                                                                                    Ce champ est requis
                                                                                </div>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                                <div className={'w-1/2'}>
                                                                    <label htmlFor="periode_two_end">Heure de Fin</label>
                                                                    <input type="time"
                                                                           onChange={(e) => handleChange(e, item?._id)}
                                                                           name={'periode_two_end'}
                                                                           value={timeData[item?._id]?.periode_two_end ?? ''}
                                                                           className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                                                    />
                                                                    {
                                                                        error10[item?._id] ? (
                                                                            <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                                                <div className={'my-auto'}>
                                                                                    <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                                                </div>
                                                                                <div className={'my-auto'}>
                                                                                    Ce champ est requis
                                                                                </div>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                    {iTime[item?._id] > 2 ?
                                                        <div className={`flex flex-col gap-4 ${error3[item?._id] !== '' ? 'border-2 border-red-600 rounded-md' : null}`}>
                                                            <div className={'flex justify-between'}>
                                                                <p>Période travaillée 3</p>
                                                                <div onClick={() => handleSITime(item?._id)}
                                                                     className={'cursor-pointer'}
                                                                >
                                                                    <Close wh={24} color={'#6B7280'}/>
                                                                </div>
                                                            </div>
                                                            {error3[item?._id] !== '' ? <p className={' col-span-2 ft-b text-red-700'}>{error3[item?._id]}</p> : null}
                                                            <div className={'flex gap-4'}>
                                                                <div className={'w-1/2'}>
                                                                    <label htmlFor="periode_three_start">Heure de début</label>
                                                                    <input type="time"
                                                                           onChange={(e) => handleChange(e, item?._id)}
                                                                           name={'periode_three_start'}
                                                                           value={timeData[item?._id]?.periode_three_start ?? ''}
                                                                           className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                                                    />
                                                                    {
                                                                        error11[item?._id] ? (
                                                                            <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                                                <div className={'my-auto'}>
                                                                                    <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                                                </div>
                                                                                <div className={'my-auto'}>
                                                                                    Ce champ est requis
                                                                                </div>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                                <div className={'w-1/2'}>
                                                                    <label htmlFor="periode_three_end">Heure de Fin</label>
                                                                    <input type="time"
                                                                           onChange={(e) => handleChange(e, item?._id)}
                                                                           name={'periode_three_end'}
                                                                           value={timeData[item?._id]?.periode_three_end ?? ''}
                                                                           className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                                                    />
                                                                    {
                                                                        error12[item?._id] ? (
                                                                            <div className={'text-red-500 flex gap-2 mt-1 ft-xs'}>
                                                                                <div className={'my-auto'}>
                                                                                    <ExclamationCircle wh={16} color={'#EF4444'}/>
                                                                                </div>
                                                                                <div className={'my-auto'}>
                                                                                    Ce champ est requis
                                                                                </div>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={'mx-3 ft-sm py-3'}>
                                                    Vous avez indiqué le {dateWordDay(item.date)} comme étant un jour non travaillé
                                                </div>
                                            </>
                                        )
                                    }
                                    <div className={'flex gap-2 mx-3'}>
                                        <div className={'my-auto'}>
                                            <div onClick={() => {
                                                setWork(prevData => ({
                                                    ...prevData,
                                                    [item?._id]: !work[item?._id]
                                                }))
                                            }}
                                                 className={'cursor-pointer text-yellow-500 underline ft-sm'}>
                                                {work[item?._id] ? 'Jour non travaillé ?' : 'Jour travaillé ?'}
                                            </div>
                                        </div>
                                        <div className={'ml-auto my-auto flex gap-2'}>
                                            {
                                                (iTime[item?._id] < 3 && work[item?._id]) &&
                                                <Button size={'SM'} color={'DEFAULT'} onClick={() => handleITime(item?._id)}>
                                                    Ajouter une période
                                                </Button>
                                            }
                                            {isDisplaySuggestionLoading.includes(item?._id) ? (
                                                <Button size={'SM'} color={'DISABLED'} disabled={true}>
                                                    Validation ...
                                                </Button>
                                            ) : (
                                                <Button size={'SM'} color={'PRIMARY'} onClick={() => handleSubmitSuggestedPeriod(item?._id)}>
                                                    Valider
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    <Divider w={100}/>
                </>
            )}
        </>
    );
};

export default TBodyHoursCompany;