import React, {useEffect, useState} from 'react';
import SelectPhone
    from "components/formcomponents/SelectPhone";
import {Information} from "assets/icons";
import useFetchData from "hooks/useFetchData";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import UseContext from "hooks/useContext";

const MyAccountPersonalContent = () => {
    const {userData}=useFetchData()
    const {refreshLoadWorker} = UseContext()
    const [validPhone, setValidPhone] = useState(false);
    const [errMsgPhone, setErrMsgPhone] = useState(false);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const contentAdmin={
        200:'Observateur',
        205:'Manageur',
        210:'Responsable',
        215:'Directeur',
        220:'Superviseur',
        225:'Administrateur'
    }
useEffect(()=>{
    if (userData?.phone?.length > 0) {
        setPhone(userData.phone)
    }
},[userData])
    useEffect(() => {
        if (phone.phone !== userData?.phone) {
            //Insert phone in database
            ApiDatabase.postUpdatePhone(
                {
                    token: localStorage.getItem('xsrfToken'),
                    phone: phone.phone
                },
                (data) => {
                    toast.success('Le numéro de téléphone a été modifié.')
                    userData.phone = phone;
                    refreshLoadWorker()
                },
                (err) => {
                    console.log(err)
                }
            )
        }
    }, [phone]);
    return (
        <>
            <div
                className='col-span-12 flex justify-center items-center mb-32 tab:mb-0'>
                <div
                    className='bg-white w-[800px] rounded-md shadow-md p-6 '>
                    <h2 className='flex justify-start ft-xl'>Informations personnelles</h2>
                    <div className='mt-4'>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12"></div>
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="name"
                                       className='ft-sm text-gray-500'>Nom</label>
                                <input type="text"
                                       value={userData.lastname}
                                       name='lastname'
                                       className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                       disabled
                                />
                            </div>
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="prenom"
                                       className='ft-sm text-gray-500'>Prenom</label>
                                <input type="text"
                                       value={userData.firstname}
                                       name='firstname'
                                       className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                       disabled/>
                            </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="email"
                                       className='ft-sm text-gray-500'>Poste dans l’entreprise </label>
                                <input type="text"
                                       value={contentAdmin[userData.role]}
                                       name='email'
                                       className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                                       disabled
                                       />
                            </div>
                            <div className="col-span-12 tm:col-span-6">
                                <label htmlFor="phone"
                                       className='ft-sm text-gray-500'>Numéro</label>
                                <div className="relative">
                                    <SelectPhone
                                        phoneValue={phone}
                                        setPhoneValue={setPhone}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default MyAccountPersonalContent;