import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import useModal from "components/modal/useModal";
import Button from "components/button";
import UseContext from "hooks/useContext";
import ApiDatabase from "server"

const ModalSignature = () => {
    const {toggle} = useModal()
    const {refreshLoadCompany}=UseContext()
    const {dataYousign, signatureLink} = UseContext()

    const handleSubmit = e => {
        e.preventDefault()
        ApiDatabase.putStatusContract({contract: dataYousign},
            (data) => {
                refreshLoadCompany()
                toggle()
            },
            (err)=>{
                console.log(err)})

    }

    return (
        <>
            <Modalheader hide={handleSubmit} hideShow={true}>Signature du document</Modalheader>
            <ModalBody>
                <iframe className={'w-full h-[700px]'} src={`${signatureLink}`}></iframe>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={handleSubmit}>Fermer</Button>
            </ModalFooter>
        </>
    );
};

export default ModalSignature;