import React, {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import UseContext from "hooks/useContext";
import ApiDatabase from "server";
import {JobbizLogo, Loading2} from "../../assets/icons";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const LayoutCompany = () => {
    const {height, width} = useWindowDimensions();
    const {updateUser}=UseContext()
    const location = useLocation();
    const navigate = useNavigate()

    //get multiple get params in url
    const params = new URLSearchParams(location.search);
    const tokenURL = params.get('token');
    const redirectURL = params.get('redirect');

    useEffect(()=>{
        if (tokenURL) {
            ApiDatabase.postRoleUser({token: tokenURL},
                (data) => {
                    localStorage.setItem('xsrfToken', data.token_refresh)
                    localStorage.setItem('rUser', data.role)
                    if (localStorage.getItem('xsrfToken') !== null && localStorage.getItem('rUser') !== null) {

                        if(redirectURL){
                            navigate(redirectURL)
                        } else {
                            navigate('../dashboard')
                        }
                    }
                },
                (err) => {
                    console.log(err)
                    window.location.href = process.env.REACT_APP_COMPANY_LOGIN + '?redirect=' + encodeURIComponent(location.pathname)
                })
        } else {
            const test = localStorage.getItem('rUser')
            const tt = localStorage.getItem('xsrfToken')
            if (test && tt) {
                if(redirectURL){
                    navigate(redirectURL)
                } else {
                    navigate('../dashboard')
                }
            } else {
                window.location.href = process.env.REACT_APP_COMPANY_LOGIN + '?redirect=' + encodeURIComponent(location.pathname)
            }
        }
    },[])

    return (
        <>
            <div className={`flex flex-col ${width <= 460 ? 'gap-5' : 'gap-10'} justify-center items-center h-screen`}>
                <JobbizLogo wh={(width <= 460 ? 166 : 300)}/>
                <div className={'animate-spin'}>
                    <Loading2 wh={(width <= 460 ? 40 : 60)} color={'#FF9600'}/>
                </div>
            </div>
        </>
    );
};

export default LayoutCompany;