import Button from "../button";
import {ChevronLeft, ChevronRight} from "../../assets/icons";
import React, {useEffect, useState} from "react";
import BadgeSquare from "../badge/badgeSquare";
import Badge from "../badge/badge";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {dateToEpoch} from "../../helper/helper";

const ScheduleWeekListMission = ({indexScheduleWeek, scheduleWeek, isNext, isPrev, isHidden, isActualWeek, setScheduleWeekDisplay, isWaiting}) => {
    const [dateStartWeek, setDateStartWeek] = useState(null)
    const [dateEndWeek, setDateEndWeek] = useState(null)
    const [dateWeekName, setDateWeekName] = useState(null)
    const [scheduleGroupByPeriods, setScheduleGroupByPeriods] = useState([])
    const {height, width} = useWindowDimensions();

    const monthList = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    // Set dateStartWeek, dateEndWeek and dateWeekName
    useEffect(() => {
        if(scheduleWeek?.days?.length > 0){
            const dateStart = dateToEpoch(scheduleWeek.days[0].date)
            const dateEnd = dateToEpoch(scheduleWeek.days[scheduleWeek.days.length - 1].date)

            setDateStartWeek(dateStart)
            setDateEndWeek(dateEnd)

            const yearStart = dateStart.getFullYear()
            const dayNumberStart = dateStart.getDate()
            const monthStart = monthList[dateStart.getMonth()]

            const yearEnd = dateEnd.getFullYear()
            const dayNumberEnd = dateEnd.getDate()
            const monthEnd = monthList[dateEnd.getMonth()]

            if(dateStart.setHours(0,0,0,0) === dateEnd.setHours(0,0,0,0)){
                setDateWeekName(dayNumberStart + ' ' + monthStart + ' ' + yearStart)
            } else {
                setDateWeekName(dayNumberStart + ' au ' + dayNumberEnd + ' ' + monthEnd + ' ' + yearEnd)
            }
        }
    }, [scheduleWeek])

    useEffect(() => {
        if(scheduleWeek?.days?.length > 0){
            let scheduleGroupByPeriods = []
            for (let i = 0; i < scheduleWeek.days.length; i++) {
                let periodsWeek = scheduleWeek.days[i].periods
                if(!periodsWeek){
                    periodsWeek = {
                        periode_one_start: null,
                        periode_one_end: null,
                    }
                }
                if (scheduleGroupByPeriods.length === 0) {
                    scheduleGroupByPeriods.push([{
                        date: scheduleWeek.days[i].date,
                        periods: periodsWeek
                    }])
                } else {
                    let isPeriodsExist = false
                    for (let j = 0; j < scheduleGroupByPeriods.length; j++) {
                        const scheduleGroupByPeriod = scheduleGroupByPeriods[j]
                        for (let k = 0; k < scheduleGroupByPeriod.length; k++) {
                            const scheduleGroupByPeriodDay = scheduleGroupByPeriod[k]
                            if (!!scheduleGroupByPeriodDay.periods) {
                                if (scheduleGroupByPeriodDay.periods.periode_one_start === periodsWeek.periode_one_start &&
                                    scheduleGroupByPeriodDay.periods.periode_one_end === periodsWeek.periode_one_end &&
                                    scheduleGroupByPeriodDay.periods.periode_two_start === periodsWeek.periode_two_start &&
                                    scheduleGroupByPeriodDay.periods.periode_two_end === periodsWeek.periode_two_end &&
                                    scheduleGroupByPeriodDay.periods.periode_three_start === periodsWeek.periode_three_start &&
                                    scheduleGroupByPeriodDay.periods.periode_three_end === periodsWeek.periode_three_end
                                ) {
                                    scheduleGroupByPeriod.push({
                                        date: scheduleWeek.days[i].date,
                                        periods: periodsWeek
                                    })
                                    isPeriodsExist = true
                                    break
                                }
                            }
                        }
                    }
                    if (!isPeriodsExist) {
                        scheduleGroupByPeriods.push([{
                            date: scheduleWeek.days[i].date,
                            periods: periodsWeek
                        }])
                    }
                }
            }

            setScheduleGroupByPeriods(scheduleGroupByPeriods)
        }
    }, [scheduleWeek])

    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className={`flex flex-col gap-4`}>
                            <div className={'flex justify-between gap-3'}>
                                <div className={'my-auto'}>
                                    <div className={'flex gap-3'}>
                                        <div className={'my-auto'}>
                                            <div className={'h-3 bg-gray-200 rounded w-20 mb-1'}></div>
                                        </div>
                                    </div>
                                    <div className={'ft-sm text-gray-500'}>
                                        <div className={'h-2 bg-gray-200 rounded w-40'}></div>
                                    </div>
                                </div>
                                <div className={'my-auto'}>
                                    <div className={'flex gap-2'}>
                                        <div className={'my-auto'}>
                                            <div className={'h-3 bg-gray-200 rounded w-8'}></div>
                                        </div>
                                        <div className={'my-auto'}>
                                            <div className={'h-3 bg-gray-200 rounded w-8'}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'flex flex-col gap-3'}>
                                {
                                    [1, 2, 3].map((i, index) => (
                                        <>
                                            <div className={'flex justify-between gap-3'}>
                                                <div className={'flex gap-2 my-auto overflow-y-auto no-scrollbar'}>
                                                    <div className={'flex justify-center items-center'}>
                                                        <div
                                                            className={`flex justify-center items-center rounded-full w-12 h-12`}>
                                                            <div className={'h-3 bg-gray-200 rounded w-10'}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`my-auto flex gap-2 ${width < 800 ? 'flex-col' : ''}`}>
                                                    <div className={'h-3 bg-gray-200 rounded w-20'}></div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={`flex flex-col gap-4 ${isHidden ? 'hidden' : ''}`}>
                            <div className={'flex justify-between gap-3'}>
                                <div className={'my-auto'}>
                                    <div className={'flex gap-3'}>
                                        <div className={'my-auto'}>
                                            {scheduleWeek.name}
                                        </div>
                                        {
                                            isActualWeek ? (
                                                <div className={'my-auto'}>
                                                    <Badge type={'VALID'}>
                                                        Cette semaine
                                                    </Badge>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                    <div className={'ft-sm text-gray-500'}>
                                        Consultez le planning de la semaine du {dateWeekName}.
                                    </div>
                                </div>
                                <div className={'my-auto'}>
                                    <div className={'flex gap-2'}>
                                        <div className={'my-auto'}>
                                            {/* Show previous parent div if clicked */}
                                            <Button size={'SMI'}
                                                    color={isPrev ? 'SECONDARY' : 'DISABLED'}
                                                    disabled={isPrev ? null : 'disabled'}
                                                    onClick={() => setScheduleWeekDisplay(indexScheduleWeek - 1)}
                                            >
                                                <ChevronLeft wh={24} color={isPrev ? '#111827' : '#6B7280'}/>
                                            </Button>
                                        </div>
                                        <div className={'my-auto'}>
                                            <Button size={'SMI'}
                                                    color={isNext ? 'SECONDARY' : 'DISABLED'}
                                                    disabled={isNext ? null : 'disabled'}
                                                    onClick={() => setScheduleWeekDisplay(indexScheduleWeek + 1)}
                                            >
                                                <ChevronRight wh={24} color={isNext ? '#111827' : '#6B7280'}/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                scheduleGroupByPeriods.length > 0 ? (
                                    <div className={'flex flex-col gap-3'}>
                                        {
                                            scheduleGroupByPeriods.map((scheduleGroupByPeriod, index) => (
                                                <div className={'flex justify-between gap-3'}>
                                                    <div className={'flex gap-2 my-auto overflow-y-auto no-scrollbar'}>
                                                        {
                                                            scheduleGroupByPeriod.map((scheduleGroupByPeriodDay, index) => {
                                                                const dateSchedulePeriodDay = dateToEpoch(scheduleGroupByPeriodDay.date)
                                                                //id day to french first letter day name
                                                                let dayName = ''
                                                                switch (dateSchedulePeriodDay.getDay()) {
                                                                    case 1:
                                                                        dayName = 'L'
                                                                        break
                                                                    case 2:
                                                                        dayName = 'Ma'
                                                                        break
                                                                    case 3:
                                                                        dayName = 'Me'
                                                                        break
                                                                    case 4:
                                                                        dayName = 'J'
                                                                        break
                                                                    case 5:
                                                                        dayName = 'V'
                                                                        break
                                                                    case 6:
                                                                        dayName = 'S'
                                                                        break
                                                                    case 0:
                                                                        dayName = 'D'
                                                                        break
                                                                    default:
                                                                }

                                                                //round button with day name centered
                                                                return (
                                                                    <div key={index} className={'flex justify-center items-center'}>
                                                                        <div className={`flex justify-center items-center rounded-full w-12 h-12 border select-none border-gray-100 bg-gray-50`}>
                                                                            {dayName}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className={`my-auto flex gap-2 ${width < 800 ? 'flex-col' : ''}`}>
                                                        {
                                                            scheduleGroupByPeriod[0].periods.periode_one_start && scheduleGroupByPeriod[0].periods.periode_one_end ? (
                                                                <div className={'whitespace-nowrap'}>
                                                                    <Badge type={scheduleGroupByPeriod[0]?.periods.periode_one_start === '00:00' && scheduleGroupByPeriod[0]?.periods.periode_one_end === '00:00' ? 'PRIMARY' : 'WARNING'}>
                                                                        {scheduleGroupByPeriod[0]?.periods.periode_one_start === '00:00' && scheduleGroupByPeriod[0]?.periods.periode_one_end === '00:00' ? 'Non travaillé' : `${scheduleGroupByPeriod[0]?.periods.periode_one_start} > ${scheduleGroupByPeriod[0]?.periods.periode_one_end}`}
                                                                    </Badge>
                                                                </div>
                                                            ) : (
                                                                scheduleGroupByPeriod[0].periods.periode_one_start === null && scheduleGroupByPeriod[0].periods.periode_one_end === null ? (
                                                                    <div className={'whitespace-nowrap text-gray-500'}>
                                                                        Non renseigné
                                                                    </div>
                                                                ) : null
                                                            )
                                                        }
                                                        {
                                                            scheduleGroupByPeriod[0].periods.periode_two_start && scheduleGroupByPeriod[0].periods.periode_two_end ? (
                                                                <div className={'whitespace-nowrap'}>
                                                                    <Badge type={'WARNING'}>
                                                                        {scheduleGroupByPeriod[0].periods.periode_two_start} > {scheduleGroupByPeriod[0].periods.periode_two_end}
                                                                    </Badge>
                                                                </div>
                                                            ) : null
                                                        }
                                                        {
                                                            scheduleGroupByPeriod[0].periods.periode_three_start && scheduleGroupByPeriod[0].periods.periode_three_end ? (
                                                                <div className={'whitespace-nowrap'}>
                                                                    <Badge type={'WARNING'}>
                                                                        {scheduleGroupByPeriod[0].periods.periode_three_start} > {scheduleGroupByPeriod[0].periods.periode_three_end}
                                                                    </Badge>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                    </>
                )
            }
        </>
    )
}

export default ScheduleWeekListMission;
