import React from 'react';
import Button from "components/button";
import UseContext from "hooks/useContext";
import {dateWord} from "helper/helper";
import ContentMotif from "pages/createMission/content.motif";
import {Calendar} from "../../../assets/icons";

const Confirmation = () => {
    const {data,setPage} = UseContext()

    return (
        <div className={'flex flex-col mx-auto gap-8 '}>
            <p className={'ft-xl text-gray-900 font-medium'}>Confirmer
                la création de mission</p>
            <div className={'mx-6 border-b border-gray-200 py-2'}>
                <div className={'flex justify-between'}>
                    <span
                        className={'ft-b text-gray-900 font-medium'}>Description</span>
                    <Button color={'DEFAULT'}
                            onClick={(e)=>{setPage(1)}}
                            size={'SM'}>Modifier</Button>
                </div>
                <div>
                    <p className={'ft-sm'}><span
                        className={'text-gray-700 font-medium'}>Secteur d'activité :</span> {data.activity}
                    </p>
                    <p className={'ft-sm'}><span
                        className={'text-gray-700 font-medium'}>Qualification:</span> {data.qualification}
                    </p>
                </div>
            </div>
            <div className={'mx-6 border-b border-gray-200 py-2'}>
                <div className={'flex justify-between'}>
                    <span
                        className={'ft-b text-gray-900 font-medium'}>Pré-requis</span>
                    <Button color={'DEFAULT'}
                            onClick={(e)=>{setPage(2)}}
                            size={'SM'}>Modifier</Button>
                </div>
                <div>
                    <p className={'ft-sm'}><span
                        className={'text-gray-700 font-medium'}>Matériel nécessaire : </span>{data.tools.map((item,index) => (
                        <span key={index}> {item} </span>
                    ))}
                    </p>
                    <p className={'ft-sm'}><span
                        className={'text-gray-700 font-medium'}>Permis nécessaire : </span>{data.licences.map((item,index) => (
                        <span key={index}> {item} </span>
                    ))}
                    </p>
                    <p className={'ft-sm'}><span
                        className={'text-gray-700 font-medium'}>Langue nécessaire : </span>{data.languages.map((item,index) => (
                        <span key={index}> {item} </span>
                    ))}
                    </p>
                </div>
            </div>
            <div className={'mx-6 border-b border-gray-200 py-2'}>
                <div className={'flex justify-between'}>
                    <span
                        className={'ft-b text-gray-900 font-medium'}>Raison de l’emploi</span>
                    <Button color={'DEFAULT'}
                            onClick={(e)=>{setPage(3)}}
                            size={'SM'}>Modifier</Button>
                </div>
                <div>
                    <ContentMotif/>
                </div>
            </div>
            <div className={'mx-6 border-b border-gray-200 py-2'}>
                <div className={'flex justify-between'}>
                    <span
                        className={'ft-b text-gray-900 font-medium'}>Lieu de la mission</span>
                    <Button color={'DEFAULT'}
                            onClick={(e)=>{setPage(4)}}
                            size={'SM'}>Modifier</Button>
                </div>
                <div className={'flex flex-col gap-4'}>
                    <div className={'flex flex-col ft-sm '}>
                        <span className={'text-gray-900 font-medium'}>Adresse :</span>
                        {data.location.street + ', ' + data.location.postcode + ' ' + data.location.city}
                    </div>
                    <div className={'flex justify-between'}>
                        <div className={'my-auto'}>
                            <div className={'flex flex-col ft-sm'}>
                                <span className={'text-gray-900 font-medium'}>Période de la mission : </span>
                                {data.dateStart && data.dateEnd ? `${dateWord(data.dateStart)} au ${dateWord(data.dateEnd)}` : null}
                            </div>
                        </div>
                        {
                            data.isScheduleCheck ? (
                                <div className={'my-auto'}>
                                    <div className={'my-auto'}>
                                        <Button color={'SECONDARY'}
                                                size={'SM'}
                                                onClick={(e)=>{setPage(5)}}
                                        >
                                            <Calendar wh={24} color={'#374151'} />
                                            Planning
                                        </Button>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
            <div className={'mx-6 border-b border-gray-200 py-2'}>
                <div className={'flex justify-between'}>
                    <span
                        className={'ft-b text-gray-900 font-medium'}>Information responsable</span>
                    <Button color={'DEFAULT'}
                            onClick={(e)=>{setPage(8)}}
                            size={'SM'}>Modifier</Button>
                </div>
                <div className={'flex flex-col gap-4'}>
                    <div className={'flex flex-col ft-sm '}>
                        <span className={'text-gray-900 font-medium'}>Nom complet du responsable : </span>
                        {data.contact_name}
                    </div>
                    <div className={'flex flex-col ft-sm'}>
                        <span className={'text-gray-900 font-medium'}>Poste du responsable : </span>
                        {data.contact_poste}
                    </div>
                </div>
            </div>
            <div className={'mx-6 border-b border-gray-200 py-2'}>
                <div className={'flex justify-between'}>
                    <span
                        className={'ft-b text-gray-900 font-medium'}>Rémunération de l’intérimaire</span>
                    <Button color={'DEFAULT'}
                            onClick={(e)=>{setPage(6)}}
                            size={'SM'}>Modifier</Button>
                </div>
                <div className={'flex flex-col ft-sm '}>
                    <span className={'text-gray-900 font-medium'}>Taux horaire : </span>
                    {data.salary} €/h
                </div>
            </div>
            <div className={'mx-6 border-b border-gray-200 py-2'}>
                <div className={'flex justify-between'}>
                    <span
                        className={'ft-b text-gray-900 font-medium'}>Re-travailler avec un profil</span>
                    <Button color={'DEFAULT'}
                            onClick={(e)=>{setPage(7)}}
                            size={'SM'}>Modifier</Button>
                </div>
                <div className={'flex flex-col ft-sm '}>
                    <span className={'text-gray-900 font-medium'}>Intérimaires invités : </span>
                    {data.users_invite ? data.users_invite.map((tempWorker,index) => (
                        <span className={'mt-0.5'} key={index}>- {tempWorker.name} </span>
                    )) : 'Aucun'}
                </div>
            </div>
            <div className={'mx-6 border-b border-gray-200 py-2'}>
                <div className={'flex justify-between'}>
                    <span className={'ft-b text-gray-900 font-medium'}>Informations supplémentaires</span>
                    <Button color={'DEFAULT'}
                            onClick={(e)=>{setPage(9)}}
                            size={'SM'}>Modifier</Button>
                </div>

                <div className={'flex flex-col gap-4'}>
                    <div className={'flex flex-col ft-sm '}>
                        <span className={'text-gray-900 font-medium'}>Informations pour le candidat sélectionné : </span>
                        <p>{data.other !== '' ? data.other : 'Aucune information'}</p>
                    </div>
                    <div className={'flex flex-col ft-sm'}>
                        <span className={'text-gray-900 font-medium'}>Informations pour l'agence d'intérim : </span>
                        <p>{data.other_interim !== '' ? data.other_interim : 'Aucune information'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Confirmation;