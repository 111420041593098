import React, {useState} from 'react';
import {CloseModal, MenuBar} from "assets/icons";
import NavbarDesktop from "components/navBar/NavbarDesktop";
import {useLocation} from "react-router-dom";
import NavBarTitle
    from "components/navBar/NavbarComponents/navBarTitle";


const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            <header className='sticky z-40 top-0 bg-white border-b border-gray-200 w-full'>
                <div className='px-8 '>
                    <div className='flex items-center justify-between h-16 -mb-px'>
                        {/* Header: Left side */}
                        <NavBarTitle/>
                        {/* Header: Right side */}
                        <div className='flex items-center px-2 gap-4'>

                                <NavbarDesktop/>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Navbar;