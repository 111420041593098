import React, {useEffect, useRef} from 'react';
import UseContext from "hooks/useContext";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {
    AddMission,
    ChevronLeftNav,
    ChevronRightNav, DashboardIcon,
} from "assets/icons";
import Logo from "assets/images/Jobbiz.svg";
import Tooltips from "components/tooltips/Tooltips";
import DashboardLink
    from "components/sidebar/links/dashboard.link";
import ProfilLink from "components/sidebar/links/profil.link";
import FilesLink from "components/sidebar/links/files.link";
import MissionLink
    from "components/sidebar/links/mission.link";
import HelpLink from "components/sidebar/links/help.link";
import TimeSheetLink
    from "components/sidebar/links/timeSheet.link";

const SidebarDesktop = () => {
    const {
        sidebarExpanded,
        setSidebarExpanded,
        sidebarOpen,
        setSidebarOpen,
        setPage,
        setMissionCostEstimate,
        roleUser
    } =
        UseContext();
    const trigger = useRef(null);
    const sidebar = useRef(null);
    const rUser = localStorage.getItem('rUser')
    const route = useLocation();
    const navigate = useNavigate()
    const {pathname} = route;
    useEffect(() => {
        localStorage.setItem("sidebar-expanded", sidebarExpanded);
        if (sidebarExpanded) {
            document.querySelector("body").classList.add("sidebar-expanded");
        } else {
            document.querySelector("body").classList.remove("sidebar-expanded");
        }
    }, [sidebarExpanded]);

    useEffect(() => {
        const clickHandler = ({target}) => {
            if (!sidebar.current || !trigger.current) return;
            if (
                !sidebarOpen ||
                sidebar.current.contains(target) ||
                trigger.current.contains(target)
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });
    return (
        <>
            <div
                id="sidebar"
                ref={sidebar}
                className={`flex flex-col absolute left-0 top-0 tab:static tab:left-auto tab:translate-x-0 transform h-screen overflow-y-scroll tab:overflow-y-auto no-scrollbar w-60 tab:w-[75px] tab:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-[#FCFCFC] p-4 transition-all duration-200 ease-in-out  border-b-2 border-r-[1px]  ${
                    sidebarOpen ? "translate-x-0" : "-translate-x-60"
                } `}
            >
                {/* Sidebar Header */}
                <div
                    className="flex justify-between mb-10 pr-3 mm:px-2">
                    {/* close Button */}
                    <button
                        className="tab:hidden text-gray-600 hover:text-slate-400"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                    >
                        <span
                            className="sr-only ">Fermer le menu</span>
                        <ChevronLeftNav/>
                    </button>
                    <NavLink
                        end
                        to="/dashboard"
                        className={`flex items-center justify-center w-24 !sidebar-expanded:hidden `}
                    >
                        <img src={Logo} alt="logo"
                             className="w-full"/>

                    </NavLink>
                    {/* Expand / collapse button */}
                    <div
                        className="pt-3 hidden tab:inline-flex 2xl:hidden justify-end mt-auto">
                        <div className="px-3 py-2 ">
                            <Tooltips
                                text={sidebarExpanded ? "Reduire" : "Agrandir"}
                                placement="right"
                            >
                                <button
                                    onClick={() => setSidebarExpanded(!sidebarExpanded)}>
                                    <span
                                        className="sr-only">Expand / collapse sidebar</span>
                                    <ChevronRightNav
                                        prop={"sidebar-expanded:rotate-180"}
                                    ></ChevronRightNav>
                                </button>
                            </Tooltips>
                        </div>
                    </div>
                </div>
                <div className="space-y-8 ">
                    <div>
                        {(rUser !== '200' && rUser !== '205') && (
                            <button
                                className={'bg-yellow-500 text-white rounded w-full sh-sm flex flex-row justify-center items-center gap-2 h-9 ft-sm sidebar-expanded:py-6 sidebar-expanded:px-4 p-2'}
                                onClick={(e) => {
                                    navigate('/createOfMission');
                                    setPage(0)
                                    setMissionCostEstimate(0)
                                }}
                            >
                                <span className='shrink-0 block sidebar-expanded:hidden'>
                                   <AddMission wh={24} cIcon={'fill-white stroke-white '}/>
                                </span>
                                <span className={'ft-b font-medium hidden opacity-0 sidebar-expanded:block sidebar-expanded:opacity-100 duration-200'}>
                                    Créer une mission
                                </span>
                            </button>
                        )}
                        <ul className="mt-6 ml-2">
                            {/* Links */}
                            <DashboardLink pathname={pathname} tooltip={'right'}/>
                            {(rUser !== '200' && rUser !== '205' && rUser !== '210' && rUser !== '220') && <ProfilLink pathname={pathname} tooltip={'right'}/>}
                            {(rUser !== '205' && rUser !== '220') && <FilesLink pathname={pathname} tooltip={'right'}/>}
                            <TimeSheetLink pathname={pathname} tooltip={'right'}/>
                            {rUser !== '205' && <MissionLink pathname={pathname} tooltip={'right'}/>}
                            {/*<hr className="w-[95%] h-px bg-gray-20 my-2"/>*/}
                            {/*<HelpLink pathname={pathname}*/}
                            {/*          tooltip={'right'}/>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarDesktop;