import React from 'react';
import EmptyState from "components/emptyState";

const PaymentsTermsContent = () => {
    return (
        <>
            <EmptyState type={'coming'}/>

        </>
    );
};

export default PaymentsTermsContent;