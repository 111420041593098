import React, {useEffect} from "react";

// IMPORT COMPONENTS
import Navbar from "components/navBar/Navbar";
import MediaQuery from "react-responsive";
import SidebarDesktop from "components/sidebar/sidebarDesktop";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EmptyStateCompany from "components/emptyState";
import Intercom from '@intercom/messenger-js-sdk';
import CryptoJS from 'crypto-js';
import useFetchData from "../../hooks/useFetchData";

const PageForm = (props) => {
    const {userData} = useFetchData();

    useEffect(() => {
        if (Object.keys(userData).length > 0) {
            const userIdentifier = userData._id.toString();
            const hash = CryptoJS.HmacSHA256(userIdentifier, process.env.REACT_APP_INTERCOM_ID).toString();
            Intercom({
                app_id: 'hq7jtuxz',
                user_id: userData._id,
                user_hash: hash,
                name: userData.firstname + ' ' + userData.lastname,
                email: userData.email,
                created_at: userData.createdAt,
                custom_attributes: {
                    userType: "company",
                },
            });
        }
    }, [userData]);

    return (
        <>
            <MediaQuery minWidth={900}>
            <div className="flex h-screen overflow-y-hidden tab:overflow-hidden">
                {/* Sidebar */}
                <SidebarDesktop/>
                {/* Content area */}
                <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/* Navbar */}
                    <Navbar></Navbar>
                    <main>
                        {/* SubNav */}
                        {/*<div className="w-full">*/}
                        {/*    <SecondNavBar/>*/}
                        {/*</div>*/}
                        {/* Main Content */}
                        <div className="flex flex-col gap-6">{props.children}</div>
                    </main>
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={2}
                pauseOnFocusLoss
                draggable
            />
            </MediaQuery>
            <MediaQuery maxWidth={899}>
                <EmptyStateCompany type={'desktop'}/>
            </MediaQuery>
        </>
    );
};

export default PageForm;