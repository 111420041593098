import useModal from "../../../useModal";
import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import UseContext from "../../../../../hooks/useContext";
import React, {useEffect, useState} from "react";
import ApiDatabase from "server";
import {toast} from "react-toastify";
import Banner from "../../../../banner/banner";
import {CheckIcon} from "../../../../../assets/icons";
import {dateWordShort} from "../../../../../helper/helper";

const ModalMissionCancel = () => {
    const {toggle} = useModal()
    const {objID} = UseContext()
    const [isWaiting, setIsWaiting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [missionData, setMissionData] = useState({})
    const [reasonMissionCancellation, setReasonMissionCancellation] = useState([])
    const [reasonSelected, setReasonSelected] = useState('')
    const [errAtt, setErrAtt] = useState(false)
    const [check, setCheck] = useState(false)
    const token = localStorage.getItem('xsrfToken')

    const handleCheck = (e) => {
        setCheck(!check)
        if (errAtt) setErrAtt(false)
    }

    useEffect(() => {
        if (token && objID) {
            setIsWaiting(true)
            ApiDatabase.getInfoMissionCancelDelete({token, idMission: objID, isAdmin: false}, (data) => {
                setMissionData(data.mission)
                setReasonMissionCancellation(data.reasonMissionCancellation)
                setIsWaiting(false)
            }, (err) => {
                console.log('err', err)
                setIsWaiting(false)
            })
        }
    }, [token, objID]);

    const handleSubmit = () => {
        if (token && objID && reasonSelected && check) {
            setIsLoading(true)
            ApiDatabase.postCancelDeleteMission({token: token, idMission: objID, status: "cancellation", reason: reasonSelected, isDesktop: true}, async (data) => {
                toast.success('La mission a bien été annulée')
                setIsLoading(false)
                toggle()
                window.history.back()
            }, (err) => {
                toast.error('Une erreur est survenue lors de l\'annulation de la mission : ' + err)
                setIsLoading(false)
            })
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Annuler la mission
            </Modalheader>
            <ModalBody>
                {isWaiting ? (
                    <>
                        Chargement ...
                    </>
                ) : (
                    <>
                        {missionData.id_mission && (
                            <div className={'flex flex-col gap-3'}>
                                <div className={''}>
                                    Vous êtes sur le point d'annuler la mission {missionData.title} n°{missionData.id_mission}, prévue le {dateWordShort(missionData.duration.datestart)}. Le candidat sera informé de l’annulation de la mission.
                                </div>
                                <div>
                                    <label className='ft-sm text-gray-500'>
                                        <div>
                                            Motif
                                        </div>
                                        <div>
                                            <select
                                                className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                                value={reasonSelected}
                                                onChange={e => setReasonSelected(e.target.value)}
                                            >
                                                <option value={''}>Sélectionnez une raison</option>
                                                {
                                                    reasonMissionCancellation.map((mission, index) => {
                                                        return (
                                                            <option key={index}
                                                                    value={mission.reason}>{mission.reason}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </label>
                                </div>
                                <div
                                    className={`w-full ft-sm flex items-start gap-3 border b-gray-50 p-4 rounded-md cursor-pointer ${errAtt ? 'border-red-700 border-2' : null}`}
                                    onClick={handleCheck}
                                >
                                    <div
                                        className={`flex items-center justify-center h-4 w-5 border border-gray-300 rounded-sm bg-white ${check ? 'bg-yellow-600 border-yellow-600' : ''} ${errAtt ? 'border-red-700 border-2' : null} focus:outline-none transition duration-200  cursor-pointe`}>{check ?
                                        <CheckIcon w={14} h={10} color={'#ffff'}/> : ''}</div>
                                    <div className={'flex flex-col gap-2'}>
                                        <span
                                            className={`ft-sm font-bold text-gray-700 ${errAtt ? 'text-red-700' : null} `}>Attestation sur l’honneur</span>
                                        <div className="ft-sm font-medium text-gray-500">
                                            Je confirme l'annulation de la mission sélectionnée et comprends les
                                            répercussions potentielles sur le candidat sélectionné.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                {!check || !reasonSelected
                    ? <Button size={'LG'} color={'DISABLED'}>Confirmer</Button>
                    :
                    <>
                        {isLoading
                            ? <Button size={'LG'} color={'DISABLED'}>Confirmation ...</Button>
                            : <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
                        }
                    </>
                }
            </ModalFooter>

        </>
    );
}

export default ModalMissionCancel
