import React, {useEffect, useState} from 'react';
import EmptyStateCompany from "components/emptyState";
import UseContext from "hooks/useContext";
import Tableinvoice from "components/table/tableinvoice";
import {dateToEpoch} from "../../../../helper/helper";

const ToBeSetContent = () => {
    const {companyData} = UseContext()
    const dataInvoices = companyData.factures
    const [arrayInvoices, setArrayInvoices] = useState()
    const [isWaiting, setIsWaiting] = useState(true)

    const titles = ['Mois', 'Montant', 'État', 'Date']

    useEffect(()=>{
        if(dataInvoices instanceof Object || dataInvoices instanceof Array){
            dataInvoices.sort((a,b) => dateToEpoch(new Date(a.date_facture)).getTime() - dateToEpoch(new Date(b.date_facture)).getTime());
        }
    }, [dataInvoices])

    useEffect(() => {
        setIsWaiting(true)
        let tampon = []
        for (let m in dataInvoices) {
            if (!dataInvoices[m].status) tampon.push(dataInvoices[m])
        }
        setArrayInvoices(tampon)
        setIsWaiting(false)
    }, [companyData])

    return (
        <div className={'flex flex-col gap-8'}>
            {
                isWaiting ? (
                    <>
                        {
                            [1, 2, 3, 4, 5].map((i, index)=>(
                                <Tableinvoice champsBody={arrayInvoices} champsHeader={titles} isWaiting={isWaiting}/>
                            ))
                        }
                    </>
                ) : (
                    <>
                        {
                            arrayInvoices?.length ?
                                <Tableinvoice champsBody={arrayInvoices} champsHeader={titles}/>
                                :
                                <EmptyStateCompany type={'invoices_nopay'}/>
                        }
                    </>
                )
            }
        </div>
    );
};

export default ToBeSetContent;