import React from 'react';

const Thead = ({champsHeader, isWaiting}) => {
    return (
        <>
            <tr>
                {
                    isWaiting ? (
                        <>
                            {
                                champsHeader?.map((title, index) => (
                                    <td className={'px-2 py-7'}>
                                        <div className="h-2 bg-gray-200 rounded"></div>
                                    </td>
                                ))
                            }
                        </>
                    ) : (
                        <>
                            {
                                champsHeader?.map(item =>
                                    <th className={'m-3 text-left'}>{item.toUpperCase()}</th>
                                )
                            }
                            <th></th>
                        </>
                    )
                }
                <th></th>
            </tr>
        </>
    );
};

export default Thead;