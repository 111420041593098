import useModal from "../../../useModal";
import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import React, {useEffect, useState} from "react";
import ApiDatabase from "../../../../../server";
import UseContext from "../../../../../hooks/useContext";
import {InterrogationCercle, NegativeThumb, PositiveThumb} from "../../../../../assets/icons";
import Divider from "../../../../divider/divider";
import {toast} from "react-toastify";
import Tooltip from "../../../../tooltips/Tooltips";

const ModalRatingTempWorker = () => {
    const {toggle}=useModal()
    const {companyData, idTempWorker, setIdTempWorker, idMission, setIdMission, idTimeSheet, setIdTimeSheet, setUpdateRatingTempWorker} = UseContext()
    const [tempWorkerData, setTempWorkerData] = useState({})
    const [tempWorkerRating, setTempWorkerRating] = useState({})
    const [isPositive, setIsPositive] = useState(false)
    const [isNegative, setIsNegative] = useState(false)
    const [isNegativePerformance, setIsNegativePerformance] = useState(false)
    const [isNegativeProfessionalism, setIsNegativeProfessionalism] = useState(false)
    const [textComment, setTextComment] = useState('')
    const [isWaitingRating, setIsWaitingRating] = useState(false)
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        if(companyData?._id){
            setIsWaitingRating(true)
            ApiDatabase.getTempWorker({token, idCompany: companyData?._id, idTempWorker}, (data) => {
                setTempWorkerData(data)
            })
            ApiDatabase.getTempWorkerRating({token, idCompany: companyData?._id, idTempWorker, idMission}, (data) => {
                setTempWorkerRating(data)
                setIsWaitingRating(false)
            })
        }
    }, [companyData])

    useEffect(() => {
        if(isPositive) setIsNegative(false)
        setIsNegativeProfessionalism(false)
        setIsNegativePerformance(false)
        setTextComment('')
    }, [isPositive])

    useEffect(() => {
        if(isNegative) setIsPositive(false)
        setIsNegativeProfessionalism(false)
        setIsNegativePerformance(false)
        setTextComment('')
    }, [isNegative])

    useEffect(() => {
        if(isNegativePerformance) setIsNegativeProfessionalism(false)
        setTextComment('')
    }, [isNegativePerformance])

    useEffect(() => {
        if(isNegativeProfessionalism) setIsNegativePerformance(false)
        setTextComment('')
    }, [isNegativeProfessionalism])

    useEffect(() => {
        if(tempWorkerRating){
            if(tempWorkerRating.type){
                if(tempWorkerRating.type === 'positive') setIsPositive(true)
                if(tempWorkerRating.type === 'negative') setIsNegative(true)
            }
            if(tempWorkerRating.type_negative){
                setTimeout(() => {
                    if(tempWorkerRating.type_negative === 'performance') setIsNegativePerformance(true)
                    if(tempWorkerRating.type_negative === 'professionalism') setIsNegativeProfessionalism(true)
                }, 100);
            }
            if(tempWorkerRating.comment) {
                setTimeout(() => {
                    setTextComment(tempWorkerRating.comment)
                }, 150);
            }
        }
    }, [tempWorkerRating]);

    const handleSubmit = (e) => {
        e.preventDefault()

        if(!isPositive && !isNegative) return toast.error('Veuillez compléter le formulaire')
        if(isNegative && !isNegativePerformance && !isNegativeProfessionalism) return toast.error('Veuillez compléter le formulaire')
        if(textComment.length < 5 || textComment.length > 500) return toast.error('Veuillez compléter le formulaire')

        const dataSubmit = {
            type: isPositive ? 'positive' : isNegative ? 'negative' : '',
            type_negative: isNegativePerformance ? 'performance' : isNegativeProfessionalism ? 'professionalism' : '',
            comment: textComment
        }

        if(tempWorkerRating) {
            if(dataSubmit.type === tempWorkerRating.type && dataSubmit.type_negative === tempWorkerRating.type_negative && dataSubmit.comment === tempWorkerRating.comment) return toast.error('Vous avez déjà enregistré cet avis')
        }

        ApiDatabase.postTempWorkerRating({token, idCompany: companyData?._id, idTempWorker, idMission, idTimeSheet, type: dataSubmit.type, type_negative: dataSubmit.type_negative, comment: dataSubmit.comment}, (data) => {
            toast.success('Votre avis a bien été enregistré')
            setUpdateRatingTempWorker(true)
            hideModal()
        }, (err) => {
            toast.error('Une erreur est survenue, veuillez réessayer plus tard.' + err)
        })
    }

    const hideModal = () => {
        setIdTempWorker('')
        setIdMission('')
        setIdTimeSheet('')
        toggle()
    }

    return (
        <>
            <Modalheader hide={hideModal} hideShow={true}>
                Retravailler avec cet intérimaire ?
            </Modalheader>
            <ModalBody>
                {isWaitingRating ? (
                    <div className={'flex flex-col gap-8 animate-fadeIn animate-pulse'}>
                        <div className={'text-gray-500'}>
                            <div className={'h-5 bg-gray-200 rounded w-full'}></div>
                            <div className={'h-5 bg-gray-200 rounded w-28 mt-1'}></div>
                        </div>
                        <div className={'flex gap-6 select-none'}>
                            <div className={`flex flex-col gap-3 justify-center border rounded px-4 py-8 w-full cursor-pointer bg-gray-50`}>
                                <div className={'flex justify-center'}>
                                    <div className={'h-8 bg-gray-200 rounded w-8'}></div>
                                </div>
                                <div className={`flex flex-col text-center`}>
                                    <div className={'h-4 bg-gray-200 rounded w-full'}></div>
                                    <div className={'h-4 bg-gray-200 rounded w-24 mx-auto mt-1'}></div>
                                </div>
                            </div>
                            <div className={`flex flex-col gap-3 justify-center border rounded px-4 py-8 w-full cursor-pointer bg-gray-50`}>
                                <div className={'flex justify-center'}>
                                    <div className={'h-8 bg-gray-200 rounded w-8'}></div>
                                </div>
                                <div className={`flex flex-col text-center`}>
                                    <div className={'h-4 bg-gray-200 rounded w-full'}></div>
                                    <div className={'h-4 bg-gray-200 rounded w-24 mx-auto mt-1'}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={'flex flex-col gap-8'}>
                        <div className={'text-gray-500'}>
                            Envisageriez-vous de retravailler avec {tempWorkerData.firstname} {tempWorkerData.lastname} pour les futures missions de {tempWorkerRating.originalTitle} ?
                        </div>
                        <div className={'flex gap-6 select-none'}>
                            <div className={`flex flex-col gap-3 justify-center border rounded px-4 py-8 w-full cursor-pointer ${isNegative ? 'bg-red-100' : 'bg-gray-50'}`}
                                 onClick={() => setIsNegative(!isNegative)}
                            >
                                <div className={'flex justify-center'}>
                                    <NegativeThumb wh={24} color={isNegative ? '#B91C1C' : '#111827'}/>
                                </div>
                                <div className={`flex justify-center text-center ${isNegative && 'text-red-700'}`}>
                                    Je ne souhaite pas retravailler avec cet intérimaire
                                </div>
                            </div>
                            <div className={`flex flex-col gap-3 justify-center border rounded px-4 py-8 w-full cursor-pointer ${isPositive ? 'bg-green-100' : 'bg-gray-50'}`}
                                 onClick={() => setIsPositive(!isPositive)}
                            >
                                <div className={'flex justify-center'}>
                                    <PositiveThumb wh={24} color={isPositive ? '#047857' : '#111827'}/>
                                </div>
                                <div className={`flex justify-center text-center ${isPositive && 'text-green-700'}`}>
                                    Je souhaite retravailler avec cet intérimaire
                                </div>
                            </div>
                        </div>
                        {
                            isNegative && (
                                <>
                                    <Divider/>
                                    <div className={'flex flex-col gap-4'}>
                                        <label className={'p-4 flex gap-3 border rounded cursor-pointer'}>
                                            <div>
                                                <input type="checkbox" checked={isNegativePerformance} onChange={() => setIsNegativePerformance(!isNegativePerformance)}/>
                                            </div>
                                            <div className={'w-full'}>
                                                <div className={'flex justify-between gap-3'}>
                                                    <div className={'my-auto'}>
                                                        <div>Performance</div>
                                                        <div className={'text-gray-500 text-sm'}>
                                                            L’intérimaire n’a pu terminer les tâches pour lesquelles il a été embauché.
                                                        </div>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        <Tooltip text={`L'intémaire ne vous sera plus proposé pour les missions de ${tempWorkerRating.originalTitle}.`} placement={'top'}>
                                                            <div><InterrogationCercle wh={24} color={'#374151'}/></div>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                        <label className={'p-4 flex gap-3 border rounded cursor-pointer'}>
                                            <div>
                                                <input type="checkbox" checked={isNegativeProfessionalism} onChange={() => setIsNegativeProfessionalism(!isNegativeProfessionalism)}/>
                                            </div>
                                            <div className={'w-full'}>
                                                <div className={'flex justify-between gap-3'}>
                                                    <div className={'my-auto'}>
                                                        <div>Professionnalisme</div>
                                                        <div className={'text-gray-500 text-sm'}>
                                                            L'intérimaire a démontré une attitude irrespectueuse et non conforme aux exigences professionnelles.
                                                        </div>
                                                    </div>
                                                    <div className={'my-auto'}>
                                                        <Tooltip text={`L'intémaire ne vous sera plus proposé pour les prochaines missions.`} placement={'top'}>
                                                            <div><InterrogationCercle wh={24} color={'#374151'}/></div>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <Divider/>
                                    <label>
                                        <div className={'text-gray-500'}>Pouvez-vous partager plus de détails ?</div>
                                        <textarea
                                            cols="30"
                                            rows="5"
                                            className="border border-gray-300 rounded-md w-full p-1"
                                            required
                                            maxLength={500}
                                            minLength={3}
                                            wrap="true"
                                            value={textComment}
                                            onChange={(e) => setTextComment(e.target.value)}
                                        ></textarea>
                                        <div className={'text-gray-400 text-sm'}>
                                            {textComment.length === 0 && (
                                                <>
                                                    5 caractères minimum
                                                </>
                                            )}
                                            {textComment.length > 0 && textComment.length < 5 && (
                                                <div className={'text-red-500'}>
                                                    5 caractères minimum
                                                </div>
                                            )}
                                            {textComment.length >= 5 && textComment.length <= 500 && (
                                                <>
                                                    {textComment.length} / 500 caractères
                                                </>
                                            )}
                                        </div>
                                    </label>
                                </>
                            )
                        }
                        {
                            isPositive && (
                                <label>
                                    <div className={'text-gray-500'}>Pouvez-vous partager plus de détails ?</div>
                                    <textarea
                                        cols="30"
                                        rows="5"
                                        className="border border-gray-300 rounded-md w-full p-1"
                                        required
                                        maxLength={500}
                                        minLength={3}
                                        wrap="true"
                                        value={textComment}
                                        onChange={(e) => setTextComment(e.target.value)}
                                    ></textarea>
                                    <div className={'text-gray-400 text-sm'}>
                                        {textComment.length === 0 && (
                                            <>
                                                5 caractères minimum
                                            </>
                                        )}
                                        {textComment.length > 0 && textComment.length < 5 && (
                                            <div className={'text-red-500'}>
                                                5 caractères minimum
                                            </div>
                                        )}
                                        {textComment.length >= 5 && textComment.length <= 500 && (
                                            <>
                                                {textComment.length} / 500 caractères
                                            </>
                                        )}
                                    </div>
                                </label>
                            )
                        }
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
}

export default ModalRatingTempWorker;