import React from 'react';
import Start from "pages/createMission/body/start";
import Activity from "pages/createMission/body/activity";
import Prerequis from "pages/createMission/body/prerequis";
import Motif from "pages/createMission/body/motif";
import Location from "pages/createMission/body/location";
import Schedule from "pages/createMission/body/schedule";
import Contact from "pages/createMission/body/contact";
import Salary from "pages/createMission/body/salary";
import Other from "pages/createMission/body/other";
import Confirmation
    from "pages/createMission/body/confirmation";
import End from "pages/createMission/body/end";
import UseContext from "hooks/useContext";
import Invitation from "./body/invitation";

const FormsCreate = () => {
    const {page} = UseContext()
    const display = {
        0: <Start key={'start'}/>,
        1: <Activity key={'activity'}/>,
        2: <Prerequis key={'prerequis'}/>,
        3: <Motif key={'motif'}/>,
        4: <Location key={'location'}/>,
        5: <Schedule key={'schedule'}/>,
        6: <Salary key={'salary'}/>,
        7: <Invitation key={'invitation'}/>,
        8: <Contact key={'contact'}/>,
        9: <Other key={'other'}/>,
        10: <Confirmation key={'confirmation'}/>,
        11: <End key={'end'}/>
    }

    return (
        <div
            className={'w-full mx-auto lg:w-[1000px] min-h-[550px] border border-gray-200 rounded-md p-8'}>
            {display[page]}
        </div>
    );
};

export default FormsCreate;