import React, {useEffect, useState} from 'react';
import PageForm from "components/pageForm";
import {ArrowLeft, CrossIcon, Localisation, Speakerphone} from "assets/icons";
import {useNavigate, useParams} from "react-router-dom";
import ApiDataBase from 'server'
import Cardheader from "components/card/components/cardheader";
import Chip from "components/chip/chip";
import Cardbody from "components/card/components/cardbody";
import Divider from "components/divider/divider";
import {
    Camion,
    ChevronDown,
    ChevronTop,
    Drapeau,
    Warning
} from "assets/icons";
import Button from "components/button";
import Cardfooter from "components/card/components/cardfooter";
import {dateToEpoch, dateWord, dateWordShort} from "helper/helper";
import UseContext from "hooks/useContext";
import {statutMission} from "helper/helper";
import ScheduleWeekListMission from "../components/ScheduleWeekList/ScheduleWeekListMission";
import EmptyStateCompany from "../components/emptyState";
import useModal from "../components/modal/useModal";

const MissionFiche = () => {
    const {companyData, setObjID} = UseContext()
    const { toggle } = useModal();
    const [infoMission, setInfoMission] = useState({})
    const [showTools, setShowTools] = useState(false)
    const [visibilityTools, setVisibilityTools] = useState(3)
    const [showLicences, setShowLicences] = useState(false)
    const [visibilityLicences, setVisibilityLicences] = useState(3)
    const [scheduleWeeks, setScheduleWeeks] = useState([])
    const [scheduleWeeksProv, setScheduleWeeksProv] = useState([])
    const [scheduleWeekDisplay, setScheduleWeekDisplay] = useState(0)
    const [isWaiting, setIsWaiting] = useState(true);

    const status = statutMission(infoMission?.duration)
    const navigate = useNavigate()
    const {id} = useParams()

    useEffect(() => {
        setIsWaiting(true)
        ApiDataBase.getMissionInfo({id},
            (data) => {
                setInfoMission(data ?? {})
                setIsWaiting(false);
            },
            (err) => {
                console.log(err)
            })
    }, [id])

    useEffect(() => {
        ApiDataBase.getScheduleByMission({id: id},
            (data) => {
                setScheduleWeeksProv(data)
            },
            (err) => {
                console.log(err)
            })
    }, [infoMission])

    useEffect(() => {
        if(scheduleWeeksProv.length > 0) {
            scheduleWeeksProv.sort((a, b) => a.week_number - b.week_number)

            let isAWeekChosen = false;
            for (let i = 0; i < scheduleWeeksProv.length; i++) {
                scheduleWeeksProv[i].days.sort((a, b) => a.date - b.date)

                const number_week = ('0' + (parseInt(scheduleWeeksProv[i].week_number) + 1)).slice(-2)
                scheduleWeeksProv[i].name = `Semaine ${number_week}`
                scheduleWeeksProv[i].isNext = !!scheduleWeeksProv[i + 1];
                scheduleWeeksProv[i].isPrev = !!scheduleWeeksProv[i - 1];

                let isHidden = true;
                for (let j = 0; j < scheduleWeeksProv[i].days.length; j++) {
                    const scheduleDate = dateToEpoch(scheduleWeeksProv[i].days[j].date)
                    const today = dateToEpoch(new Date())

                    if(scheduleDate === today){
                        setScheduleWeekDisplay(i)
                        isHidden = false;
                        isAWeekChosen = true;
                    }
                }
                scheduleWeeksProv[i].isHidden = isHidden;
                scheduleWeeksProv[i].isActualWeek = !isHidden;
            }
            if(!isAWeekChosen){
                scheduleWeeksProv[0].isHidden = false;
            }

            setScheduleWeeks(scheduleWeeksProv);
        }
    }, [scheduleWeeksProv])

    const handleShowExp = () => {
        setShowTools(!showTools)
        if (showTools) {
            setVisibilityTools((prevValue) => prevValue - 3)
        } else {
            setVisibilityTools((prevValue) => prevValue + 3)
        }
    }
    const handleShowLicences = () => {
        setShowLicences(!showLicences)
        if (showLicences) {
            setVisibilityLicences((prevValue) => prevValue - 3)
        } else {
            setVisibilityLicences((prevValue) => prevValue + 3)
        }
    }

    const shouldShowTrashButton = (mission) => {
        if (mission.status === 'cancel' || mission.status === 'delete') return false

        const now = new Date();
        const startDate = new Date(mission.duration.datestart);
        const endDate = new Date(mission.duration.dateend);
        const hasAssignedUser = mission.user_assigned || false

        let missionStatus;
        if (now < startDate) {
            missionStatus = 'soon';
        } else if (now > endDate) {
            missionStatus = 'past';
        } else {
            missionStatus = 'in_progress';
        }

        if (missionStatus === 'soon' && hasAssignedUser) {
            return 'cancel';
        }

        if ((missionStatus === 'in_progress' || missionStatus === 'soon' || missionStatus === 'past') && !hasAssignedUser) {
            return 'delete';
        }

        return false;
    }

    const handleDeleteMission = () => {
        setObjID(infoMission._id)
        toggle('mission_delete')
    }

    const handleCancelMission = () => {
        setObjID(infoMission._id)
        toggle('mission_cancel')
    }

    return (
        <>
            <PageForm>
            {
                isWaiting ? (
                    <>
                        <div className={'animate-fadeIn animate-pulse'}>
                            <div className="flex justify-between bg-white sticky z-10 mt-lg-10">
                                <div className='flex items-center justify-between h-14 px-4'>
                                    <div className='flex items-center gap-2 cursor-pointer' onClick={e => navigate(-1)}>
                                        <ArrowLeft w={15} h={12} color={'#111827'}/>
                                        <div className={'h-4 bg-gray-200 rounded w-40'}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center col-span-full md:col-start-3 md:col-end-11">
                                <div className="block rounded-lg shadow-lg bg-white w-full lg:w-[60%] text-center mb-36 tab:mb-0">
                                    <Cardheader>
                                        <div className=" grid grid-cols-2 tab:grid-cols-4 flex gap-1">
                                            <div className="col-span-1 flex items-center justify-between w-[64px] h-[64px]">
                                                <div className={'h-full w-full bg-gray-200 rounded w-full'}></div>
                                            </div>
                                            <div className="col-span-2  order-last tab:order-none w-full">
                                                <div className="grid grid-col-3 text-left">
                                                    <div className="col-span-1 ft-sm text-gray-500 mb-1">
                                                        <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                                    </div>
                                                    <div className="col-span-1 ft-2xl font-medium text-gray-700 mb-1">
                                                        <div className={'h-4 bg-gray-200 rounded w-2/4'}></div>
                                                    </div>
                                                    <div className="col-span-1 ft-xs text-gray-500">
                                                        <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Cardheader>

                                    <Cardbody>
                                        <Divider w={100}/>
                                        <div className="grid grid-cols-1 tab:grid-cols-2 text-left">
                                            <div className="col-span-1 tab:col-span-2 ">
                                                <h5 className="text-gray-900 ft-b font-medium mb-2">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-full mb-1'}></div>
                                                    <div className={'h-2 bg-gray-200 rounded w-full mb-1'}></div>
                                                    <div className={'h-2 bg-gray-200 rounded w-3/4 mb-1'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1 ">
                                                <h6 className="text-gray-900 ft-b font-medium mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h6>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1">
                                                <h6 className="text-gray-900 ft-b font-medium mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                                </h6>
                                                <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                    <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                                </p>
                                            </div>
                                        </div>
                                        <Divider w={100}/>
                                        <div className={'text-left'}>
                                            {
                                                <ScheduleWeekListMission isWaiting={isWaiting}/>
                                            }
                                        </div>
                                        <Divider w={100}/>
                                        <div className="grid grid-cols-1 tab:grid-cols-3 text-left">
                                            <div className="col-span-1 tab:col-span-3">
                                                <h5 className="ft-xl text-gray-700">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-3/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider w={100}/>
                                        <div className="grid grid-cols-1 tab:grid-cols-3 text-left">
                                            <div className="col-span-1 tab:col-span-3">
                                                <h5 className="ft-xl text-gray-700">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 my-2">
                                                <div className="flex flex-nowrap items-center mb-1">
                                                    <h6 className="ft-xl text-gray-900 w-2/4">
                                                        <div className={'h-3 bg-gray-200 rounded w-full'}></div>
                                                    </h6>
                                                </div>
                                                <div className="grid grid-cols-1">
                                                    <div className="col-span-1 ft-sm text-gray-800">
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                        <div className={'h-2 bg-gray-200 rounded w-2/4 mb-1'}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider w={100}/>
                                        <div className="grid grid-cols-2 gap-6 text-left mb-4 ">
                                            <div className="col-span-2 ">
                                                <h5 className="ft-xl text-gray-900">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h5>
                                            </div>
                                            <div className="col-span-1">
                                                <h6 className="ft-xl text-gray-900 ml-2 mb-1">
                                                    <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                                                </h6>
                                                <p className="ft-sm text-gray-500 font-normal text-left ml-2 mb-1">
                                                    <div className={'h-2 bg-gray-200 rounded w-2/4'}></div>
                                                </p>
                                                <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                    <div className={'h-2 bg-gray-200 rounded w-3/4'}></div>
                                                </p>
                                            </div>
                                            <div className="col-span-1 flex justify-end px-5 py-8items-start">
                                                <div className={'h-5 bg-gray-200 rounded w-2/4'}></div>
                                            </div>
                                        </div>
                                    </Cardbody>
                                    <Cardfooter>
                                        <div className="ft-sm text-center flex justify-end text-gray-500">
                                            <div className={'h-2 bg-gray-200 rounded w-1/4'}></div>
                                        </div>
                                    </Cardfooter>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={'animate-fadeIn'}>
                            <div className="flex justify-between items-center bg-white sticky top-16 px-4">
                                <div className='flex items-center justify-between h-14'>
                                    <div className='flex items-center gap-2 cursor-pointer' onClick={e => navigate(-1)}>
                                        <ArrowLeft w={15} h={12} color={'#111827'}/>
                                        {infoMission?.title} n° {infoMission?.id_mission}
                                    </div>
                                </div>
                                {shouldShowTrashButton(infoMission) && (
                                    <div className={'flex'}>
                                        {shouldShowTrashButton(infoMission) === 'cancel' && (
                                            <Button size={'SM'} color={'SECONDARY'} onClick={handleCancelMission}>
                                                <div className={'flex items-center gap-1'}>
                                                    <CrossIcon wh={20} color={'#374151'}/>
                                                    Annuler la mission
                                                </div>
                                            </Button>
                                        )}
                                        {shouldShowTrashButton(infoMission) === 'delete' && (
                                            <Button size={'SM'} color={'SECONDARY'} onClick={handleDeleteMission}>
                                                <div className={'flex items-center gap-1'}>
                                                    <CrossIcon wh={20} color={'#374151'}/>
                                                    Annuler la mission
                                                </div>
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="flex mt-2 justify-center col-span-full md:col-start-3 md:col-end-11">
                                <div
                                    className="block rounded-lg shadow-lg bg-white w-full lg:w-[60%] text-center mb-36 tab:mb-0">
                                    <Cardheader>
                                        <div className=" grid grid-cols-2 tab:grid-cols-4 flex gap-2">
                                        <div className="col-span-3 order-last tab:order-none  ">
                                                <div className="grid grid-col-3 gap-2 text-left">
                                                    <div className="col-span-1 ft-sm text-gray-500">
                                                        {dateWord(infoMission?.duration?.datestart)} - {dateWord(infoMission?.duration?.dateend)}
                                                    </div>
                                                    <div className="col-span-1 ft-2xl font-medium text-gray-700 ">
                                                        {infoMission?.title} - n° {infoMission?.id_mission}
                                                    </div>
                                                    <div className="col-span-1 ft-xs text-gray-500">
                                                        {companyData.name_commercial} · {infoMission?.activity} · {infoMission?.address?.street}, {infoMission?.address?.city} , {infoMission?.address?.department?.split(',')[1]}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 flex justify-end">
                                                <Chip color={status?.color}>{status?.text}</Chip>
                                            </div>
                                        </div>
                                    </Cardheader>

                                    <Cardbody>
                                        <div className={'flex flex-col gap-4'}>
                                            <Divider w={100}/>
                                            <div className="grid grid-cols-1 tab:grid-cols-2 text-left ">
                                                <div className="col-span-1 tab:col-span-2 ">
                                                    <h5 className="text-gray-900 ft-b font-medium ">
                                                        Description
                                                    </h5>
                                                    <p className="text-gray-500 ft-sm font-normal mb-4">
                                                        <div dangerouslySetInnerHTML={{__html: infoMission?.desc ?? ''}}></div>
                                                    </p>
                                                    {
                                                        infoMission?.other ? (
                                                            <p className="text-gray-500 ft-sm font-normal mb-4">
                                                                <div dangerouslySetInnerHTML={{__html: infoMission?.other ?? ''}}></div>
                                                            </p>
                                                        ) : null
                                                    }
                                                </div>
                                                <div className="col-span-1 ">
                                                    <h6 className="text-gray-900 ft-b font-medium ">
                                                        Période
                                                    </h6>
                                                    <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                        {dateWordShort(infoMission?.duration?.datestart)} - {dateWordShort(infoMission?.duration?.dateend)}
                                                    </p>
                                                </div>
                                                <div className="col-span-1">
                                                    <h6 className="text-gray-900 ft-b font-medium ">
                                                        Salaire horaire brut
                                                    </h6>
                                                    <p className="text-gray-500 ft-sm font-normal mb-4 ">
                                                        {infoMission?.salary?.wage_per_hour !== 0 ? infoMission?.salary?.wage_per_hour + ' €/h' : 'Selong expérience'}
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                scheduleWeeks.length > 0 ? (
                                                    <>
                                                        <Divider w={100}/>
                                                        <div className={'text-left'}>
                                                            {
                                                                scheduleWeeks.map((scheduleWeek, index) => {
                                                                    if(scheduleWeek.isNext !== undefined && scheduleWeek.isPrev !== undefined && scheduleWeek.isHidden !== undefined && scheduleWeek.isActualWeek !== undefined) {
                                                                        return (
                                                                            <ScheduleWeekListMission key={index}
                                                                                                     indexScheduleWeek={index}
                                                                                                     scheduleWeek={scheduleWeek}
                                                                                                     isNext={scheduleWeek.isNext}
                                                                                                     isPrev={scheduleWeek.isPrev}
                                                                                                     isHidden={scheduleWeekDisplay !== index}
                                                                                                     isActualWeek={scheduleWeek.isActualWeek}
                                                                                                     setScheduleWeekDisplay={setScheduleWeekDisplay}
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Divider w={100}/>
                                                        <EmptyStateCompany type={'schedule_missing'}/>
                                                    </>
                                                )
                                            }
                                            <Divider w={100}/>
                                            <div className="grid grid-cols-3 text-left ">
                                                <div className="col-span-3">
                                                    <h5 className="ft-xl text-gray-700">Coordonnées</h5>
                                                </div>
                                                <div className="col-span-3 my-2">
                                                    <div className="flex flex-nowrap items-center">
                                                        <Speakerphone wh={18} color={'#111827'}/>
                                                        <h6 className="ft-b text-gray-900 ml-2">Responsable</h6>
                                                    </div>
                                                    <div className="ft-sm text-gray-800">
                                                        {infoMission?.contact?.name} - {infoMission?.contact?.poste}
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider w={100}/>
                                            {(infoMission?.language?.length > 0 || infoMission?.tools?.length > 0 || infoMission?.licences?.length > 0) && (
                                                <>
                                                    <div className="grid grid-cols-1 tab:grid-cols-3 text-left ">
                                                        <div className="col-span-1 tab:col-span-3">
                                                            <h5 className="ft-xl text-gray-700">Pré-requis</h5>
                                                        </div>
                                                        {infoMission?.language?.length > 0 && (
                                                            <div className="col-span-1 my-2">
                                                                <div className="flex flex-nowrap items-center">
                                                                    <Drapeau wh={18} color={'#111827'}/>
                                                                    <h6 className="ft-xl text-gray-900 ml-2">Langues</h6>
                                                                </div>
                                                                <div className="grid grid-cols-1">
                                                                    {
                                                                        infoMission?.language ?
                                                                            infoMission?.language.map((item) =>
                                                                                <div
                                                                                    className="col-span-1 ft-sm text-gray-800">{item}
                                                                                </div>
                                                                            ) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        {infoMission?.tools?.length > 0 && (
                                                            <div className="col-span-1 my-2">
                                                                <div className="flex flex-nowrap items-center">
                                                                    <Warning wh={18} color={'#111827'}/>
                                                                    <h6 className="text-xl text-gray-900 ml-2">Matériels</h6>
                                                                </div>
                                                                <div className="grid grid-cols-1 flex gap-3">
                                                                    {
                                                                        infoMission?.tools && Array.isArray(infoMission?.tools) ?
                                                                            infoMission?.tools.slice(0, visibilityTools).map(item =>
                                                                                <div
                                                                                    className="col-span-1 ft-sm text-gray-800">{item}
                                                                                </div>
                                                                            ) : ''
                                                                    }
                                                                    {
                                                                        infoMission?.tools?.length < 4 ?
                                                                            '' :
                                                                            <div className="cursor-pointer ft-sm text-yellow-500 flex flex-row items-center gap-3"
                                                                                 onClick={handleShowExp}
                                                                            >
                                                                                {showTools ?
                                                                                    <ChevronTop w={18} h={8} color={'#FF9600'}/> :
                                                                                    <ChevronDown w={18} h={8} color={'#FF9600'}/>}
                                                                                En savoir
                                                                                {showTools ? ' moins' : ' plus'}
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        {infoMission?.licences?.length > 0 && (
                                                            <div className="col-span-1 my-2">
                                                                <div className="flex flex-nowrap items-center">
                                                                    <Camion w={18} h={14} color={'#111827'}/>
                                                                    <h6 className="ft-xl text-gray-900 ml-2">Permis</h6>
                                                                </div>
                                                                <div className="grid grid-cols-1 flex gap-3">
                                                                    {
                                                                        infoMission?.licences && Array.isArray(infoMission?.licences) ?
                                                                            infoMission?.licences.slice(0, visibilityLicences).map(item =>
                                                                                <div className="col-span-1 ft-sm text-gray-800">{item}</div>
                                                                            ) : ''

                                                                    }
                                                                    {
                                                                        infoMission?.licences?.length < 4 ?
                                                                            '' :
                                                                            <div
                                                                                className="cursor-pointer ft-sm text-yellow-500 flex flex-row items-center gap-3"
                                                                                onClick={handleShowLicences}
                                                                            >
                                                                                {showLicences ?
                                                                                    <ChevronTop w={18} h={8} color={'#FF9600'}/> :
                                                                                    <ChevronDown w={18} h={8} color={'#FF9600'}/>}
                                                                                En savoir
                                                                                {showLicences ? ' moins' : ' plus'}
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <Divider w={100}/>
                                                </>
                                            )}
                                            <div className="grid grid-cols-2 gap-6 text-left mb-4 ">
                                                <div className="col-span-2 ">
                                                    <h5 className="ft-xl text-gray-900">
                                                        Lieu de travail
                                                    </h5>
                                                </div>
                                                <div className="col-span-1 flex flex-col ">
                                                    <h6 className="ft-xl text-gray-900 ml-2">
                                                        {companyData.name_commercial}
                                                    </h6>
                                                    <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                        {infoMission?.address?.street}
                                                    </p>
                                                    <p className="ft-sm text-gray-500 font-normal text-left ml-2">
                                                        {infoMission?.address?.city}, {infoMission?.address?.postcode}, {infoMission?.address?.department?.split(',')[1]}
                                                    </p>
                                                </div>
                                                <div className="col-span-1 flex justify-end items-start">
                                                    <a href={`https://www.google.com/maps/search/?api=1&query=${infoMission?.address?.street} ${infoMission?.address?.postcode} ${infoMission?.address?.city}`}
                                                       target='_blank'>
                                                        <Button color={'SECONDARY'} size={'LG'}
                                                        >
                                                            <Localisation wh={15} color={'#111827'}/>
                                                            Voir l'adresse
                                                        </Button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Cardbody>
                                    <Cardfooter>
                                        <div className="ft-sm text-center tab:text-right text-gray-500">
                                            Mission créée le {dateWordShort(infoMission?.createdAt)}
                                        </div>
                                    </Cardfooter>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            </PageForm>
        </>
    );
};

export default MissionFiche;