import React from 'react';
import SidebarUnitDesktop
    from "components/sidebar/components/SidebarUnitDesktop";
import {DashboardIcon, TeamIcon} from "assets/icons";

const AdminLink = ({pathname}) => {
    return (
        <>
            <SidebarUnitDesktop routeName={"Administation"} routeSidebar={"/adminCompany"}>
                <span className='shrink-0'>
                    <TeamIcon wh={24} color={`${pathname.includes("adminCompany") ? '#FF9600' : '#6B7280'}`}/>
                </span>
                <span className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes("adminCompany") && "!text-[#FF9600]"}`}>
                    {/*{language.getValue('sidebar.dashboard','Tableau de bord')}*/}
                    Équipe
                </span>
            </SidebarUnitDesktop>
        </>
    );
};

export default AdminLink;