import React, {useEffect, useState} from 'react';
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {useLocation} from "react-router-dom";

const PdfView = () => {
    const location = useLocation();
    const path = location.search.split('=')[1];
    const [docs, setDocs] = useState([]);
    const [isFileExist, setIsFileExist] = useState(false);
    const [isWaitingFile, setIsWaitingFile] = useState(false);

    useEffect(() => {
        manageFiles();
    }, [path]);

    useEffect(() => {
        if (docs.length > 0) {
            // Modifier l'attribut download du bouton de téléchargement
            const downloadButton = document.getElementById("pdf-download");
            if (downloadButton) {
                const fileName = docs[0].fileName || 'document.pdf';
                downloadButton.setAttribute('download', fileName);
            }
        }
    }, [docs]);

    const manageFiles = async () => {
        if (path) {
            const pathFile = `${process.env.REACT_APP_FILE + path}`;
            setIsWaitingFile(true);
            const response = await checkFileExistence(pathFile);
            setIsFileExist(response);
            if (response) {
                const buffer = await fetch(pathFile).then(res => res.arrayBuffer());
                const blob = new Blob([buffer], {type: response.type});
                const fileURL = URL.createObjectURL(blob);
                setDocs(docs => [...docs, {uri: fileURL, fileType: response.type, fileName: path.split('/').pop()}]);
            }
            setIsWaitingFile(false);
        }
    };

    const checkFileExistence = async (fileUrl) => {
        try {
            const response = await fetch(fileUrl);
            if (response.status >= 200 && response.status < 300) {
                const contentType = response.headers.get('content-type');
                if (contentType.includes('pdf') || contentType.includes('image')) {
                    const buffer = await response.arrayBuffer();
                    const blob = new Blob([buffer], {type: contentType});
                    return blob.size !== 0 ? {type: contentType} : false;
                }
                return false;
            }
            return false;
        } catch (error) {
            console.error("Error checking file existence:", error);
            return false;
        }
    };

    return (
        <div className={'h-screen overflow-y-auto'}>
            {isWaitingFile ? (
                <>
                    Chargement du document...
                </>
            ) : (
                <>
                    {
                        isFileExist ? (
                            docs.length > 0 && (
                                <DocViewer documents={docs} config={{pdfVerticalScrollByDefault: true}}
                                           pluginRenderers={DocViewerRenderers}/>
                            )
                        ) : (
                            <div className={'flex justify-center items-center h-full py-3 px-1 border rounded'}>
                                <div className={'text-gray-500'}>
                                    Une erreur est survenue lors du chargement du document. Le fichier est peut-être
                                    corrompu ou inexistant.
                                </div>
                            </div>
                        )
                    }
                </>
            )}
        </div>
    );
};

export default PdfView;