import React from 'react';
import Button from "components/button";
import {AddIcon} from "assets/icons";
import UseModal from "components/modal/useModal";
import UseContext from "hooks/useContext";

const AdminSubnav = () => {
    const {rest} = UseContext()
    const {toggle} = UseModal()

    const handleAdd = (e) => {
        e.preventDefault()
        toggle('admin_c_add')
    }
    return (
        <>
            <div
                className={'flex h-14 items-center justify-end mr-5'}>
                {rest === 0 ? null :
                    <Button size={'LGI'} color={'PRIMARYFIXE'}
                            onClick={handleAdd}>
                        <AddIcon wh={14} color={'#ffffff'}/>
                        Inviter un membre
                    </Button>
                }

            </div>
        </>
    );
};

export default AdminSubnav;