import React, {useEffect, useState} from 'react';
import EmptyStateCompany from "components/emptyState";
import UseContext from "hooks/useContext";
import ApiDatabase from "server";
import TableContract from "components/table/tableContract";

const SignedContent = () => {
    const {companyData,refreshTime} = UseContext()
    const titles=['N° de contrat','Intérimaire','Mission', 'N° De mission','ÉTAT','Date']
    const [arrayContracts, setArrayContracts] = useState([])
    const [isWaiting, setIsWaiting] = useState(true)

    useEffect(() => {
        setIsWaiting(true)
        if(Object.keys(companyData).length){
            ApiDatabase.getContracts({id:companyData._id,pStatus:1},
                (data) => {
                    setArrayContracts(data)
                    setIsWaiting(false)
                },
                (err) => {
                    console.log(err)
                })
        }
    }, [companyData, refreshTime])
    return (
        <div className={'flex flex-col  gap-8'}>
            {
                isWaiting ? (
                    <>
                        {
                            [1, 2, 3, 4, 5].map((i, index)=>(
                                <TableContract champsBody={i} champsHeader={titles} isWaiting={isWaiting}/>
                            ))
                        }
                    </>
                ) : (
                    <>
                        {
                            arrayContracts.length ?
                                <TableContract champsBody={arrayContracts} champsHeader={titles} isWaiting={isWaiting}/>
                                :
                                <EmptyStateCompany type={'contract'}/>

                        }
                    </>
                )
            }
        </div>
    );
};

export default SignedContent;