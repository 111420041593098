import ProgressBar from "components/progressBar/progressBar";
import {Close, TrophyIcon} from "assets/icons";
import {NavLink} from "react-router-dom";
import CardProfileCompletionDashboard from "components/card/dashboard/cardProfileCompletionDashboard";
import Divider from "components/divider/divider";
import React, {useEffect, useState} from "react";
import useFetchData from "hooks/useFetchData";
import Button from "components/button";
import useModal from "components/modal/useModal";
import useContext from "hooks/useContext";
import Banner from "../banner/banner";

const ProfileCompletion = () => {
    const {userData} = useFetchData();
    const {companyData} = useContext();
    const {toggle} = useModal()

    const [completion, setCompletion] = useState(0);
    const [isProfile, setIsProfile] = useState(0);
    const [isRemunerationConditions, setIsRemunerationConditions] = useState(0);
    const [isLegalInformation, setIsLegalInformation] = useState(0);
    const [isBonusesExemptions, setIsBonusesExemptions] = useState(0);
    const [isPayment, setIsPayment] = useState(0);

    const completionSteps = [
        {
            title: 'Compte',
            link:  '/profil/myAccountCompany',
            valid: isProfile,
        },
        {
            title: 'Informations légales',
            link:  '/profil/legalInformation',
            valid: isLegalInformation,
        },
        {
            title: 'Conditions de rémunérations',
            link:  '/profil/modesOfPayments',
            valid: isRemunerationConditions,
        },
        {
            title: 'Primes & exonérations',
            link:  '/profil/bonusesAndCompensation',
            valid: isBonusesExemptions,
        },
        {
            title: 'Paiement',
            link:  '/profil/paymentsTerms',
            valid: isPayment,
        }
    ];

    useEffect(() => {
        setCompletion(100/completionSteps.length * completionSteps.filter(step => step.valid === 1).length);
    }, [completionSteps]);

    useEffect(() => {
        if (
            companyData?.name_commercial &&
            companyData?.leader?.firstname &&
            companyData?.leader?.lastname &&
            companyData?.leader?.mail &&
            companyData?.leader?.phone &&
            companyData?.location
        ) {
            setIsProfile(1);
        }
        if (companyData?.remuneration_hours_conditions?.hours_supp_35 !== undefined &&
            companyData?.remuneration_hours_conditions?.hours_supp_43 !== undefined &&
            companyData?.remuneration_hours_conditions?.hours_night !== undefined &&
            companyData?.remuneration_hours_conditions?.hours_sunday !== undefined &&
            companyData?.remuneration_hours_conditions?.holiday_hours_worked !== undefined
        ) {
            setIsRemunerationConditions(1);
        }
        if (companyData?.reason_social &&
            companyData?.siret &&
            companyData?.code_ape &&
            companyData?.capital &&
            companyData?.staff &&
            companyData?.conv_collective
        ) {
            setIsLegalInformation(1);
        }
        if (
            companyData?.bonus?.basket &&
            companyData?.bonus?.travel_allowance &&
            companyData?.bonus?.cost_of_living
        ) {
            setIsBonusesExemptions(1);
        }
        if (
            (companyData?.bank_transfer || companyData?.cheque) &&
            companyData?.accounting_contact?.firstname &&
            companyData?.accounting_contact?.lastname &&
            companyData?.accounting_contact?.mail &&
            companyData?.accounting_contact?.phone &&
            companyData?.invoice_address
    ) {
            setIsPayment(1);
        }
    }, [userData]);

    const handleTasks = e => {
        e.preventDefault()
        toggle('tasks')
    }
    return (
        <>
            {completion < 100 ? (
                <div className={'px-4 py-5'}>
                    <div>
                        <div>
                            Votre profil est complet à {completion}%
                        </div>
                        <div className={'my-1'}>
                            <Banner type={'info'}>
                                Un conseiller Jobbiz va rapidement vous joindre afin de compléter votre profil et recrutez vos premiers intérimaires sur Jobbiz.
                            </Banner>
                        </div>
                        {/*<div className={'text-xs text-gray-500 mt-2'}>
                            En complétant votre profil, vous pourrez recrutez vos premiers profils.
                        </div>*/}
                    </div>
                    <div className={'my-3'}>
                        <div className={'flex'}>
                            <div className={'grow my-auto'}>
                                <ProgressBar progress={completion}/>
                            </div>
                            <div className={'my-auto ml-2'}>
                                <TrophyIcon wh={22}/>
                            </div>
                        </div>
                    </div>
                    {
                        completionSteps.length > 0 ? (
                            <div className={'my-6'}>
                                <div className={'gap-4 no-scrollbar overflow-y-auto hidden md:flex'}>
                                    {
                                        // completionSteps with only valid === 0
                                        completionSteps.map((step, key) => {
                                            if (step.valid === 0) {
                                                return (
                                                    <CardProfileCompletionDashboard key={key} text={step.title} link={step.link} value={parseInt(100/completionSteps.length)}/>
                                                );
                                            }
                                        })
                                    }
                                </div>
                                <div className={'md:hidden flex'}>
                                    <div className={'ml-auto'}>
                                        <Button onClick={handleTasks} size={'SM'} color={'SECONDARY'}>
                                            Voir les tâches
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : ''
                    }
                </div>
            ) : (
                <div className={'px-4 py-5'}>
                    <div className={'flex justify-between'}>
                        <div className={'my-auto'}>
                            <div className={'flex'}>
                                <div className={'my-auto'}>
                                    Félicitations, votre profil est complet
                                </div>
                                <div className={'ml-2 my-auto'}>
                                    <TrophyIcon wh={22}/>
                                </div>
                            </div>
                            <div className={'text-xs text-gray-500 mt-2'}>
                                Continuez de tenir votre profil à jour pour augmenter vos chances de recrutement.
                            </div>
                        </div>
                        <div className={'my-auto'}>
                            <div className={'cursor-pointer'}>
                                <Close wh={22} color={'#374151'}/>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={'my-4'}>
                <Divider/>
            </div>
        </>
    )
}

export default ProfileCompletion;