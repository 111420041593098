import React, {useEffect, useState} from 'react';
import AvatarCompagny from "components/avatar/avatarcompany";
import SelectPhone from "components/formcomponents/SelectPhone";
import UseContext from "hooks/useContext";
import Banner from "../../../components/banner/banner";
import ApiDatabase from "../../../server";
import {toast} from "react-toastify";

const MyAccountContent = () => {
    const {companyData} = UseContext()
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [validPhone, setValidPhone] = useState(false);
    const [errMsgPhone, setErrMsgPhone] = useState(false);

    useEffect(() => {
        if (Object.keys(companyData).length) {
            ApiDatabase.getAddress({id: companyData.location}, (res) => {
                if (res) {
                    setAddress(res.street + ' ' + res.city + ', ' + res.postcode + ' ' + res.department);
                }
            })
        }
    }, [companyData]);

    useEffect(() => {
        if (companyData?.leader?.phone !== phone && companyData.leader) {
            ApiDatabase.postUpdatePhoneCompany({
                id: companyData._id,
                phone: phone
            }, (res) => {
                    toast.success('Le numéro de téléphone a été modifié.')
            },
                (err)=>{
                console.warn(err)
                })
        }else if(companyData?.leader?.phone === undefined){
            ApiDatabase.postUpdatePhoneCompany({
                    id: companyData._id,
                    phone: phone
                }, (res) => {
                    toast.success('Le numéro de téléphone a été modifié.')
                },
                (err)=>{
                    console.warn(err)
                })
        }
    }, [phone])


    return (
        <div
            className={'flex flex-col mx-auto gap-8 w-[750px] border border-gray-300 p-4 rounded-md'}>
            <div className={'my-1'}>
                <Banner type={'info'}>
                    Un conseiller Jobbiz va rapidement vous joindre
                    afin de compléter votre profil et recrutez vos
                    premiers intérimaires sur Jobbiz.
                </Banner>
            </div>
            <div
                className={'flex items-center justify-center w-full -z-10'}>
                <AvatarCompagny size={'MD'}/>
            </div>
            <div className={
                ' flex flex-col gap-3'
            }>
                <div className={'ft-xl font-medium'}>Informations de
                    l'entreprise
                </div>
                <div className={'flex flex-col '}>
                    <label className='ft-sm text-gray-500'>Nom
                        commercial</label>
                    <input type="text"
                           disabled
                           value={companyData?.name_commercial}
                           className='w-1/2 h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'/>
                </div>
                <div className={'flex flex-col'}>
                    <div className={'ft-sm text-gray-500'}>Adresse de
                        l'entreprise
                    </div>
                    <input type="text"
                           disabled
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'
                           value={address}
                    />
                </div>
            </div>
            <div className={
                ' flex flex-col gap-3'
            }>
                <div className={'ft-xl font-medium'}>Contact
                    dirigeant
                </div>
                <div className={'grid grid-cols-2 gap-4'}>
                    <div className={'col-span-1 flex flex-col'}>
                        <label
                            className='ft-sm text-gray-500'>Prénom</label>
                        <input type="text"
                               disabled
                               value={companyData?.leader?.firstname}
                               className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'/>
                    </div>
                    <div className={'grid col-span-1 flex flex-col'}>
                        <label
                            className='ft-sm text-gray-500'>Nom</label>
                        <input type="text"
                               disabled
                               value={companyData?.leader?.lastname}
                               className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'/>

                    </div>
                </div>
                <div className={'grid grid-cols-2 gap-4'}>
                    <div className={'col-span-1 flex flex-col'}>
                        <label
                            className='ft-sm text-gray-500'>E-mail</label>
                        <input type="text"
                               disabled
                               value={companyData?.leader?.mail}
                               className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100 border rounded-lg'/>
                    </div>
                    <div className={'grid col-span-1 flex flex-col'}>
                        <label
                            className='ft-sm text-gray-500'>Numéro</label>
                        <SelectPhone
                            phoneValue={companyData?.leader?.phone}
                            setPhoneValue={setPhone}
                            setValidPhone={setValidPhone}
                            setErrMsgPhone={setErrMsgPhone}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyAccountContent;