import UseContext from "../../../hooks/useContext";
import React, {useEffect, useState} from "react";
import ApiDatabase from "server";
import {toast} from "react-toastify";
import {dateDiff} from "../../../helper/helper";
import EmptyState from "../../../components/emptyState";
import {PositiveThumb} from "../../../assets/icons";
import Pagination from "../../../components/pagination/pagination";

const Invitation = () => {
    const {data, setData, companyData} = UseContext()
    const [tempWorkerRating, setTempWorkerRating] = useState(null);
    const [tempWorkerRatingSearchResult, setTempWorkerRatingSearchResult] = useState(null);
    const [tempWorkerRatingSearchFinalResult, setTempWorkerRatingSearchFinalResult] = useState(null);
    const [tempWorkerRatingSelected, setTempWorkerRatingSelected] = useState([]);
    const [loadingTempWorkerRating, setLoadingTempWorkerRating] = useState(false);
    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [countPerPage, setCountPerPage] = useState(0)
    const [countTotal, setCountTotal] = useState(0)
    const token = localStorage.getItem('xsrfToken')

    useEffect(() => {
        if(companyData) {
            setLoadingTempWorkerRating(true)
            ApiDatabase.getTempWorkersRatingByMission({token, idCompany: companyData._id, titleMission: data.qualification, dateStart: data.dateStart, dateEnd: data.dateEnd}, (response) => {
                setTempWorkerRating(response)
                setTempWorkerRatingSearchResult(response)
                setTempWorkerRatingSearchFinalResult(response)
                setLoadingTempWorkerRating(false)
            }, (err) => {
                toast.error('Une erreur est survenue, veuillez réessayer plus tard.')
            })
        }
    }, [companyData])

    useEffect(() => {
        if(tempWorkerRatingSelected.length === 0 && data.users_invite.length > 0) {
            setTempWorkerRatingSelected(data.users_invite)
        }
    }, [data])

    useEffect(() => {
        setData(prevData => ({
            ...prevData,
            users_invite: tempWorkerRatingSelected
        }))
    }, [tempWorkerRatingSelected])

    let timerTempWorkerRatingSuggestions = null;

    useEffect(() => {
        timerTempWorkerRatingSuggestions = setTimeout(async () => {
            await manageTempWorkerRatingSuggestions(search);
        }, 300);

        return () => clearTimeout(timerTempWorkerRatingSuggestions);
    }, [search])

    const manageTempWorkerRatingSuggestions = (search) => {
        if(tempWorkerRating?.length > 0) {
            setCurrentPage(1)
            const tempWorkerRatingSearchResult = []
            for (let i = 0; i < tempWorkerRating.length; i++) {
                if(tempWorkerRating[i].name.toLowerCase().includes(search.toLowerCase())) {
                    tempWorkerRatingSearchResult.push(tempWorkerRating[i])
                }
            }
            setTempWorkerRatingSearchResult(tempWorkerRatingSearchResult)
        }
    }

    useEffect(() => {
        if(tempWorkerRatingSearchResult){
            const maxCountPerPage = 15
            const countTotal = tempWorkerRatingSearchResult.length
            const totalPages = Math.ceil(countTotal / maxCountPerPage)
            //know the number of element in current page
            const countPerPage = (currentPage === totalPages) ? (countTotal - ((totalPages - 1) * maxCountPerPage)) : maxCountPerPage

            const startIndex = (currentPage - 1) * countPerPage;
            const endIndex = startIndex + countPerPage;

            const tempWorkerRatingSearchResultPaginated = tempWorkerRatingSearchResult.slice(
                startIndex,
                endIndex
            );

            setCountPerPage(countPerPage)
            setCountTotal(countTotal)
            setTotalPages(totalPages)

            setTempWorkerRatingSearchFinalResult(tempWorkerRatingSearchResultPaginated)
        }
    }, [tempWorkerRatingSearchResult, currentPage]);

    return (
        <div className={'flex flex-col gap-4 p-8'}>
            <div className='ft-xl font-medium'>
                Re-travailler avec un profil ?
            </div>
            <p className={'text-gray-500'}>
                Sélectionnez les profils avec lesquels vous souhaitez travailler pour cette mission, les profils sélectionnées seront placés en priorité. Uniquement les profils disponibles sont affichés.
            </p>
            <div className={'flex flex-col gap-3'}>
                <input type={'text'} placeholder={'Rechercher un profil'} className={'w-full h-10 py-5 pl-3 pr-8 ft-b border rounded-lg'} onChange={(e) => setSearch(e.target.value)}/>
                <div className={'flex flex-col max-h-[260px] overflow-y-auto'}>
                    {loadingTempWorkerRating ? (
                        [1, 2, 3].map((i, index)=>(
                            <label key={index} className={'flex gap-8 cursor-pointer px-5 py-4 border-b'}>
                                <div className={'my-auto'}>
                                    <input type="checkbox"
                                           className={'accent-yellow-400'}
                                           disabled={true}
                                    />
                                </div>
                                <div className={'my-auto'}>
                                    <div><div className={'h-3 bg-gray-200 rounded w-10'}></div></div>
                                    <div className={'text-gray-500 mt-2'}>
                                        <div className={'h-2 bg-gray-200 rounded w-80'}></div>
                                    </div>
                                </div>
                            </label>
                        ))
                    ) : (
                        <>
                            {tempWorkerRatingSearchFinalResult && tempWorkerRatingSearchFinalResult.length > 0
                                ? tempWorkerRatingSearchFinalResult.map((tempWorker, index) => {
                                    let dateText = ''
                                    let dateEndMission = {}
                                    if(tempWorker.date_end) {
                                        dateEndMission = dateDiff(tempWorker.date_end)
                                        if(dateEndMission.day >= 0) {
                                            if(dateEndMission.day > 0) {
                                                dateText = `${dateEndMission.day} ${dateEndMission.day === 1 || dateEndMission.day === '1' ? 'jour' : 'jours'}`
                                            } else if(dateEndMission.hour > 0) {
                                                dateText = `${dateEndMission.hour} ${dateEndMission.hour === 1 || dateEndMission.hour === '1' ? 'heure' : 'heures'}`
                                            } else if(dateEndMission.min > 0) {
                                                dateText = `${dateEndMission.min} minutes`
                                            } else if(dateEndMission.sec > 0) {
                                                dateText = `${dateEndMission.sec} secondes`
                                            }
                                        } else {
                                            const dateAbs = Object.fromEntries(Object.entries(dateEndMission).map(([key, value]) => [key, Math.abs(value)]));
                                            if(dateAbs.day > 0) {
                                                dateText = `${dateAbs.day} ${dateAbs.day === 1 || dateAbs.day === '1' ? 'jour' : 'jours'}`
                                            } else if(dateAbs.hour > 0) {
                                                dateText = `${dateAbs.hour} ${dateAbs.hour === 1 || dateAbs.hour === '1' ? 'heure' : 'heures'}`
                                            } else if(dateAbs.min > 0) {
                                                dateText = `${dateAbs.min} minutes`
                                            } else if(dateAbs.sec > 0) {
                                                dateText = `${dateAbs.sec} secondes`
                                            }
                                        }
                                    }

                                    return (
                                        <label key={index} className={'flex gap-8 justify-between cursor-pointer px-5 py-4 border-b'}>
                                            <div className={'flex gap-8 my-auto'}>
                                                <div className={'my-auto'}>
                                                    <input type="checkbox"
                                                           className={'accent-yellow-400'}
                                                           onChange={() => {
                                                               setTempWorkerRatingSelected((prevData) => {
                                                                   //need to compare prevData.id_user with tempWorker._id
                                                                   if(prevData.find((item) => item.id_user === tempWorker._id)) {
                                                                       return prevData.filter((item) => item.id_user !== tempWorker._id)
                                                                   } else {
                                                                       return [...prevData, {id_user: tempWorker._id, name: tempWorker.name}]
                                                                   }
                                                               });
                                                           }}
                                                           checked={tempWorkerRatingSelected.find((item) => item.id_user === tempWorker._id)}
                                                    />
                                                </div>
                                                <div className={'my-auto'}>
                                                    <div>{tempWorker.name}</div>
                                                    {dateText && (
                                                        <div className={'text-gray-500 text-sm'}>
                                                            {tempWorker.title} {dateEndMission.day >= 0 ? 'il y a' : 'termine dans'} {dateText} {companyData._id !== tempWorker.id_company ? `chez ${tempWorker.company}` : ''}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {
                                                tempWorker.isRated && (
                                                    <div className={'my-auto'}>
                                                        <div className={'rounded-full bg-green-50 p-2'}>
                                                            <PositiveThumb wh={20} color={'#10B981'}/>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </label>
                                    )
                                })
                                : (
                                    <>
                                        <EmptyState type={'mission_invitation'}/>
                                    </>
                                )
                            }
                        </>
                    )}
                </div>
                {
                    (currentPage && totalPages > 1 && setCurrentPage) ? (
                        <div className={'px-8 py-4'}>
                            <Pagination currentPage={currentPage} countPerPage={countPerPage} countTotal={countTotal} totalPages={totalPages} setCurrentPage={setCurrentPage}/>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
}

export default Invitation;