import React,{useContext} from "react";
import TokenPwd from "components/modal/dataModal/data/ModalToken/tokenPwd";
import ModalToken from "components/modal/dataModal/data/ModalToken/token";
import UseContext from "hooks/useContext";

import ModalLogout
    from "components/modal/dataModal/data/ModalLogout/ModalLogout";
import ModalMessagerie
    from "components/modal/dataModal/data/ModalMessagerie/ModalMessagerie";
import ModalNotification
    from "components/modal/dataModal/data/ModalNotification/ModalNotification";
import Modalmodifyemail
    from "components/modal/dataModal/data/modalparametre/modalmodifyemail";
import Modalmodifypwd
    from "components/modal/dataModal/data/modalparametre/modalmodifypwd";
import Modalmodifydeleted
    from "components/modal/dataModal/data/modalparametre/modalmodifydeleted";
import ModalTokenPassword
    from "components/modal/dataModal/data/ModalToken/tokenPwd";
import Tokenemail
    from "components/modal/dataModal/data/ModalToken/tokenemail";
import ModalSignature
    from "components/modal/dataModal/data/ModalSignature";
import CloseProcess
    from "components/modal/dataModal/data/createMission/closeProcess";
import Temporaire
    from "components/modal/dataModal/data/createMission/temporaire";
import Remplacement
    from "components/modal/dataModal/data/createMission/remplacement";
import AttenteRecrutement
    from "components/modal/dataModal/data/createMission/attenteRecrutement";
import Accroissement
    from "components/modal/dataModal/data/createMission/accroissement";
import Saisonnier
    from "components/modal/dataModal/data/createMission/saisonnier";
import AddEditSchedule from "components/modal/dataModal/data/createMission/addEditSchedule";
import ToBeChecked
    from "components/modal/dataModal/data/modalTimeSheet/toBeChecked";
import Checked
    from "components/modal/dataModal/data/modalTimeSheet/checked";
import ValidateTime
    from "components/modal/dataModal/data/modalTimeSheet/validateTime";
import AddRole from "components/modal/dataModal/data/admin/addRole";
import ModifyRole
    from "components/modal/dataModal/data/admin/modifyRole";
import DeleteRole
    from "components/modal/dataModal/data/admin/deleteRole";
import Transfert
    from "components/modal/dataModal/data/admin/transfert";
import ConfirmSchedule from "./data/createMission/confirmSchedule";
import DeleteScheduleWeekDay from "./data/createMission/deleteScheduleWeekDay";
import DeleteScheduleWeek from "./data/createMission/deleteScheduleWeek";
import ModalRatingTempWorker from "./data/ModalRatingTempWorker/modalRatingTempWorker";
import ModalMissionDelete from "./data/ModalMission/ModalMissionDelete";
import ModalMissionCancel from "./data/ModalMission/ModalMissionCancel";


// Function
// nomination des modales
//Premiere partie:
// j-> justificatif *** msg->messagerie *** ntf->notification *** par-> parametres ***
// sf-> savoir faire *** tok-> token *** log->logout *** doc->documents
//Seconde partie:
//u-Update *** c->create *** d->delete ***g->get
//Troisieme partie:
//nom de la modale
const dataModal=new Map()
dataModal.set('log_d_logout',<ModalLogout/>)
dataModal.set('msg_c_messagerie',<ModalMessagerie/>)
dataModal.set('ntf_g_notif',<ModalNotification/>)
dataModal.set('par_u_email',<Modalmodifyemail/>)
dataModal.set('par_u_pwd',<Modalmodifypwd/>)
dataModal.set('par_u_compte',<Modalmodifydeleted/>)
dataModal.set('tok_u_token',<ModalToken/>)
dataModal.set('tok_u_tokenPwd',<ModalTokenPassword/>)
dataModal.set('tok_u_tokenEmail',<Tokenemail/>)
dataModal.set('doc_c_signature',<ModalSignature/>)
dataModal.set('cM_close_process',<CloseProcess/>)
dataModal.set('cM_temporaire',<Temporaire/>)
dataModal.set('cM_remplacement',<Remplacement/>)
dataModal.set('cM_attRecrutement',<AttenteRecrutement/>)
dataModal.set('cM_accroissement',<Accroissement/>)
dataModal.set('cM_saisonnier',<Saisonnier/>)
dataModal.set('cM_addScheduleWeek',<AddEditSchedule/>)
dataModal.set('cM_confirmSchedule',<ConfirmSchedule/>)
dataModal.set('cM_deleteScheduleWeekDay',<DeleteScheduleWeekDay/>)
dataModal.set('cM_deleteScheduleWeek',<DeleteScheduleWeek/>)
dataModal.set('ts_toBeChecked',<ToBeChecked/>)
dataModal.set('ts_checked',<Checked/>)
dataModal.set('ts_validateCheck',<ValidateTime/>)
dataModal.set('admin_c_add',<AddRole/>)
dataModal.set('admin_u_modify',<ModifyRole/>)
dataModal.set('admin_u_transfert',<Transfert/>)
dataModal.set('admin_d_delete',<DeleteRole/>)
dataModal.set('rating_temp_worker',<ModalRatingTempWorker/>)
dataModal.set('mission_cancel',<ModalMissionCancel/>)
dataModal.set('mission_delete',<ModalMissionDelete/>)

const Datamodal = () => {
    const {modalType}=UseContext()
    return (
        <>
            {dataModal.get(modalType)}
        </>
    );
};

export default Datamodal;