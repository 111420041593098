import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import Modalheader from "../../../components/header";
import React, {useState} from "react";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import ModalBody from "../../../components/body";
import {toast} from "react-toastify";

const DeleteScheduleWeekDay = () => {
    const {toggle}=useModal()
    const {activeScheduleWeekDay, setActiveScheduleWeekDay, setData, data, handleNext, updSchedule, setUpdSchedule} = UseContext()
    const [selectDeleteOption, setSelectDeleteOption] = useState('thisDay')

    const handleSubmit = (e) => {
        e.preventDefault()

        if(selectDeleteOption !== '' && activeScheduleWeekDay){
            let validDeleteOption = false
            const activeDate = data.schedule[activeScheduleWeekDay[0]].days[activeScheduleWeekDay[1]].date
            const activePeriods = data.schedule[activeScheduleWeekDay[0]].days[activeScheduleWeekDay[1]].periods

            if(selectDeleteOption === 'thisDay'){
                validDeleteOption = true;

                for (let i = 0; i < data.schedule.length; i++) {
                    for (let j = 0; j < data.schedule[i].days.length; j++) {
                        if(data.schedule[i].days[j].date === activeDate) {
                            delete data.schedule[i].days[j].periods;
                        }
                    }
                }
            } else if (selectDeleteOption === 'thisDayAndOthers'){
                validDeleteOption = true;

                for (let i = 0; i < data.schedule.length; i++) {
                    for (let j = 0; j < data.schedule[i].days.length; j++) {
                        if(data.schedule[i].days[j].date >= activeDate){
                            if(data.schedule[i].days[j].periods) {
                                if (
                                    data.schedule[i].days[j].periods.periode_one_start === activePeriods.periode_one_start &&
                                    data.schedule[i].days[j].periods.periode_one_end === activePeriods.periode_one_end &&
                                    data.schedule[i].days[j].periods.periode_two_start === activePeriods.periode_two_start &&
                                    data.schedule[i].days[j].periods.periode_two_end === activePeriods.periode_two_end &&
                                    data.schedule[i].days[j].periods.periode_three_start === activePeriods.periode_three_start &&
                                    data.schedule[i].days[j].periods.periode_three_end === activePeriods.periode_three_end
                                ) {
                                    delete data.schedule[i].days[j].periods;
                                }
                            }
                        }
                    }
                }
            } else if (selectDeleteOption === 'allSame'){
                validDeleteOption = true;

                for (let i = 0; i < data.schedule.length; i++) {
                    for (let j = 0; j < data.schedule[i].days.length; j++) {
                        if(data.schedule[i].days[j].periods){
                            if(
                                data.schedule[i].days[j].periods.periode_one_start === activePeriods.periode_one_start &&
                                data.schedule[i].days[j].periods.periode_one_end === activePeriods.periode_one_end &&
                                data.schedule[i].days[j].periods.periode_two_start === activePeriods.periode_two_start &&
                                data.schedule[i].days[j].periods.periode_two_end === activePeriods.periode_two_end &&
                                data.schedule[i].days[j].periods.periode_three_start === activePeriods.periode_three_start &&
                                data.schedule[i].days[j].periods.periode_three_end === activePeriods.periode_three_end
                            ){
                                delete data.schedule[i].days[j].periods;
                            }
                        }
                    }
                }
            } else {
                toast.error('Veuillez choisir une option de suppression')
            }

            if(validDeleteOption){
                data.isConfirmedSchedule = false
                setUpdSchedule(true)
                toggle()
            }
        }
        else{
            toast.error('Veuillez choisir une option de suppression')
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Effacer une journée
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <div className={'flex flex-col gap-3'}>
                    <div className={'flex gap-2'}>
                        <label className={`flex gap-2 cursor-pointer`}>
                            <input type="radio"
                                   className={'w-4 h-4 my-auto'}
                                   name={'deleteScheduleWeekDay'}
                                   onChange={() => setSelectDeleteOption('thisDay')}
                                   checked={selectDeleteOption === 'thisDay'}
                            />
                            <div className={'my-auto'}>
                                Cette journée
                            </div>
                        </label>
                    </div>
                    <div className={'flex gap-2'}>
                        <label className={`flex gap-2 cursor-pointer`}>
                            <input type="radio"
                                   className={'w-4 h-4 my-auto'}
                                   name={'deleteScheduleWeekDay'}
                                   onChange={() => setSelectDeleteOption('thisDayAndOthers')}
                                   checked={selectDeleteOption === 'thisDayAndOthers'}
                            />
                            <div className={'my-auto'}>
                                Cette journée et toutes les suivantes aux mêmes périodes
                            </div>
                        </label>
                    </div>
                    <div className={'flex gap-2'}>
                        <label className={`flex gap-2 cursor-pointer`}>
                            <input type="radio"
                                   className={'w-4 h-4 my-auto'}
                                   name={'deleteScheduleWeekDay'}
                                   onChange={() => setSelectDeleteOption('allSame')}
                                   checked={selectDeleteOption === 'allSame'}
                            />
                            <div className={'my-auto'}>
                                Toutes les journées aux mêmes périodes
                            </div>
                        </label>
                    </div>
                </div>
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
                    Annuler
                </Button>
                <Button size={'LG'} onClick={handleSubmit}>
                    Confirmer
                </Button>
            </ModalFooter>
        </>
    );
}

export default DeleteScheduleWeekDay;
