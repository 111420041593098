import {useEffect, useState} from 'react';
import ApiDatabase from 'server'
import UseContext from "hooks/useContext";
import {useLocation} from "react-router-dom";

const Usefetchdata = () => {
    const {
        refreshUser,
        refreshCompany,
        setCompanyData,
        setSwitchCompany,
        refreshSwitchCompany,
        setRefreshStaff,
        refreshStaff,
        setUserDatas,
    } = UseContext()

    const [userData, setUserData] = useState([])

    const location = useLocation();

    const token = localStorage.getItem('xsrfToken')

    // Information User
    useEffect(() => {
        if (token) {
            ApiDatabase.getUserProfile({token},
                (data => {
                    setUserData(data)
                    setUserDatas(data)
                    if (data.role !== localStorage.getItem('rUser')){
                        localStorage.setItem('rUser',data.role)
                    }
                }),
                (err => {
                    localStorage.removeItem('xsrfToken')
                    localStorage.removeItem('rUser')
                    window.location.href = process.env.REACT_APP_COMPANY_LOGIN + '?redirect=' + encodeURIComponent(location.pathname)
                })
            )
        }
    }, [refreshUser, token])

    // Information Entreprise
    useEffect(() => {
        if (token) {
            ApiDatabase.getCompanyProfile({token},
                (data) => {
                    if(Object.keys(data).length){
                        setCompanyData(data)
                        setRefreshStaff(!refreshStaff)
                    }
                },
                (err) => {
                    localStorage.removeItem('xsrfToken')
                    localStorage.removeItem('rUser')
                    window.location.href = process.env.REACT_APP_COMPANY_LOGIN + '?redirect=' + encodeURIComponent(location.pathname)
                })
        }
    }, [refreshCompany, token])

    useEffect(() => {
        if (token) {
            ApiDatabase.getCompanySwitch({token},
                (data) => {
                    setSwitchCompany(data)
                },
                (err) => {
                    console.log(err)
                })
        }
    }, [refreshSwitchCompany, token])


    return {
        userData
    }

}

export default Usefetchdata;