import React, {useEffect, useRef, useState} from "react";
import UseContext from "hooks/useContext";
import {CheckIcon, SelectorArrow} from "../../../assets/icons";
import ApiDatabase from 'server'
import ExternalTooltip from "../../tooltips/ExternalTooltip";
import {toast} from "react-toastify";
import useFetchData from "../../../hooks/useFetchData";

const SwitchCompany = () => {
    const {userData} = useFetchData();
    const {switchCompany, refreshCompany, setRefreshCompany, refreshSwitchCompany, setRefreshSwitchCompany} = UseContext()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isWaiting, setIsWaiting] = useState(true);
    const [confirmTutoSwitchCompany, setConfirmTutoSwitchCompany] = useState(true)
    const [onClickConfirm, setOnClickConfirm] = useState(false)
    const dropDownRef = useRef()
    const token = localStorage.getItem('xsrfToken')
    //get url and find createOfMission in it
    const url = window.location.href
    const createOfMission = url.includes('createOfMission')

    useEffect(() => {
        setIsWaiting(true)
        if(switchCompany.length >= 2) {
            setIsWaiting(false)
        } else {
            setIsWaiting(false)
        }
    }, [switchCompany])

    useEffect(() => {
        let handler = (e) => {
            if (dropDownRef && dropDownRef.current && !dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown",handler)

        return()=>{
            document.removeEventListener("mousedown",handler)
        }
    })

    const switchCompanyHandler = (idCompany) => {
        if(switchCompany.length >= 2 && token && idCompany) {
            for (let i = 0; i < switchCompany.length; i++) {
                if(switchCompany[i]._id === idCompany && !switchCompany[i].is_active) {
                    setDropdownOpen(false)
                    setIsWaiting(true)
                    ApiDatabase.putSwitchCompany({token, idCompany},
                        (data) => {
                            if (data?.role) localStorage.setItem('rUser', data.role)
                            setRefreshCompany(!refreshCompany)
                            setRefreshSwitchCompany(!refreshSwitchCompany)
                        },
                        (err) => {
                            console.log('err', err)
                        }
                    )
                    break;
                }
            }
        }
    }

    useEffect(() => {
        if(onClickConfirm && !confirmTutoSwitchCompany){
            if(token){
                ApiDatabase.putCheckTutoSwitchCompany({token}, (data) => {
                    setConfirmTutoSwitchCompany(true)
                }, (err) => {
                    setConfirmTutoSwitchCompany(false)
                })
            } else {
                toast.error('Une erreur est survenue, veuillez réessayer plus tard.')
            }
        }
    }, [onClickConfirm])

    useEffect(() => {
        if(Object.keys(userData).length > 0){
            setConfirmTutoSwitchCompany(userData.tutoSwitchCompany ?? false)
        }
    }, [userData])

    return (
        <>
            {
                !isWaiting ? (
                    <>
                        {
                            switchCompany.length >= 2 ? (
                                <>
                                    <div ref={dropDownRef} className={'relative select-none animate-fadeIn'}>
                                        {
                                            confirmTutoSwitchCompany ? (
                                                <div className={`flex ${!createOfMission ? 'cursor-pointer' : ''} my-auto`} onClick={() => setDropdownOpen(!dropdownOpen)}>
                                                    <div className={'border rounded px-3 py-1 flex justify-between gap-2 w-[210px]'}>
                                                        <div className={'my-auto flex gap-2'}>
                                                            <div className={'my-auto'}>
                                                                <div className={`border rounded bg-gray-100 ${!createOfMission ? 'text-gray-600': 'text-gray-500'} font-inter ft-xs w-[36px] h-[36px] flex justify-center items-center`}>
                                                                    {switchCompany.find((company) => company.is_active).name_commercial.split(' ').map((word) => word[0]).join('').substring(0, 2)}
                                                                </div>
                                                            </div>
                                                            <div className={'my-auto flex flex-col'}>
                                                                <div className={`text-ellipsis whitespace-nowrap max-w-[110px] overflow-hidden ${!createOfMission ? '': 'text-gray-500'}`}>
                                                                    {switchCompany.find((company) => company.is_active).name_commercial}
                                                                </div>
                                                                <div className={`ft-sm ${!createOfMission ? 'text-gray-500': 'text-gray-400'} text-ellipsis whitespace-nowrap max-w-[110px] overflow-hidden`}>
                                                                    {switchCompany.find((company) => company.is_active).reason_social}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={'my-auto'}>
                                                            {
                                                                !createOfMission && (
                                                                    <SelectorArrow wh={20} color={'#6B7280'}/>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <ExternalTooltip title={'Un compte, plusieurs entreprises.'}
                                                                     description={'Managez et créez des missions depuis vos autres entreprises depuis cette section.'}
                                                                     confirm={confirmTutoSwitchCompany}
                                                                     setOnClickConfirm={setOnClickConfirm}
                                                    >
                                                        <div className={`flex ${!createOfMission ? 'cursor-pointer' : ''} my-auto`}>
                                                            <div className={'border rounded px-3 py-1 flex justify-between gap-2 w-[210px]'}>
                                                                <div className={'my-auto flex gap-2'}>
                                                                    <div className={'my-auto'}>
                                                                        <div className={`border rounded bg-gray-100 ${!createOfMission ? 'text-gray-600': 'text-gray-500'} font-inter ft-xs w-[36px] h-[36px] flex justify-center items-center`}>
                                                                            {switchCompany.find((company) => company.is_active).name_commercial.split(' ').map((word) => word[0]).join('').substring(0, 2)}
                                                                        </div>
                                                                    </div>
                                                                    <div className={'my-auto flex flex-col'}>
                                                                        <div className={`text-ellipsis whitespace-nowrap max-w-[110px] overflow-hidden ${!createOfMission ? '': 'text-gray-500'}`}>
                                                                            {switchCompany.find((company) => company.is_active).name_commercial}
                                                                        </div>
                                                                        <div className={`ft-sm ${!createOfMission ? 'text-gray-500': 'text-gray-400'} text-ellipsis whitespace-nowrap max-w-[110px] overflow-hidden`}>
                                                                            {switchCompany.find((company) => company.is_active).reason_social}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={'my-auto'}>
                                                                    {
                                                                        !createOfMission && (
                                                                            <SelectorArrow wh={20} color={'#6B7280'}/>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ExternalTooltip>
                                                </>
                                            )
                                        }

                                        {(dropdownOpen && !createOfMission) &&
                                            <div className={`${dropdownOpen ? "" : "hidden"}  absolute border rounded shadow-lg h-30 w-[260px] z-50 bg-gray-50 overflow-x-hidden overflow-y-auto max-h-80`}>
                                                <div className={'py-1 flex flex-col gap-2'}>
                                                    {
                                                        switchCompany.map((company, index) => (
                                                            <div key={index} className={`flex ${!company.is_active ? 'cursor-pointer' : ''} my-auto`} onClick={() => switchCompanyHandler(company._id)}>
                                                                <div className={'px-3 py-1 flex gap-2'}>
                                                                    <div className={'my-auto'}>
                                                                        <div className={'border rounded bg-gray-100 text-gray-600 font-inter ft-xs w-[36px] h-[36px] flex justify-center items-center'}>
                                                                            {!company.is_active ? company.name_commercial.split(' ').map((word) => word[0]).join('').substring(0, 2) : <CheckIcon w={14} h={10} color={'#374151'}/>}
                                                                        </div>
                                                                    </div>
                                                                    <div className={'my-auto flex flex-col'}>
                                                                        <div className={'text-ellipsis whitespace-nowrap max-w-[190px] overflow-hidden'}>
                                                                            {company.name_commercial}
                                                                        </div>
                                                                        <div className={'ft-sm text-gray-500'}>
                                                                            {company.reason_social}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>
                            ) : ''
                        }
                    </>
                ) : (
                    <>
                        <div className={'flex cursor-pointer my-auto animate-fadeIn'}>
                            <div className={'border rounded px-3 py-1 flex justify-between gap-2 w-[210px]'}>
                                <div className={'my-auto flex gap-2'}>
                                    <div className={'my-auto'}>
                                        <div className={'border rounded bg-gray-100 text-gray-600 font-inter ft-xs w-[36px] h-[36px] flex justify-center items-center'}></div>
                                    </div>
                                    <div className={'my-auto flex flex-col'}>
                                        <div className={'text-ellipsis whitespace-nowrap max-w-[110px] overflow-hidden'}>
                                            <div className="h-4 bg-gray-200 rounded w-20 my-1"></div>
                                        </div>
                                        <div className={'ft-sm text-gray-500 text-ellipsis whitespace-nowrap max-w-[110px] overflow-hidden'}>
                                            <div className="h-2 bg-gray-200 rounded w-10 my-1"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'my-auto'}>
                                    <SelectorArrow wh={20} color={'#6B7280'}/>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default SwitchCompany