import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import React from "react";

const DeleteScheduleWeek = () => {
    const {toggle}=useModal()
    const {setData, data, activeScheduleWeekDay, setUpdSchedule} = UseContext()

    const handleSubmit = (e) => {
        e.preventDefault()

        for (let i = 0; i < data.schedule.length; i++) {
            if(i === activeScheduleWeekDay[0]){
                for (let j = 0; j < data.schedule[i].days.length; j++) {
                    if (data.schedule[i].days[j].periods) {
                        delete data.schedule[i].days[j].periods
                    }
                }
            }
        }

        setUpdSchedule(true)
        setData(data)
        toggle()
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Effacer toute la sélection
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <div className={'flex flex-col'}>
                    <div className={'ft-sm text-gray-500'}>
                        Êtes-vous sûr d’effacer toutes les périodes travaillées ? Cette action est irréversible.
                    </div>
                </div>
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
                    Annuler
                </Button>
                <Button size={'LG'} onClick={handleSubmit}>
                    Confirmer
                </Button>
            </ModalFooter>
        </>
    );

}

export default DeleteScheduleWeek