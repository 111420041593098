import Button from "../button";

const ExternalTooltip = ({title, description, confirm, setOnClickConfirm, children}) => {

    const handleConfirm = () => {
        setOnClickConfirm(true);
    }

    return (
        <>
            <div className={'relative'}>
                {children}
                <div className="z-20 w-[319px] absolute transition duration-150 ease-in-out left-0 text-white left-2/3 -translate-x-1/2">
                    <div className="h-0 w-0 border-x-8 border-x-white border-b-[12px] border-b-blue-500 mx-auto"></div>
                    <div className={'bg-blue-500 rounded shadow-lg p-4 flex flex-col gap-3'}>
                        <div className="text-lg">{title}</div>
                        <div className="text-sm">{description}</div>
                        {
                            !confirm && (
                                <>
                                    <div className="flex justify-end">
                                        <Button color={'BLUE'} size={'SM'} onClick={() => handleConfirm()}>D'accord</Button>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExternalTooltip;