import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

// import components
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import axios from "axios";
import {Information} from "assets/icons";
import Button from "components/button";
import useModal from "components/modal/useModal";
import apiDataBase from "server";
import UseContext from "hooks/useContext";

const ModalTokenPassword = () => {
    const {email, typeNav} = UseContext()
    const [token, setToken] = useState('')
    const {toggle} = useModal()
    const [validToken, setValidToken] = useState(false)
    const navigate = useNavigate()

    const handleMail = async (e) => {
        e.preventDefault()
        apiDataBase.postTokenPwdMail({email},
            (data)=>{
                /*console.log(data)*/},
            (err)=>{
                console.log(err)})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        apiDataBase.postTokenPwd({email,token},
            (data)=>{
                if (data.error) {
                    setValidToken(true)
                } else {
                    setValidToken(false)
                    localStorage.setItem('email', email)
                    if (typeNav === 2) navigate('/lostforgot/entreprise')
                    else navigate('/lostforgot/interimaire')
                }
            },
            (err)=>{
                console.log(err)})

    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={false}>
                Validation du code Mot de passe oublié
            </Modalheader>
            {/*Body*/}
            <ModalBody>
                <form className='flex flex-col w-full'>
                    <label htmlFor='token'>Validation du code
                        :</label>
                    <p className={validToken ?
                        "text-red-500 flex flex-row gap-4 items-center"
                        : 'hidden'
                    }>
                                            <span>
                                                <Information
                                                    color={'#FF9600'}
                                                    wh={14}/>
                                                </span>
                        Veuillez indiquer un code valide.
                    </p>
                    <input
                        className='w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg '
                        name='token'
                        onChange={(e) => setToken(e.target.value)}
                        value={token}
                    />
                </form>
                <span
                    className='flex justify-center items-center mt-4 ft-xs cursor-pointer'
                    onClick={handleMail}>Renvoyer le code </span>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleSubmit}
                        size={'LG'}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};


export default ModalTokenPassword;
