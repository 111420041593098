import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Layout from "pages/layout";
import Unauthorized from "pages/unAuthorized";
import RequireAuth from "components/requireAuth";
import Dashboard from "pages/dashboard";
import Profil from "pages/profil/profil";
import MyAccountContent
    from "pages/profil/content/myAccount.content";
import LegalInformationContent
    from "pages/profil/content/legalInformation.content";
import ModesOfPaymentContent
    from "pages/profil/content/modesOfPayment.content";
import BonusesCompensationContent
    from "pages/profil/content/bonusesCompensation.content";
import PaymentsTermsContent
    from "pages/profil/content/paymentsTerms.content";
import Mission from "pages/missions/mission";
import EndContent from "pages/missions/content/end.content";
import InProgressContent
    from "pages/missions/content/inProgress.content";
import ComingSoonContent
    from "pages/missions/content/comingSoon.content";
import Files from "pages/files/files";
import Contract from "pages/files/contract/contract";
import SignedContent
    from "pages/files/contract/content/signed.content";
import ToBeSignedContent
    from "pages/files/contract/content/toBeSigned.content";
import Invoices from "pages/files/invoices/invoices";
import SettleContent
    from "pages/files/invoices/content/settle.content";
import ToBeSetContent
    from "pages/files/invoices/content/toBeSet.content";
import AdminCompany from "pages/admin";
import CreateOfMission from "pages/createOfMission";
import Activity from "pages/activity";
import TimeSheet from "pages/timeSheet/timeSheet";
import CheckedContent
    from "pages/timeSheet/content/checked.content";
import ToBeCheckedContent
    from "pages/timeSheet/content/toBeChecked.content";
import LitigationContent
    from "pages/timeSheet/content/litigation.content";
import PersonalInformation
    from "pages/personalInformation/personalInformation";
import MyAccountPersonalContent
    from "pages/personalInformation/content/myAccountPersonal.content";
import ParamsContent
    from "pages/personalInformation/content/params.content";
import MissionFiche from "pages/missionFiche";
import LayoutCompany from "components/layout";
import PdfView from "pages/pdfView";


const Index = () => {
    // {/*'200':'Observateur',*/}
    // {/*'205':'Manageur',*/}
    // {/*'210':'Responsable',*/}
    // {/*'215':'Directeur',*/}
    // {/*'220':'Superviseur'*/}
    // {/*'225':'Administrateur'*/}
    const ROLES = {
        'interim': '100',
        'observateur': '200',
        'manageur': '205',
        'responsable': '210',
        'directeur': '215',
        'superviseur': '220',
        'admin': '225'
    }
    return (
        <>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route path='/entreprise' element={<LayoutCompany/>}/>
                    <Route element={<RequireAuth allowedRoles={[ROLES.observateur, ROLES.manageur, ROLES.responsable, ROLES.directeur, ROLES.admin, ROLES.superviseur]}/>}>
                        <Route path='dashboard'
                               allowedRoles={[ROLES.observateur, ROLES.manageur, ROLES.responsable, ROLES.directeur, ROLES.admin]}
                               element={<Dashboard/>}/>
                        <Route element={<RequireAuth
                            allowedRoles={[ROLES.directeur, ROLES.admin]}/>}>
                            <Route path='profil' element={<Profil/>}>
                                <Route path='myAccountCompany'
                                       element={<MyAccountContent/>}/>
                                <Route path='legalInformation' element={
                                    <LegalInformationContent/>}/>
                                <Route path='modesOfPayments' element={
                                    <ModesOfPaymentContent/>}/>
                                <Route path='bonusesAndCompensation'
                                       element={
                                           <BonusesCompensationContent/>}/>
                                <Route path='paymentsTerms'
                                       element={<PaymentsTermsContent/>}/>
                            </Route>
                        </Route>
                        <Route path={'missions'}
                               allowedRoles={[ROLES.observateur, ROLES.responsable, ROLES.directeur, ROLES.superviseur, ROLES.admin]}
                               element={<Mission/>}>
                            <Route path={'end'}
                                   element={<EndContent/>}/>
                            <Route path={'inProgress'}
                                   element={<InProgressContent/>}/>
                            <Route path={'comingSoon'}
                                   element={<ComingSoonContent/>}/>
                        </Route>
                        <Route path={'mission/:id'}
                               allowedRoles={[ROLES.observateur, ROLES.responsable, ROLES.directeur, ROLES.superviseur, ROLES.admin]}
                               element={<MissionFiche/>}
                        />

                        <Route path={'files'}
                               allowedRoles={[ROLES.observateur, ROLES.responsable, ROLES.directeur, ROLES.admin]}
                               element={<Files/>}
                        >
                            <Route path={'contract'}
                                   element={<Contract/>}>
                                <Route path={'signed'}
                                       element={<SignedContent/>}/>
                                <Route path={'toBeSigned'} element={
                                    <ToBeSignedContent/>}/>
                            </Route>
                            <Route path={'invoices'}
                                   element={<Invoices/>}>
                                <Route path={'settle'}
                                       element={<SettleContent/>}/>
                                <Route path={'toBeSet'}
                                       element={<ToBeSetContent/>}/>
                            </Route>
                        </Route>
                        <Route path={'timeSheet'}
                               allowedRoles={[ROLES.observateur, ROLES.manageur, ROLES.responsable, ROLES.directeur, ROLES.superviseur, ROLES.admin]}
                               element={<TimeSheet/>}
                        >
                            <Route path={'checked'}
                                   element={<CheckedContent/>}/>
                            <Route path={'toBeChecked'}
                                   element={<ToBeCheckedContent/>}/>
                            <Route path={'litigation'}
                                   element={<LitigationContent/>}/>
                        </Route>
                        <Route path={'personalInformations'}
                               allowedRoles={[ROLES.observateur, ROLES.manageur, ROLES.responsable, ROLES.directeur, ROLES.admin]}
                               element={<PersonalInformation/>}
                        >
                            <Route path={'myPersonalAccount'}
                                   element={
                                       <MyAccountPersonalContent/>}/>
                            <Route path={'params'}
                                   element={<ParamsContent/>}/>
                        </Route>
                        <Route element={<RequireAuth
                            allowedRoles={[ROLES.admin]}/>}
                        >
                            <Route path={'adminCompany'}
                                   element={<AdminCompany/>}/>
                        </Route>
                        <Route element={<RequireAuth
                            allowedRoles={[ROLES.responsable, ROLES.directeur, ROLES.superviseur, ROLES.admin]}/>}
                        >
                            <Route path={'createOfMission'}
                                   element={<CreateOfMission/>}/>
                        </Route>
                        <Route path={'activityCompany'}
                               allowedRoles={[ROLES.observateur, ROLES.responsable, ROLES.directeur, ROLES.admin]}
                               element={<Activity/>}
                        />
                    </Route>
                    <Route path='pdfView' element={<PdfView/>}/>
                    <Route path={'*'} element={<Unauthorized/>}/>
                    <Route path={""} element={<Navigate to="/dashboard"/>}/>
                    <Route path={"*"} element={<Navigate to="/dashboard"/>}/>
                </Route>
            </Routes>
        </>
    )
        ;
};

export default Index;