import React from 'react';
import {dateExp, dateWordShort} from "helper/helper";
import Chip from "components/chip/chip";
import {DocumentIcon} from "assets/icons";

const Tbodyinvoice = ({champsBody}) => {
    return (
        <>
            {champsBody?.reverse().map(item =>
                <tr key={item._id} className={'border-b-2 border-gray-100'}>
                    {/*Mois*/}
                    <td className={'uppercase py-5'}>{dateExp(item.date_facture)}</td>
                    {/*Montant*/}
                    <td className={'py-5'}>{item.amount ?`${item.amount} €`:''} </td>
                    {/*Etat*/}
                    <td className={'py-5'}>{item.status?
                        <Chip type={'INDICATOR'}
                              color={'INFO'}>Réglé</Chip>
                        :
                        <Chip type={'INDICATOR'}
                              color={'ALERT'}>À régler</Chip>
                    }</td>
                    {/*Date*/}
                    <td className={'py-5'}>{dateWordShort(item.date_facture)}</td>
                    {/*pdf*/}
                    <td className={'py-5'}>
                        <div className={'cursor-pointer'}>
                            <a target='_blank' href={`${process.env.REACT_APP_COMPANY_PDF}${item.filePath}`} >
                                <DocumentIcon wh={24} color={'#111827'}/>
                            </a>
                        </div>
                    </td>
                </tr>
            )}

        </>
    );
};

export default Tbodyinvoice;