import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";


const CloseProcess = () => {
    const {toggle} = useModal()
    const {setPage,setData,setMissionCostEstimate} = UseContext()
    const handleClose = (e) => {
        e.preventDefault()
        setData({activity: '',
            qualification: '',
            tools: [],
            licences: [],
            languages: [],
            contact_name: '',
            contact_poste: '',
            location: '',
            dateStart: '',
            dateEnd: '',
            schedule: [],
            isScheduleCheck: true, // if the company choose to have a schedule
            isConfirmedSchedule: false, // if the company valid schedule with empty periods
            isScheduleRequired: false, // if the company have a 1 day mission
            users_invite: [],
            motif: '',
            other: '',
            other_interim: '',
            salary: ''})
        setPage(0)
        setMissionCostEstimate(0)
        toggle()
    }
    const handleReturn=(e)=>{
        e.preventDefault()
        toggle()
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Quitter sans enregistrer
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <p>Êtes-vous sûr de vouloir quitter sans enregistrer
                    les
                    informations ?</p>
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={handleReturn}>Annuler</Button>
                <Button size={'LG'}
                        onClick={handleClose}>Confirmer</Button>
            </ModalFooter>

        </>
    );
};

export default CloseProcess;