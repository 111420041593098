import React from 'react';
import EmptyState from "components/emptyState";

const ModesOfPaymentContent = () => {
    return (
        <>
            <EmptyState type={'coming'}/>
        </>
    );
};

export default ModesOfPaymentContent;