import React from 'react';
import {Tabs, Tab} from "components/tabs/tabs";
const ProfilSubNav = () => {
    return (
        <>
            <Tabs>
                <Tab label={"myAccountCompany"} tabName={'Compte'}/>
                <Tab label={'legalInformation'} tabName={'Information légales'}/>
                <Tab label={'modesOfPayments'} tabName={'Conditions de rémunération'}/>
                <Tab label={'bonusesAndCompensation'} tabName={'Primes & exonération'}/>
                <Tab label={'paymentsTerms'} tabName={'Paiement'}/>

            </Tabs>
        </>
    );
};

export default ProfilSubNav;