import React, {useEffect, useState} from 'react';
import CardMission from "components/card/mission";
import ApiDataBase from 'server'
import UseContext from "hooks/useContext";
import EmptyStateCompany from "components/emptyState";
import {dateToEpoch} from "../../../helper/helper";

const EndContent = () => {
    const {companyData} = UseContext()
    const [dataMission, setDataMission] = useState([])
    const [endMission, setEndMission] = useState([])
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(() => {
        setIsWaiting(true)
        if (Object.keys(companyData).length) {
            ApiDataBase.getMissions({idC: companyData._id},
                (data) => {
                    setDataMission(data)
                    setIsWaiting(false)
                },
                (err) => {
                    console.warn(err)
                })
        }
    }, [companyData])

    useEffect(() => {
        let tampon = []
        for (let m in dataMission) {
            const d0 = dateToEpoch(new Date())
            const d2 = dateToEpoch(new Date(dataMission[m].duration?.dateend))
            if (d0.getTime() > d2.getTime()) {
                tampon.push(dataMission[m])
            }
        }
        setEndMission(tampon)
    }, [dataMission])

    return (
        <div className={'flex flex-wrap gap-6 mx-2'}>
            {
                isWaiting ? (
                    <>
                        {
                            [1, 2, 3, 4, 5].map((i, index) => (
                                <CardMission key={index} data={i} isWaiting={isWaiting}/>
                            ))
                        }
                    </>
                ) : (
                    <>
                        {
                            endMission.length ?
                                endMission.reverse().map((item, index) =>
                                    <CardMission key={index} data={item}/>
                                )
                                :
                                <EmptyStateCompany type={'mission_done'}/>
                        }
                    </>
                )
            }
        </div>
    );
};

export default EndContent;