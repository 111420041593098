import {dateWordShort} from "helper/helper";
import UseContext from "hooks/useContext";
import UseModal from "components/modal/useModal";
import Badge from "../../badge/badge";
import RatingTempWorker from "../../ratingTempWorker/ratingTempWorker";


const Tbodyhours = ({champsBody}) => {
    const {setObjID,roleUser} = UseContext()
    const {toggle} = UseModal()
    let nbTutoDisplay = 0;

    const statusTextDay = {
        1: 'Vérifier',
        2: 'Litige',
        3: 'Complet',
    }
    const statusColorDay = {
        1: 'ALERT',
        2: 'WARNING',
        3: 'VALID',
    }

    const handleModal=(e,i)=>{
        e.preventDefault()
        setObjID(i)
        toggle('ts_toBeChecked')
    }

    const handleModalConsult=(e,i)=>{
        e.preventDefault()
        setObjID(i)
        toggle('ts_checked')
    }

    return (
        <>
            {champsBody?.map(item =>
                <tr key={item._id} className={'border-b-2 border-gray-100'}>
                    <td className={'py-5 text-gray-900'}>{item.id_time_sheet}</td>
                    <td className={'py-5'}>
                        <div className={'text-gray-900'}>{item.mission[0].title}</div>
                        <div>n°{item.mission[0].id_mission}</div>
                    </td>
                    <td className={'py-5'}>
                        <div className={'flex gap-2'}>
                            <div className={'my-auto underline decoration-dashed'}>{item.user[0]?.firstname} {item.user[0]?.lastname}</div>
                            <div className={'my-auto cursor-pointer'}>
                                <RatingTempWorker review={item.review} isTutoCanBeDisplayed={nbTutoDisplay === 0} idMission={item.mission[0]._id} idTempWorker={item.user[0]?._id} idTimeSheet={item._id}/>
                                {(() => {
                                    nbTutoDisplay++;
                                    return null;
                                })()}
                            </div>
                        </div>
                    </td>
                    <td className={'py-5'}>{dateWordShort(item.periode_start)} > {dateWordShort(item.periode_end)}</td>
                    <td className={'py-5 flex items-center justify-center'}>{
                        item.validated === 1 && roleUser !=='200' ?
                            <div className={'cursor-pointer'} onClick={(e) => {
                                handleModal(e,item._id)
                            }}>
                                <Badge type={statusColorDay[item.validated]}>{statusTextDay[item.validated]}</Badge>
                            </div>
                            :
                            <div className={'cursor-pointer'} onClick={(e) => {
                                handleModalConsult(e,item._id)
                            }}>
                                <Badge type={statusColorDay[item.validated]}>{statusTextDay[item.validated]}</Badge>
                            </div>
                    }</td>
                </tr>
            )}
        </>
    );
};

export default Tbodyhours;