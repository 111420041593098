import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import UseModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import {Mail} from "assets/icons";
import ApiDatabase from 'server'
import {toast} from "react-toastify";

const ModifyRole = () => {
    const {toggle} = UseModal()
    const {
        adminData,
        setAdminData,
        setRefreshStaff,
        refreshStaff,
        setModalType,
        companyData
    } = UseContext()
    const contentAdmin = {
        200: 'Observateur',
        205: 'Manageur',
        210: 'Responsable',
        215: 'Directeur',
        220: 'Superviseur',
        225: 'Administrateur'
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (adminData.role === '225') {
            setModalType('admin_u_transfert')
        } else {
            adminData.id_company = companyData._id
            ApiDatabase.putModifyStaff({adminData},
                (data) => {
                    toast.success(`Modification de ${adminData.firstname} ${adminData.lastname} prends le rôle ${contentAdmin[adminData.role]}`)
                    setRefreshStaff(!refreshStaff)
                    toggle()
                },
                (err) => {
                    console.log(err)
                    toast.error('Une erreur est survenue')
                })
        }

    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Modifier un membre
            </Modalheader>
            <ModalBody>
                <div className="grid grid-cols-2 gap-6">
                    <div className="col-span-2">
                        <label htmlFor="email"
                               className="block ft-sm font-medium  text-gray-500">Email</label>
                        <div className="relative text-gray-500">
                            <input type="email"
                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                   value={adminData.email}
                                   onChange={(e) => {
                                       setAdminData(adminData => ({
                                           ...adminData,
                                           email: e.target.value
                                       }))
                                   }}
                                   disabled
                                   required/>
                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Mail wh={18} color={'#666666'}
                                      strokeW={2}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="firstname"
                               className="block ft-sm font-medium text-gray-500">Prénom</label>
                        <div className="relative text-gray-500">
                            <input type="text"
                                   className={'w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg'}
                                   onChange={(e) => {
                                       setAdminData(adminData => ({
                                           ...adminData,
                                           firstname: e.target.value
                                       }))
                                   }}
                                   value={adminData.firstname}
                                   disabled
                                   required/>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="lastname"
                               className="block ft-sm font-medium text-gray-500">Nom</label>
                        <div className="relative text-gray-500">
                            <input type="text"
                                   className={'w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg'}
                                   required
                                   value={adminData.lastname}
                                   onChange={(e) => {
                                       setAdminData(adminData => ({
                                           ...adminData,
                                           lastname: e.target.value
                                       }))
                                   }}
                                   disabled
                            />
                        </div>
                    </div>
                    <div className="col-span-2">
                        {/*'200':'Observateur',*/}
                        {/*'205':'Manageur',*/}
                        {/*'210':'Responsable',*/}
                        {/*'215':'Directeur',*/}
                        {/*'220':'Superviseur'*/}
                        {/*'225':'Administrateur'*/}
                        <label htmlFor="role" className="block ft-sm font-medium text-gray-500">Rôle</label>
                        <select name="role" id=""
                                className={'w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg'}
                                value={adminData.role}
                                onChange={(e) => {
                                    setAdminData(adminData => ({
                                        ...adminData,
                                        role: e.target.value
                                    }))
                                }}>
                            <option value="200">Observateur</option>
                            <option value="205">Manageur</option>
                            <option value="210">Responsable</option>
                            <option value="215">Directeur</option>
                            <option value="220">Superviseur</option>
                            <option value="225">Administrateur</option>
                        </select>
                        {
                            adminData.role && (
                                <div className={'text-gray-500 ft-sm mt-3'}>
                                    {
                                        (adminData.role === '200' || adminData.role === 200) && 'L’observateur peut uniquement consulter l’ensemble des factures, relevés d’heures, contrats et missions crées par l’entreprise.'
                                    }
                                    {
                                        (adminData.role === '205' || adminData.role === 205) && 'Le manageur peut uniquement vérifier des relevés d’heures.'
                                    }
                                    {
                                        (adminData.role === '210' || adminData.role === 210) && 'Le responsable peut signer des contrats, importer des factures, vérifier des relevés d’heures, et créer des missions.'
                                    }
                                    {
                                        (adminData.role === '215' || adminData.role === 215) && 'Le directeur peut signer des contrats, importer des factures, vérifier des relevés d’heures, et créer des missions et modifier le profil de l’entreprise.'
                                    }
                                    {
                                        (adminData.role === '220' || adminData.role === 220) && 'Le superviseur peut créer des missions et vérifier des relevés d\'heures.'
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>

    );
};

export default ModifyRole;