import React from 'react';
import {TabF, TabsF} from "components/tabs/tabfile";

const MissionSubNav = () => {
    return (
        <>
            <TabsF>
                <TabF label={"end"} tabName={'Terminées'}/>
                <TabF label={"inProgress"} tabName={'En cours'}/>
                <TabF label={"comingSoon"} tabName={'À venir'}/>
            </TabsF>
        </>
    );
};

export default MissionSubNav;