import React from 'react';
import SidebarUnitDesktop
    from "components/sidebar/components/SidebarUnitDesktop";
import {LogoutAdmin} from "assets/icons";

const JobbizLink = ({pathname}) => {
    return (
        <>
            <SidebarUnitDesktop routeName={"Jobbiz Entreprise"} routeSidebar={"/dashboard"}>
               <span className='shrink-0'>
                   <LogoutAdmin wh={24} color={'#6B7280'}/>
               </span>
                <span className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${pathname.includes("dashboard") && "!text-[#FF9600]"}`}>
                    {/*{language.getValue('sidebar.dashboard','Tableau de bord')}*/}
                    Retour à Jobbiz
                </span>
            </SidebarUnitDesktop>
        </>
    );
};

export default JobbizLink;