import React, {useEffect, useRef, useState} from 'react';
import useModal from "components/modal/useModal";

import AvatarUser from "components/avatar/avatar.user";
import useFetchData from "hooks/useFetchData";
import {Logout} from "assets/icons";
import {NavLink, useNavigate} from "react-router-dom";
import Mouseover from "../../../helper/mouseover";

const LogoutNavDesktop = () => {
    const {userData} = useFetchData()
    const {toggle} = useModal();
    const pRole = localStorage.getItem('rUser')
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropDownRef = useRef()
    const handleProfil = () => {
        setDropdownOpen(prev => !prev);
    };

    const tRole = {
        '200': 'Observateur',
        '205': 'Manageur',
        '210': 'Responsable',
        '215': 'Directeur',
        '220': 'Superviseur',
        '225': 'Administrateur'
    }

    const handleLogout = e => {
        e.preventDefault()
        toggle('log_d_logout')
    }

    useEffect(() => {
        let handler = (e) => {
            if (!dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown",handler)

        return()=>{
            document.removeEventListener("mousedown",handler)
        }
    })


    return (
        <div className={'relative'} ref={dropDownRef}>
            <div
                className="w-full flex items-center justify-center p-4 cursor-pointer "
                onClick={handleProfil}

            >
                <div className={'flex gap-3'}>
                    <AvatarUser size={'SM'}/>
                    <div className={'flex flex-col  justify-items-start'}>
                        <span
                            className={'ft-sm text-gray-700 truncate overflow-hidden max-w-[250px]'}>{userData?.firstname} {userData?.lastname}</span>
                        <span
                            className={'ft-xs text-gray-500'}>{tRole[pRole]}</span>
                    </div>
                </div>
            </div>
            {dropdownOpen &&
                <div
                    className={`${
                        dropdownOpen ? "" : "hidden"
                    } absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-16 z-50 bg-gray-50`}
                >
                    <NavLink
                        className="flex flex-row  items-center h-9 p-2 ft-sm cursor-pointer"
                        to={'/personalInformations/myPersonalAccount'}>
                        Mon profil
                    </NavLink>
                    {pRole === '225' ?
                        <NavLink
                            className="flex flex-row  items-center h-9 p-2 border-b  border-[#F3F4F6] ft-sm cursor-pointer"
                            to={'/adminCompany'}
                        >
                            Console d'administration
                        </NavLink>
                        : ''
                    }
                    <div
                        className="flex flex-row gap-2 items-center h-9 p-2 ft-sm text-red-500 cursor-pointer"
                        onClick={handleLogout}
                    >
                        <Logout wh={15} color={'#EF4444'}/> Déconnexion
                    </div>
                </div>
            }
        </div>
    );
};

export default LogoutNavDesktop;