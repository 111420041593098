import React from 'react';
import Thead from "components/table/components/thead";
import Tbodyinvoice from "components/table/components/tbodyinvoice";

const Tableinvoice = ({champsHeader, champsBody, isWaiting}) => {
    return (
        <>
            <div className='overflow-auto lg:overflow-visible '>
                <table className={`table-auto w-full text-gray-500 border-collapse ft-sm animate-fadeIn ${isWaiting ? 'animate-pulse' : ''}`}>
                    <thead>
                    <Thead champsHeader={champsHeader} isWaiting={isWaiting}/>
                    </thead>
                    <tbody>
                    {
                        !isWaiting ? (
                            <>
                                <Tbodyinvoice champsBody={champsBody}/>
                            </>
                        ) : null
                    }
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Tableinvoice;