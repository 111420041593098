import {
    useLocation,
    Navigate,
    Outlet
} from "react-router-dom";
import { useState} from "react";
import UseContext from "hooks/useContext";



const useToken=()=>{
    const token =localStorage.getItem('xsrfToken')
    return !!token;
}

const useRole=()=>{
   return localStorage.getItem('rUser')
}
const RequireAuth = ({ allowedRoles }) => {
    const [rAuth,setRAuth]=useState(useRole())
    const [tAuth,setTAuth]=useState(useToken())
    const location = useLocation();


    return (
        allowedRoles?.includes(rAuth)
            ? <Outlet />
            : tAuth
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : window.location.href = process.env.REACT_APP_COMPANY_LOGIN + '?redirect=' + encodeURIComponent(location.pathname)
    );
}

export default RequireAuth;