import Modalheader from "../../../components/header";
import React, {useEffect, useState} from "react";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import useModal from "../../../useModal";
import ModalBody from "../../../components/body";
import UseContext from "../../../../../hooks/useContext";
import DatePicker from "react-datepicker";
import {Calendar, ChevronRight, Close, Plus} from "../../../../../assets/icons";
import Divider from "../../../../divider/divider";
import {toast} from "react-toastify";
import {dateToEpoch} from "../../../../../helper/helper";

const addEditSchedule = () => {
    const {toggle}=useModal()
    const {activeScheduleWeekDay, setActiveScheduleWeekDay, setData, data, updSchedule, setUpdSchedule} = UseContext()
    const [actualScheduleWeekDay, setActualScheduleWeekDay] = useState(null)
    const [actualScheduleWeekDayPeriods, setActualScheduleWeekDayPeriods] = useState([]);
    const [listDay, setListDay] = useState([])
    const [isAllDay, setIsAllDay] = useState(true)
    const [selectedDays, setSelectedDays] = useState([])
    const [isRepeatPeriod, setIsRepeatPeriod] = useState(false)
    const [isWorkerPeriod, setIsWorkerPeriod] = useState(true)
    const [nbDays, setNbDays] = useState(0)
    const [maxDateRepeat, setMaxDateRepeat] = useState(null)
    const [errorMaxDateRepeat, setErrorMaxDateRepeat] = useState('');
    const [iTime, setITime] = useState(1)
    const [pTime, setPTime] = useState(true)
    const [error1, setError1] = useState('')
    const [error2, setError2] = useState('')
    const [error3, setError3] = useState('')

    useEffect(() => {
        if(activeScheduleWeekDay?.length > 0) {
            const scheduleWeekDayDate = data?.schedule[activeScheduleWeekDay[0]]?.days[activeScheduleWeekDay[1]].date;
            if(scheduleWeekDayDate){
                setActualScheduleWeekDay(scheduleWeekDayDate)
            }
        }
    }, [activeScheduleWeekDay])

    useEffect(() => {
        manageListDays()
        for (let i = 0; i < data?.schedule.length; i++) {
            for (let j = 0; j < data?.schedule[i].days.length; j++) {
                if(data?.schedule[i].days[j].date === actualScheduleWeekDay){
                    if(data?.schedule[i].days[j].periods){
                        const dataPeriods = data?.schedule[i].days[j].periods
                        setActualScheduleWeekDayPeriods(dataPeriods)

                        let nbPeriod = 0
                        if(dataPeriods.periode_two_start && dataPeriods.periode_two_end){
                            nbPeriod++
                        }
                        if(dataPeriods.periode_three_start && dataPeriods.periode_three_end){
                            nbPeriod++
                        }

                        setITime(nbPeriod + 1)
                    }
                }
            }
        }
    }, [actualScheduleWeekDay, data?.schedule])

    const manageListDays = () => {
        if(data?.schedule && actualScheduleWeekDay){
            const actualScheduleWeekDayActual = new Date(actualScheduleWeekDay);
            const actualScheduleWeekDayAdd = actualScheduleWeekDayActual.setDate(actualScheduleWeekDayActual.getDate() + 1)
            let listDays = []
            let nbDays = 0

            for (let i = 0; i < data.schedule.length; i++) {
                for (let j = 0; j < data.schedule[i].days.length; j++) {
                    if(actualScheduleWeekDayAdd <= data.schedule[i].days[j].date){
                        nbDays++
                        if(data.schedule[i].days[j].date.getDay() === 0){
                            listDays.push(7)
                        } else {
                            listDays.push(data.schedule[i].days[j].date.getDay())
                        }
                    }
                }
            }
            setNbDays(nbDays)
            //delete duplicate
            listDays = [...new Set(listDays)]

            if(nbDays >= 7){
                //sort array
                listDays.sort((a, b) => a - b)
            }

            //replace 7 by 0 in listDays
            for (let i = 0; i < listDays.length; i++) {
                if(listDays[i] === 7){
                    listDays[i] = 0
                }
            }
            setListDay(listDays)
        }
    }

    const handleITime = (e) => {
        setITime(iTime + 1)
    }
    const handleSITime = (e) => {
        setITime(iTime - 1)
        switch (iTime) {
            case 2 :
                setError2('')
                break
            case 3:
                setError3('')
                break
            default:
                break

        }
    }

    const handleChangeTime = e => {
        e.preventDefault()
        const value = e.target.value;
        const name = e.target.name;

        setActualScheduleWeekDayPeriods(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    useEffect(() => {
        if(actualScheduleWeekDayPeriods.periode_one_start || actualScheduleWeekDayPeriods.periode_one_end) {
            if (actualScheduleWeekDayPeriods.periode_one_start && actualScheduleWeekDayPeriods.periode_one_end) {
                if (actualScheduleWeekDayPeriods.periode_one_start >= actualScheduleWeekDayPeriods.periode_one_end && isWorkerPeriod) {
                    setError1('L\'heure de début doit être inférieure à l\'heure de fin');
                    return;
                } else {
                    setError1('')
                }
            } else{
                setError1('Veuillez renseigner les deux champs')
                return;
            }
        }
        if(actualScheduleWeekDayPeriods.periode_two_start || actualScheduleWeekDayPeriods.periode_two_end) {
            if (actualScheduleWeekDayPeriods.periode_two_start && actualScheduleWeekDayPeriods.periode_two_end) {
                if (actualScheduleWeekDayPeriods.periode_two_start >= actualScheduleWeekDayPeriods.periode_two_end) {
                    setError2('L\'heure de début doit être inférieure à l\'heure de fin')
                    return;
                } else {
                    setError2('')
                }
            } else {
                setError2('Veuillez renseigner les deux champs')
                return;
            }
        }
        if(actualScheduleWeekDayPeriods.periode_three_start || actualScheduleWeekDayPeriods.periode_three_end){
            if(actualScheduleWeekDayPeriods.periode_three_start && actualScheduleWeekDayPeriods.periode_three_end){
                if(actualScheduleWeekDayPeriods.periode_three_start >= actualScheduleWeekDayPeriods.periode_three_end){
                    setError3('L\'heure de début doit être inférieure à l\'heure de fin')
                    return;
                }else{
                    setError3('')
                }
            } else{
                setError3('Veuillez renseigner les deux champs')
                return;
            }
        }
        if(actualScheduleWeekDayPeriods.periode_one_end && actualScheduleWeekDayPeriods.periode_two_start) {
            if (actualScheduleWeekDayPeriods.periode_one_end >= actualScheduleWeekDayPeriods.periode_two_start) {
                setError2('La période 2 doit être supérieure à la période 1')
                return;
            } else {
                setError2('')
            }
        }
        if(actualScheduleWeekDayPeriods.periode_two_end && actualScheduleWeekDayPeriods.periode_three_start) {
            if (actualScheduleWeekDayPeriods.periode_two_end >= actualScheduleWeekDayPeriods.periode_three_start) {
                setError3('La période 3 doit être supérieure à la période 2')
            } else {
                setError3('')
            }
        }
    }, [actualScheduleWeekDayPeriods, isWorkerPeriod])

    useEffect(() => {
        if (actualScheduleWeekDayPeriods.periode_one_start && actualScheduleWeekDayPeriods.periode_one_end){
            if (actualScheduleWeekDayPeriods.periode_one_start === '00:00' && actualScheduleWeekDayPeriods.periode_one_end === '00:00'){
                setIsWorkerPeriod(false)
            }
        }
    }, [actualScheduleWeekDayPeriods])

    const manageDaysSelection = (id) => {
        if(selectedDays.includes(id)){
            setSelectedDays(selectedDays.filter(item => item !== id))
        } else {
            setSelectedDays([...selectedDays, id])
        }
    }

    const handleSubmit = () => {
        if((actualScheduleWeekDayPeriods?.periode_one_start && actualScheduleWeekDayPeriods?.periode_one_end) || !isWorkerPeriod){
            if(error1 === '' && error2 === '' && error3 === ''){
                if(isAllDay || (!isAllDay && maxDateRepeat && errorMaxDateRepeat === '')){
                    //add periods for the actual day
                    for (let i = 0; i < data.schedule.length; i++) {
                        for (let j = 0; j < data.schedule[i].days.length; j++) {
                            if(data.schedule[i].days[j].date === actualScheduleWeekDay){
                                if(!isWorkerPeriod){
                                    data.schedule[i].days[j].periods = {
                                        periode_one_start: '00:00',
                                        periode_one_end: '00:00',
                                    }
                                }
                                else{
                                    data.schedule[i].days[j].periods = actualScheduleWeekDayPeriods
                                }
                            }
                        }
                    }

                    //add periods for the selected days
                    if (isRepeatPeriod) {
                        const actualScheduleWeekDayActual = new Date(actualScheduleWeekDay);
                        const actualScheduleWeekDayAdd = dateToEpoch(new Date(actualScheduleWeekDayActual.setDate(actualScheduleWeekDayActual.getDate() + 1)))
                        const maxDateRepeatActual = dateToEpoch(new Date(maxDateRepeat));
                        for (let i = 0; i < data.schedule.length; i++) {
                            for (let j = 0; j < data.schedule[i].days.length; j++) {
                                const actualScheduleWeekDay = dateToEpoch(data.schedule[i].days[j].date);
                                if(!isAllDay && maxDateRepeatActual && errorMaxDateRepeat === ''){
                                    if (actualScheduleWeekDayAdd <= actualScheduleWeekDay && actualScheduleWeekDay <= maxDateRepeatActual) {
                                        if (data.schedule[i].days[j].date.getDay() === 0 && selectedDays.includes(7)) {
                                            if(!isWorkerPeriod){
                                                data.schedule[i].days[j].periods = {
                                                    periode_one_start: '00:00',
                                                    periode_one_end: '00:00',
                                                }
                                            }
                                            else{
                                                data.schedule[i].days[j].periods = actualScheduleWeekDayPeriods
                                            }
                                        } else {
                                            if (selectedDays.includes(data.schedule[i].days[j].date.getDay())) {
                                                if(!isWorkerPeriod){
                                                    data.schedule[i].days[j].periods = {
                                                        periode_one_start: '00:00',
                                                        periode_one_end: '00:00',
                                                    }
                                                }
                                                else{
                                                    data.schedule[i].days[j].periods = actualScheduleWeekDayPeriods
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    if (actualScheduleWeekDayAdd <= actualScheduleWeekDay) {
                                        if (data.schedule[i].days[j].date.getDay() === 0 && selectedDays.includes(7)) {
                                            if(!isWorkerPeriod){
                                                data.schedule[i].days[j].periods = {
                                                    periode_one_start: '00:00',
                                                    periode_one_end: '00:00',
                                                }
                                            }
                                            else{
                                                data.schedule[i].days[j].periods = actualScheduleWeekDayPeriods
                                            }
                                        } else {
                                            if (selectedDays.includes(data.schedule[i].days[j].date.getDay())) {
                                                if(!isWorkerPeriod){
                                                    data.schedule[i].days[j].periods = {
                                                        periode_one_start: '00:00',
                                                        periode_one_end: '00:00',
                                                    }
                                                }
                                                else{
                                                    data.schedule[i].days[j].periods = actualScheduleWeekDayPeriods
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    data.isConfirmedSchedule = false
                    setData(data)
                    setUpdSchedule(true);
                    toggle()
                } else {
                    toast.error('Veuillez indiquer des périodes')
                }
            } else {
                toast.error('Veuillez indiquer des périodes valides')
            }
        } else {
            toast.error('Veuillez indiquer des périodes')
        }
    }

    useEffect(() => {
        if(data?.dateEnd){
            setMaxDateRepeat(new Date(data.dateEnd))
        }
    }, [data])

    useEffect(() => {
        if(maxDateRepeat){
            if(maxDateRepeat <= actualScheduleWeekDay){
                setErrorMaxDateRepeat('La date de fin ne peut pas être inférieure ou égale à la date de début de la période')
            }
            else if(maxDateRepeat > data.dateEnd){
                setErrorMaxDateRepeat('La date de fin ne peut pas être supérieure à la date de fin de la mission')
            }
            else{
                setErrorMaxDateRepeat('')
            }
        }
    }, [maxDateRepeat])

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {actualScheduleWeekDayPeriods?.periode_one_start && actualScheduleWeekDayPeriods?.periode_one_end ? 'Modifier une journée' : 'Ajouter une journée'}
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <div className={''}>
                    <div className={'flex flex-col gap-4'}>
                        <div>
                            <label htmlFor="periode_one_start">
                                <div>Jour sélectionné</div>
                                <div className='relative'>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={actualScheduleWeekDay}
                                        locale='fr-FR'
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        popperPlacement="bottom"
                                        className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg pl-8'
                                        disabled={true}
                                    />
                                    <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                        <Calendar wh={18} color={'#666666'} strokeW={2}/>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div>
                            <div className={'flex justify-between'}>
                                <div className={'my-auto'}>
                                    <div className={'ft-lg text-gray-900 font-medium'}>Jour travaillé ?</div>
                                    <div className={'flex gap-5 justify-between'}>
                                        <div className={'ft-sm text-gray-500'}>
                                            Indiquez si l'intérimaire travaillera ou non sur la journée sélectionnée.
                                        </div>
                                    </div>
                                </div>
                                <div className={'my-auto'}>
                                    <div className={'my-auto'}>
                                        <label className="relative inline-flex items-center cursor-pointer my-auto">
                                            <input type="checkbox"
                                                   className="sr-only peer"
                                                   onChange={() => setIsWorkerPeriod(!isWorkerPeriod)}
                                                   checked={isWorkerPeriod}
                                            />
                                            <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-300 dark:peer-focus:ring-yellow-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-600"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            isWorkerPeriod ? (
                                <>
                                    <div>
                                        <Divider/>
                                    </div>
                                    <div>
                                        <div className={'flex flex-col gap-2'}>
                                            <div>
                                                <div className={'ft-lg text-gray-900 font-medium'}>Périodes travaillées</div>
                                                <div className={'flex gap-5 justify-between'}>
                                                    <div className={'ft-sm text-gray-500'}>
                                                        Indiquez les périodes travaillées et les pauses effectuées par le profil
                                                        dans une journée.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={error1 !== '' ? 'border-2 border-red-600 rounded-md' : ''}>
                                                {error1 !== '' ? <p className={'col-span-2 ft-b text-red-700 mb-2'}>{error1}</p> : null}
                                                <div className={`flex justify-between gap-2 items-center`}>
                                                    <div className={'my-auto w-full'}>
                                                        <label htmlFor="periode_one_start">Heure de début</label>
                                                        <input type="time"
                                                               onChange={handleChangeTime}
                                                               name={'periode_one_start'}
                                                               className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                                               value={actualScheduleWeekDayPeriods?.periode_one_start}
                                                        />
                                                    </div>
                                                    <div className={'mt-5'}>
                                                        <ChevronRight wh={24} color={'#6B7280'}/>
                                                    </div>
                                                    <div className={'my-auto w-full'}>
                                                        <label htmlFor="periode_one_end">Heure de Fin</label>
                                                        <input type="time"
                                                               name={'periode_one_end'}
                                                               onChange={handleChangeTime}
                                                               className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                                               value={actualScheduleWeekDayPeriods?.periode_one_end}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {iTime > 1 ?
                                                <div className={error2 !== '' ? 'border-2 border-red-600 rounded-md' : ''}>
                                                    <div onClick={handleSITime} className={'flex justify-end'}>
                                                        <div className={'cursor-pointer'}>
                                                            <Close wh={24} color={'#6B7280'}/>
                                                        </div>
                                                    </div>
                                                    {error2 !== '' ? <p className={'col-span-2 ft-b text-red-700 mb-2'}>{error2}</p> : null}
                                                    <div className={`flex justify-between gap-2 items-center`}>
                                                        <div className={'my-auto w-full'}>
                                                            <label htmlFor="periode_two_start">Heure de début</label>
                                                            <input type="time"
                                                                   onChange={handleChangeTime}
                                                                   name={'periode_two_start'}
                                                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                                                   value={actualScheduleWeekDayPeriods?.periode_two_start}
                                                            />
                                                        </div>
                                                        <div className={'mt-5'}>
                                                            <ChevronRight wh={24} color={'#6B7280'}/>
                                                        </div>
                                                        <div className={'my-auto w-full'}>
                                                            <label htmlFor="periode_two_end">Heure de Fin</label>
                                                            <input type="time"
                                                                   onChange={handleChangeTime}
                                                                   name={'periode_two_end'}
                                                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                                                   value={actualScheduleWeekDayPeriods?.periode_two_end}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {iTime > 2 ?
                                                <div className={error3 !== '' ? 'border-2 border-red-600 rounded-md' : null}>
                                                    <div onClick={handleSITime} className={'flex justify-end'}>
                                                        <div className={'cursor-pointer'}>
                                                            <Close wh={24} color={'#6B7280'}/>
                                                        </div>
                                                    </div>
                                                    {error3 !== '' ? <p className={' col-span-2 ft-b text-red-700'}>{error3}</p> : null}
                                                    <div className={`flex justify-between gap-2 items-center p-2`}>
                                                        <div className={'my-auto w-full'}>
                                                            <label htmlFor="periode_three_start">Heure de début</label>
                                                            <input type="time"
                                                                   onChange={handleChangeTime}
                                                                   name={'periode_three_start'}
                                                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                                                   value={actualScheduleWeekDayPeriods?.periode_three_start}
                                                            />
                                                        </div>
                                                        <div className={'mt-5'}>
                                                            <ChevronRight wh={24} color={'#6B7280'}/>
                                                        </div>
                                                        <div className={'my-auto w-full'}>
                                                            <label htmlFor="periode_three_end">Heure de Fin</label>
                                                            <input type="time"
                                                                   onChange={handleChangeTime}
                                                                   name={'periode_three_end'}
                                                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg"
                                                                   value={actualScheduleWeekDayPeriods?.periode_three_end}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {
                                                iTime < 3 ?
                                                    <div className={'mt-8'}>
                                                        <Button size={'LG'} color={'DEFAULT'} onClick={handleITime}>
                                                            <div className={'flex gap-3'}>
                                                                <div className={'my-auto'}>
                                                                    <Plus wh={14} color={'#111827'} className='fill-gray-400'/>
                                                                </div>
                                                                <div className={'my-auto'}>
                                                                    Ajouter une période
                                                                </div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                    : null
                                                }
                                        </div>
                                    </div>
                                </>
                            ) : null
                        }
                        {
                            listDay.length > 0 ? (
                                <>
                                    <div>
                                        <Divider/>
                                    </div>
                                    <div className={'flex flex-col gap-4'}>
                                        <div className={'flex justify-between'}>
                                            <div className={'my-auto'}>
                                                <div className={'ft-lg text-gray-900 font-medium'}>
                                                    Répéter la période
                                                </div>
                                                <div className={'flex gap-5 justify-between'}>
                                                    <div className={'ft-sm text-gray-500'}>
                                                        Sélectionnez les jours pour lesquels vous décidez d’ajouter ce shift.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'my-auto'}>
                                                <label className="relative inline-flex items-center cursor-pointer my-auto">
                                                    <input type="checkbox"
                                                           className="sr-only peer"
                                                           onChange={() => setIsRepeatPeriod(!isRepeatPeriod)}
                                                           checked={isRepeatPeriod}
                                                    />
                                                    <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-300 dark:peer-focus:ring-yellow-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-600"></div>
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            isRepeatPeriod ? (
                                                <>
                                                    <div className={'flex gap-3'}>
                                                        {
                                                            listDay.map((day, index) => {
                                                                //id day to french first letter day name
                                                                let dayName = ''
                                                                switch (day) {
                                                                    case 1:
                                                                        dayName = 'L'
                                                                        break
                                                                    case 2:
                                                                        dayName = 'Ma'
                                                                        break
                                                                    case 3:
                                                                        dayName = 'Me'
                                                                        break
                                                                    case 4:
                                                                        dayName = 'J'
                                                                        break
                                                                    case 5:
                                                                        dayName = 'V'
                                                                        break
                                                                    case 6:
                                                                        dayName = 'S'
                                                                        break
                                                                    case 0:
                                                                        dayName = 'D'
                                                                        break
                                                                    default:
                                                                }

                                                                //round button with day name centered
                                                                return (
                                                                    <div key={index} className={'flex justify-center items-center cursor-pointer'}>
                                                                        <div onClick={() => manageDaysSelection(day)}
                                                                             className={`flex justify-center items-center rounded-full w-12 h-12 border select-none ${selectedDays.includes(day) ? 'bg-yellow-50 border-yellow-200' : 'border-gray-100'}`}
                                                                        >
                                                                            {dayName}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        listDay.length > 1 && nbDays > 7 ? (
                                                            <>
                                                                <div className={'flex flex-col gap-2'}>
                                                                    <div className={'flex gap-2'}>
                                                                        <label className={`flex gap-2 cursor-pointer`}>
                                                                            <input type="checkbox"
                                                                                   className={'w-4 h-4 accent-yellow-400 my-auto'}
                                                                                   onChange={() => setIsAllDay(!isAllDay)}
                                                                                   checked={isAllDay}
                                                                            />
                                                                            <div className={'my-auto'}>
                                                                                Répéter jusqu’à la fin de la mission
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        !isAllDay ? (
                                                                            <div>
                                                                                <label>
                                                                                    <div>Se termine dans</div>
                                                                                    <div className='relative'>
                                                                                        <DatePicker
                                                                                            dateFormat="dd/MM/yyyy"
                                                                                            selected={maxDateRepeat}
                                                                                            onChange={date => setMaxDateRepeat(date)}
                                                                                            locale='fr-FR'
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                            popperPlacement="bottom"
                                                                                            className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg pl-8'
                                                                                        />
                                                                                        <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                                                                            <Calendar wh={18} color={'#666666'} strokeW={2}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </label>
                                                                                {errorMaxDateRepeat ? (<p className={'col-span-2 ft-b text-red-700'}>{errorMaxDateRepeat}</p>) : null}
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            </>
                                                        ) : null
                                                    }
                                                </>
                                            ) : null
                                        }
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>
                    Annuler
                </Button>
                <Button size={'LG'} onClick={handleSubmit}>
                    Confirmer
                </Button>
            </ModalFooter>
        </>
    )
}

export default addEditSchedule;