import React, {useEffect, useState} from 'react';
import UseContext from "hooks/useContext";
import ApiDatabase from "server";
import Table from "components/table/table";
import EmptyState from "components/emptyState";

const CheckedContent = () => {
    const {companyData,refreshTime} = UseContext()
    const [arrayTimeSheets, setArrayTimeSheets] = useState([])
    const [isWaiting, setIsWaiting] = useState(true);

    useEffect(() => {
        setIsWaiting(true)
        if(Object.keys(companyData).length){
            ApiDatabase.getTimeSheets({id:companyData._id,p:'3'},
                (data) => {
                    setArrayTimeSheets(data)
                    setIsWaiting(false)
                },
                (err) => {
                    console.log(err)
                })
        }
    }, [companyData, refreshTime])

    const titles=['N° de relevé','MISSION', 'Intérimaire','période']

    return (
        <div>
            {
                isWaiting ? (
                    <>
                        {
                            [1, 2, 3, 4, 5].map((i, index)=>(
                                <Table key={index} champsBody={i} champsHeader={titles} isWaiting={isWaiting}/>
                            ))
                        }
                    </>
                ) : (
                    <>
                        {
                            arrayTimeSheets?.length > 0 ?
                            <Table champsBody={arrayTimeSheets} champsHeader={titles}/>
                            :
                            <EmptyState type={'time_sheet_check'}/>
                        }
                    </>
                )
            }
        </div>
    );
};

export default CheckedContent;