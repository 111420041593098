import React from 'react';
import SidebarDesktop from "components/sidebar/sidebarDesktop";
import Navbar from "components/navBar/Navbar";
import {ToastContainer} from "react-toastify";
import SidebarAdmin from "components/sidebar/sidebarAdmin";

const AdminPageForm = (props) => {
    return (
        <>
            <div className="flex h-screen overflow-y-hidden tab:overflow-hidden">
                {/* Sidebar */}
                <SidebarAdmin/>
                {/* Content area */}
                <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/* Navbar */}
                    <Navbar></Navbar>
                    <main>
                        {/* SubNav */}
                        {/*<div className="w-full">*/}
                        {/*    <SecondNavBar/>*/}
                        {/*</div>*/}
                        {/* Main Content */}
                        <div className="grid grid-cols-12 gap-6 px-8">{props.children}</div>
                    </main>
                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={2}
                pauseOnFocusLoss
                draggable
            />

        </>
    );
};

export default AdminPageForm;