import React, {useEffect, useState} from 'react';
import PageForm from "components/pageForm";
import AdminPageForm from "components/pageForm/admin.pageForm";
import Button from "components/button";
import {Add} from "assets/icons";
import AdminSubnav from "components/subNav/admin.subnav";
import CounterEquipe from "pages/admin/counterEquipe";
import TableAdmin from "components/table/tableAdmin";
import UseContext from "hooks/useContext";
import ApiDatabase from 'server'

const AdminCompany = () => {

    const {companyData, setStaffData, staffData, refreshStaff,refreshCompany, setRefreshCompany} = UseContext()
    const [refresh,setRefresh]=useState(false)

    useEffect(() => {
        if (Object.keys(companyData).length) {
            ApiDatabase.postStaffArray({staff: companyData.employees, id_company: companyData._id},
                (data) => {
                    setStaffData(data)
                },
                (err) => {
                    console.log(err)
                })
        }
    }, [companyData, refreshStaff])

    const titles = ['Membres', 'Rôle']

    return (
        <>
            <AdminPageForm>
                <div
                    className="col-span-12 bg-white sticky  top-16 ">
                    <AdminSubnav/>
                </div>
                <div className={'col-span-full mx-2 flex flex-col gap-6'}>
                    <CounterEquipe/>
                    <TableAdmin champsHeader={titles} champsBody={staffData}/>
                </div>
            </AdminPageForm>
        </>
    );
};

export default AdminCompany;