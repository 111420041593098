import axios from "axios";

export default {

    getUserProfile: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/info`,
            withCredentials: true,
            data: {
                token: payload.token
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getCompanyProfile: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}company/info?id=${payload.token}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postStaffArray: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}company/staff`,
            withCredentials: true,
            data: {
                staff: payload.staff,
                id_company: payload.id_company
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getContracts: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/contract?token=${payload.id}&status=${payload.pStatus}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putStatusContract: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/updateContract`,
            withCredentials: true,
            data: {
                contract: payload.contract
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postRoleUser: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/role`,
            data: {
                token: payload.token
            }
        })
            .then(res => {
                if (res.data.errors)
                    reject(0, res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject(1, err)
            })
    },
    postPasswordRefresh: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/password`,
            withCredentials: true,
            data: {
                oldPwd: payload.password,
                password: payload.newPassword,
                id: payload.id
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postEmailRefresh: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/email`,
            withCredentials: true,
            data: {
                email: payload.email,
                token: payload.token,
                tokenMail: payload.tokenMail,
                validation: payload.validation
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postResendEmailToken: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/tokenMail`,
            data: {
                email: payload.email
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postCreateTokenMail: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/createToken`,
            data: {
                email: payload.email,
                token: payload.token
            }

        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },

    getListeVente: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}listes/vente`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getListeLog: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}listes/logistique`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getDescLogVente: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}listes/desc`,
            data: {
                name: payload.name
            },
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getListLanguages: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}language`,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getListTools: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}tools`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getAllLicence: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}licence`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getListeOthers: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}listes/others`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getAllConventions: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}convention`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getMinimumWage: (resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}admin/getMinimumWage`
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postCreateMission: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/add`,
            data: {
                data: payload.dataToSend,
                idCompany: payload.idCompany,
                isDesktop: payload.isDesktop
            },
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getMissions: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/company`,
            data: {
                idC: payload.idC
            },
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getMissionInfo: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/info?id=${payload.id}`,
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getAddress: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}address/${payload.id}`,
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTimeSheets: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}timeSheets/company?id=${payload.id}&p=${payload.p}`,
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTimeSheetInfo: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}timeSheets/${payload.id}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getSchedule: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}schedule/${payload.id}`,
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getScheduleByMission: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}schedule/mission/${payload.id}`,
            withCredentials: true
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUpdateTimeSheet: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}timeSheets/updateCompany`,
            withCredentials: true,
            data: {
                id: payload.id,
                time: payload.time,
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUpdatePhoneCompany:(payload,resolve,reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}company/updatePhone`,
            withCredentials: true,
            data: {
                idCompany: payload.id,
                phone: payload.phone,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUpdatePhone: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/phone`,
            withCredentials: true,
            data: {
                token: payload.token,
                phone: payload.phone,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
//     Partie Admin
    postAddStaff: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}company/addStaff`,
            withCredentials: true,
            data: {
                staff: payload.adminData
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putTransfertAdmin:(payload,resolve,reject)=>{
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}company/transfertStaff`,
            withCredentials: true,
            data: {
                staff: payload.adminData,
                token:payload.token
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putModifyStaff: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}company/modifyStaff`,
            withCredentials: true,
            data: {
                staff: payload.adminData
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postSupportMessaging:(payload,resolve,reject)=>{
        axios({
            method:'post',
            url: `${process.env.REACT_APP_API_URL}support/messaging`,
            withCredentials: true,
            data:{
                user_sender: payload.user_sender,
                mail_messaging: payload.mail_messaging,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    deleteStaff:(payload,resolve,reject)=>{
        axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}company/deleteStaff`,
            withCredentials: true,
            data: {
                staff: payload.adminData
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postSignature: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}company/addSignature`,
            withCredentials: true,
            data: {
                id_mission: payload.id_mission,
                contract: payload.contract,
                token: payload.token
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postTimeSheetSuggestedCompany: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}timeSheets/suggestedCompany`,
            withCredentials: true,
            data: {
                token: payload.token,
                idTimeSheet: payload.idTimeSheet,
                periode_one_start: payload.periode_one_start,
                periode_one_end: payload.periode_one_end,
                periode_two_start: payload.periode_two_start,
                periode_two_end: payload.periode_two_end,
                periode_three_start: payload.periode_three_start,
                periode_three_end: payload.periode_three_end,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postUpdateDayCompany: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}timeSheets/updateDayCompany`,
            withCredentials: true,
            data: {
                idTimeSheetDay: payload.idTimeSheetDay,
                validated: payload.validated,
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getCompanySwitch: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/getSwitchCompany`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putSwitchCompany: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/switchCompany`,
            withCredentials: true,
            data: {
                token: payload.token,
                idCompany: payload.idCompany,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putCheckTutoSwitchCompany: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/checkTutoSwitchCompany`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getBestCoefficient: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}company/getBestCoefficient?token=${payload.token}&wagePerHour=${payload.wagePerHour}&idCompany=${payload.idCompany}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getCostEstimateMonth: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}company/getCostEstimate`,
            withCredentials: true,
            data: {
                token: payload.token,
                idCompany: payload.idCompany,
                dates: payload.dates,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    putCheckTutoRateProfileCompany: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/checkTutoRateProfileCompany`,
            withCredentials: true,
            data: {
                token: payload.token,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTempWorker: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}company/getTempWorker?token=${payload.token}&idCompany=${payload.idCompany}&idTempWorker=${payload.idTempWorker}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTempWorkerRating: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}company/getTempWorkerRating?token=${payload.token}&idCompany=${payload.idCompany}&idTempWorker=${payload.idTempWorker}&idMission=${payload.idMission}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postTempWorkerRating: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}company/addTempWorkerRating`,
            withCredentials: true,
            data: {
                token: payload.token,
                idCompany: payload.idCompany,
                idTempWorker: payload.idTempWorker,
                idMission: payload.idMission,
                idTimeSheet: payload.idTimeSheet,
                type: payload.type,
                type_negative: payload.type_negative,
                comment: payload.comment,
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTempWorkersRatingByMission: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}company/getTempWorkersRatingByMission?token=${payload.token}&idCompany=${payload.idCompany}&titleMission=${payload.titleMission}&dateStart=${payload.dateStart}&dateEnd=${payload.dateEnd}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getTypesQualifications: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}qualification/getTypesQualifications?token=${payload.token}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getListQualifications: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}qualification/getQualificationsActive?token=${payload.token}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    postCancelDeleteMission: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}mission/missionCancellation`,
            withCredentials: true,
            data: {
                token: payload.token,
                idMission: payload.idMission,
                reason: payload.reason,
                status: payload.status,
                isDesktop: payload.isDesktop
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getInfoMissionCancelDelete: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}mission/infoMissionCancelDelete?token=${payload.token}&idMission=${payload.idMission}&isAdmin=${payload.isAdmin}`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
}