import React from 'react';

const Badge = ({type,children}) => {
    const TYPE_COLOR={
        'PRIMARY':'bg-gray-100 text-gray-800',
        'ALERT':'bg-red-100 text-red-800',
        'WARNING':'bg-yellow-100 text-yellow-800',
        'VALID':'bg-green-100 text-green-800',
        'DEFAULT':'border border-gray-300 text-gray-800',
    }

    const tag_color=TYPE_COLOR[type || 'PRIMARY']
    return (
        <>
            <div className={`h-6 px-2 ft-sm rounded-md flex flex-row items-center justify-center gap-4 ${tag_color}`}>
                {children}
            </div>
        </>
    );
};

export default Badge;