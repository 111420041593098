import React, {useEffect} from 'react';
import EmptyState from "components/emptyState";
import UseContext from "../../../hooks/useContext";

const Start = () => {
    const {data, setData} = UseContext()

    useEffect(() => {
        data.location = ''
        setData({...data})
    }, [data.location]);

    return (
        <>
            <EmptyState type={'mission_create'}/>
        </>
    );
};

export default Start;