import React, { useEffect, useState } from "react";
import UseContext from "hooks/useContext";

const CounterEquipe = () => {
    const {
        staffData,
        staff,
        setStaff,
        standby,
        setStandby,
        rest,
        setRest,
        refreshStaff,
    } = UseContext();

    useEffect(() => {
        let nbStaff = 0;
        let nbStandby = 0;

        for (let arg in staffData) {
            if (staffData[arg].validation === 2) {
                nbStaff += 1;
            } else {
                nbStandby += 1;
            }
        }

        setStaff(nbStaff);
        setStandby(nbStandby);
        const result = standby + staff;
        //setRest(rest - result)
        setRest(10 - result);
    }, [staffData, refreshStaff, staff, standby]);

    return (
        <>
            <div className="grid grid-cols-3 gap-4 w-full">
                <div className="col-span-1 border-2 border-gray-400 flex flex-col items-start rounded-md px-4 py-5">
                    <p className="ft-sm font-semibold text-gray-500">
                        Membres de l’équipe
                    </p>
                    <p className={"ft-4xl text-gray-900 font-bold"}>{staff}</p>
                </div>
                <div className="col-span-1 border-2 border-gray-400 flex flex-col items-start rounded-md px-4 py-5">
                    <p className="ft-sm font-semibold text-gray-500">
                        Invitations en attente
                    </p>
                    <p className={"ft-4xl text-gray-900 font-bold"}>
                        {standby}
                    </p>
                </div>
                <div className="col-span-1 border-2 border-gray-400 flex flex-col items-start rounded-md px-4 py-5">
                    <p className="ft-sm font-semibold text-gray-500">
                        Places disponibles
                    </p>
                    <p className={"ft-4xl text-gray-900 font-bold"}>{rest}</p>
                </div>
            </div>
        </>
    );
};

export default CounterEquipe;
