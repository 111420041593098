import React from "react";
import PageForm from "components/pageForm";
import Indicator from "pages/createMission/indicator";
import FooterButton from "pages/createMission/footer.button";
import Button from "components/button";
import CreateMissionSubNav from "components/subNav/createMission.subNav";
import FormsCreate from "pages/createMission/formsCreate";
import UseContext from "hooks/useContext";
import ApiDatabase from "server";
import { toast } from "react-toastify";
import useModal from "../components/modal/useModal";
import { dateToEpoch } from "../helper/helper";

const CreateOfMission = () => {
    const { toggle } = useModal();
    const {
        page,
        handlePrev,
        handleNext,
        data,
        setData,
        companyData,
        isDateValid,
    } = UseContext();

    const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);

    const handleSubmit = (e) => {
        setIsSubmitLoading(true);
        e.preventDefault();
        const idCompany = companyData._id;

        if(data.schedule.length > 0){
            for (let i = 0; i < data.schedule.length; i++) {
                if(data.schedule[i].days.length > 0){
                    for (let j = 0; j < data.schedule[i].days.length; j++) {
                        if(data.schedule[i].days[j].date){
                            data.schedule[i].days[j].date = dateToEpoch(new Date(data.schedule[i].days[j].date)).toString().split(' ').slice(0, 4).join(' ')
                        }
                    }
                }
            }
        }
        
        const dataToSend = {
            ...data,
            dateStart:
                dateToEpoch(data.dateStart).getFullYear() +
                "-" +
                ("0" +
                    (dateToEpoch(data.dateStart).getMonth() + 1)
                        .toString()
                        .slice(-2)) +
                "-" +
                ("0" +
                    dateToEpoch(data.dateStart).getDate().toString().slice(-2)),
            dateEnd:
                dateToEpoch(data.dateEnd).getFullYear() +
                "-" +
                ("0" +
                    (dateToEpoch(data.dateEnd).getMonth() + 1)
                        .toString()
                        .slice(-2)) +
                "-" +
                ("0" +
                    dateToEpoch(data.dateEnd).getDate().toString().slice(-2)),
        };

        ApiDatabase.postCreateMission(
            { dataToSend, idCompany, isDesktop: true },
            (data) => {
                setData({
                    activity: "",
                    qualification: "",
                    tools: [],
                    licences: [],
                    languages: [],
                    contact_name: "",
                    contact_poste: "",
                    location: "",
                    dateStart: "",
                    dateEnd: "",
                    schedule: [],
                    isScheduleCheck: true, // if the company choose to have a schedule
                    isConfirmedSchedule: false, // if the company valid schedule with empty periods
                    isScheduleRequired: false, // if the company have a 1 day mission
                    users_invite: [],
                    motif: "",
                    other: "",
                    other_interim: "",
                    salary: "",
                });
                handleNext();
                setIsSubmitLoading(false);
            },
            (err) => {
                console.log(err);
                setIsSubmitLoading(false);
            }
        );
    };

    const conditionMotif = (data) => {
        switch (data?.type) {
            case "1":
                if (data.message === "") return false;
                else return true;
            case "2":
                if (data.date === "" || data.name === "" || data.prenom === "")
                    return false;
                else return true;
            case "3":
                if (
                    data.message === "" ||
                    data.name === "" ||
                    data.prenom === "" ||
                    data.poste === ""
                )
                    return false;
                else return true;
            case "4":
                if (data.message === "") return false;
                else return true;
            case "5":
                if (data.message === "") return false;
                else return true;
            default:
                break;
        }
    };

    const handlenxt = () => {
        switch (page) {
            case 0:
                handleNext();
                break;
            case 1:
                if (data?.activity !== "" && data?.qualification !== "")
                    handleNext();
                else toast.error("Veuillez sélectionner les champs");
                break;
            case 2:
                handleNext();
                break;
            case 3:
                if (data.motif === "")
                    toast.error("Veuillez sélectionner un motif");
                else {
                    if (conditionMotif(data?.motif)) handleNext();
                    else
                        toast.error(
                            "Veuillez remplir les champs du motif sélectionné"
                        );
                }
                break;
            case 4:
                if (
                    data.location !== "" &&
                    data.dateStart !== "" &&
                    data.dateEnd !== "" &&
                    isDateValid
                )
                    handleNext();
                else toast.error("Veuillez remplir les champs");
                break;
            case 5:
                if (data.isScheduleCheck) {
                    if (data.schedule.length > 0) {
                        let isValidSchedule = false;
                        let isScheduleNotComplete = false;
                        for (let i = 0; i < data.schedule.length; i++) {
                            for (
                                let j = 0;
                                j < data.schedule[i]?.days?.length;
                                j++
                            ) {
                                if (!!data.schedule[i].days[j].periods) {
                                    if (
                                        data.schedule[i].days[j].periods
                                            .periode_one_start !== "" &&
                                        data.schedule[i].days[j].periods
                                            .periode_one_end !== ""
                                    ) {
                                        isValidSchedule = true;
                                    } else {
                                        isScheduleNotComplete = true;
                                    }
                                } else {
                                    isScheduleNotComplete = true;
                                }
                            }
                        }
                        if (isValidSchedule) {
                            if (!isScheduleNotComplete) {
                                handleNext();
                            } else {
                                //With days not complete
                                if (data.isConfirmedSchedule) {
                                    handleNext();
                                } else {
                                    toggle("cM_confirmSchedule");
                                }
                            }
                        } else {
                            if (data.isScheduleRequired) {
                                toast.error("Veuillez remplir le planning");
                            } else {
                                toast.error(
                                    "Veuillez remplir le planning ou retirer l'ajout d'un planning à la mission"
                                );
                            }
                        }
                    } else {
                        if (data.isScheduleRequired) {
                            toast.error("Veuillez remplir le planning");
                        } else {
                            toast.error(
                                "Veuillez remplir le planning ou retirer l'ajout d'un planning à la mission"
                            );
                        }
                    }
                } else {
                    handleNext();
                }
                break;
            case 6:
                if (data.salary !== "") handleNext();
                else toast.error("Veuillez remplir le champs");
                break;
            case 7 :
                handleNext();
                break;
            case 8:
                if (data.contact_name !== "" && data.contact_poste !== "")
                    handleNext();
                else toast.error("Veuillez remplir les champs");
                break;
            case 9:
                handleNext();
                break;
            case 10:
                handleNext();
                break;
            default:
                break;
        }
    };
    return (
        <>
            <PageForm>
                <div className="col-span-12 bg-white sticky z-10 top-16 ">
                    <CreateMissionSubNav />
                </div>
                <div className={"col-span-full mx-auto mb-2"}>
                    <FormsCreate />
                    {page === 11 ? null : (
                        <div className={"flex justify-between mt-2 gap-4"}>
                            <Indicator formIndex={page} />
                            <div className={"flex gap-6"}>
                                {page > 1 ? (
                                    <Button
                                        size={"LG"}
                                        color={"SECONDARY"}
                                        onClick={handlePrev}
                                    >
                                        Retour
                                    </Button>
                                ) : null}
                                <FooterButton
                                    oC1={handlenxt}
                                    oC2={handleSubmit}
                                    isLoading={isSubmitLoading}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </PageForm>
        </>
    );
};

export default CreateOfMission;
