import React from 'react';
import Thead from "components/table/components/thead";
import Tbodycontract
    from "components/table/components/tbodycontract";

const TableContract = ({champsHeader, champsBody, isWaiting}) => {
    return (
        <div className={'overflow-auto lg:overflow-visible'}>
            <table className={`table-auto w-full text-gray-500 border-collapse ft-sm animate-fadeIn ${isWaiting ? 'animate-pulse' : ''}`}>
                <thead>
                <Thead champsHeader={champsHeader} isWaiting={isWaiting}/>
                </thead>
                <tbody >
                {
                    !isWaiting ? (
                        <>
                            <Tbodycontract champsBody={champsBody}/>
                        </>
                    ) : null
                }
                </tbody>
            </table>
        </div>
    );
};

export default TableContract;