
import {ContextProvider} from "context/Context";
import Modal from 'components/modal/portal'
import Routes from "routes";
import 'styles/main.scss'

function App() {
  return (
      <ContextProvider>
        <Modal/>
        <Routes/>
      </ContextProvider>
  );
}

export default App;
