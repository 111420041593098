import React, {useState} from 'react';
import Modalheader from "components/modal/components/header";
import UseModal from "components/modal/useModal";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import Banner from "components/banner/banner";
import {CheckIcon} from "assets/icons";
import UseContext from "hooks/useContext";
import ApiDatabase from 'server'
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const Transfert = () => {
    const {adminData, setRefreshStaff, refreshStaff,setRefreshUser,refreshUser, companyData} = UseContext()
    const {toggle} = UseModal()
    const navigate =useNavigate()
    const [check, setCheck] = useState(false)
    const [err, setErr] = useState(false)

    const handleCheck = (e) => {
        setCheck(!check)
        if (err) setErr(false)
    }

    const handleSubmit = () => {
        if (check) {
            const token = localStorage.getItem('xsrfToken')
            adminData.id_company = companyData._id;
            ApiDatabase.putTransfertAdmin({token: token, adminData},
                (data) => {
                toggle()
                    toast.success(`Modification de ${adminData.firstname} ${adminData.lastname} prends le role Administrateur`)
                    localStorage.setItem('rUser', '215')
                    setRefreshStaff(!refreshStaff)
                    setRefreshUser(!refreshUser)
                    navigate('/dashboard')
                }, (err) => {
                    console.warn(err)
                })
        } else {
            setErr(true)
        }
    }

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Transférer le rôle Administrateur
            </Modalheader>
            <ModalBody>
                <div className={'flex flex-col gap-5'}>
                    <Banner type={'alert'}>
                        En transférant ce rôle vous ne pourrez plus
                        accéder à la console d’administration.
                    </Banner>
                    <div
                        className={'ft-sm text-gray-500 ml-3'}>Êtes-vous
                        sûr de vouloir transférer la propriété
                        à {adminData.firstname} {adminData.lastname}.
                    </div>
                    <div
                        className={`w-full ft-sm  flex items-start justify-center gap-4 border b-gray-50 p-2 rounded-md cursor-pointer ${err ? 'border-red-700 border-2' : null}`}
                        onClick={handleCheck}>
                        <div
                            className={`flex items-center justify-center mt-1 h-4 w-4 border border-gray-300 rounded-sm bg-white ${check ? 'bg-yellow-600 border-yellow-600' : ''} ${err ? 'border-red-700 border-2' : null} focus:outline-none transition duration-200  cursor-pointe `}>{check ?
                            <CheckIcon w={14} h={10}
                                       color={'#ffff'}/> : ''}</div>
                        <div className={'flex flex-col w-[500px]'}>
                        <span
                            className={`ft-sm font-bold text-gray-700 ${err ? 'text-red-700' : null} `}>Attestation sur l’honneur</span>
                            <p className="ft-sm font-medium text-gray-500">
                                Je confirme que je transfère le rôle
                                d’administrateur à un autre membre de
                                l’entreprise.
                            </p>

                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'}
                        onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default Transfert;