import React from 'react';

const Button = ({color, size, children, disabled, onClick}) => {

    const BUTTON_COLOR = {
        PRIMARY: 'bg-yellow-500 text-white rounded-md sh-sm  hover:bg-gray-50 hover:text-gray-700 hover:shadow-md active:bg-gray-100',
        PRIMARYFIXE:'bg-yellow-500 text-white rounded-md sh-sm ',
        SECONDARY: 'bg-white text-gray-700 border b-white rounded-md border-gray-100 hover:bg-gray-100 hover:text-gray-700 active:bg-gray-100',
        DEFAULT: 'bg-transparent text-gray-700 hover:bg-gray-50 hover:text-gray-700 hover:shadow-md active:bg-gray-100',
        DISABLED: 'bg-gray-50 text-gray-400 border-0 b-gray-300 rounded-md sh-sm',
        BLUE: 'bg-blue-600 text-white rounded-md sh-sm hover:text-white active:bg-blue-100',
    }

    const BUTTON_SIZE = {
        SM: 'h-9 ft-sm py-2 px-4',
        SMI: 'h-9 ft-sm p-2',
        LG: 'h-12 ft-lg py-3 px-6',
        LGI: 'h-9 ft-sm p-3',
        FULL: 'w-full h-12 ft-lg py-3 px-6'
    }
    const colorClassName = BUTTON_COLOR[color || 'PRIMARY']
    const sizeClassName = BUTTON_SIZE[size || 'SM']

    return (
        <button type={'button'}
                className={'flex flex-row justify-center items-center gap-2 ' + sizeClassName + ' ' + colorClassName}
                disabled={disabled} onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;