import React, {useEffect, useState} from "react";
import "react-phone-number-input/style.css";
import PhoneInput, {formatPhoneNumberIntl, isValidPhoneNumber} from "react-phone-number-input";
import {Information} from "assets/icons";


const SelectPhone = ({phoneValue, setPhoneValue,disabledP}) => {
  const [value, setValue] = useState('');
  const [validPhone, setValidPhone] = useState(false);

  useEffect(() => {
    if(validPhone){
      setPhoneValue( formatPhoneNumberIntl(`${value}`).replace(/\s+/g, ''))
    }
  }, [validPhone])

  useEffect(() => {
    setValidPhone(isValidPhoneNumber(`${value}`));
  }, [value]);

  useEffect(() => {
    if (phoneValue?.length > 0) {
      setValue(formatPhoneNumberIntl(phoneValue));
    }
  }, [phoneValue])

  return (<>
    <PhoneInput
        className="w-full h-10 pl-3 pr-8 text-base border rounded-lg"
        placeholder="Indiquer votre numéro"
        defaultCountry="FR"
        value={value}
        onChange={setValue}
    />
    {!validPhone ? (
        <p className={value?.toString().length > 0 && !validPhone ? "text-yellow-500 flex flex-row gap-4 items-center" : "hidden"}>
            <span>
              <Information wh={14} color={"#FF9600"}/>
            </span>
          Numéro invalide
        </p>) : ''}
  </>);
};

export default SelectPhone;
