import React, {useEffect, useState} from 'react';
import UseContext from "hooks/useContext";
import UseModal from "components/modal/useModal";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDatabase from "server"
import TBodyHoursCompany
    from "pages/timeSheet/components/tBodyHours";
import {CheckIcon, Close} from "assets/icons";
import ValidateTime
    from "components/modal/dataModal/data/modalTimeSheet/validateTime";
import {toast} from "react-toastify";
import {dateToEpoch} from "../../../../../helper/helper";

const ToBeChecked = () => {
    const {
        objID,
        refreshTime,
        timeCheck,
        setTimeCheck,
    } = UseContext()
    const [datas, setDatas] = useState({})
    const [isDisplaySuggested, setIsDisplaySuggested] = useState({})
    const [dataDays, setDataDays] = useState({})
    const [nextF, setNextF] = useState(false)
    const [allTimeSheetValid, setAllTimeSheetValid] = useState(false)
    const [allTimeSheetClose, setAllTimeSheetClose] = useState(false)
    const [totalHour, setTotalHour] = useState(0)
    const [isWaiting, setIsWaiting] = useState(false)
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }
    const {toggle} = UseModal()


    useEffect(() => {
        setIsWaiting(true)
        ApiDatabase.getTimeSheetInfo({id: objID},
            (data) => {
                setDatas(data[0])
                setDataDays(data[0].days)
                setIsWaiting(false)
            },
            (err) => {
                console.log(err)
            }
        )
    }, [refreshTime, objID])

    const allClose = () => {
        let validateTimeSheet = false;
        for (let i in timeCheck) {
            if (timeCheck[i] !== 2) {
                validateTimeSheet = true
            }
        }

        if(validateTimeSheet) {
            for (let i in timeCheck) {
                setTimeCheck(prevData => ({
                    ...prevData,
                    [i]: 2
                }))
            }
        }
        else{
            for (let i in timeCheck) {
                setTimeCheck(prevData => ({
                    ...prevData,
                    [i]: ""
                }))
            }
        }
    }
    const allValid = () => {
        let closeTimeSheet = false;
        for (let i in timeCheck) {
            if (timeCheck[i] !== 3) {
                closeTimeSheet = true
            }
        }

        if(closeTimeSheet) {
            for (let i in timeCheck) {
                setTimeCheck(prevData => ({
                    ...prevData,
                    [i]: 3
                }))
            }
        }
        else{
            for (let i in timeCheck) {
                setTimeCheck(prevData => ({
                    ...prevData,
                    [i]: ""
                }))
            }
        }
    }
    const handleNext = (e) => {
        e.preventDefault()
        let validateTimeSheet = true
        for (let i in timeCheck) {
            if (timeCheck[i] === '') {
                toast.error('Veuillez valider ou refuser toutes les heures.')
                validateTimeSheet = false
                break
            }
        }

        for (let i in isDisplaySuggested) {
            if(isDisplaySuggested[i] === true){
                toast.error('Veuillez compléter toutes les suggestions d\'heures.')
                validateTimeSheet = false
                break
            }
        }
        if(validateTimeSheet){
            setNextF(!nextF)
        }
    }

    useEffect(() => {
        setTimeCheck({})
        datas?.days?.map((i, index) =>
            setTimeCheck(prevData => ({
                ...prevData,
                [i._id]: ''
            }))
        )
    }, [datas])

    useEffect(() => {
        let valid = true
        let close = true
        for (let i in timeCheck) {
            if (timeCheck[i] !== 2) {
                valid = false
            }
            if (timeCheck[i] !== 3) {
                close = false
            }
        }
        setAllTimeSheetValid(valid)
        setAllTimeSheetClose(close)
    }, [timeCheck])

    useEffect(() => {
        if(datas){
            setTotalHour(datas?.total_hours_ent)
        }
    }, [datas])

    return (
        <>

            {nextF ?
                <>
                    <ValidateTime oc1={handleNext}/>
                </> :
                <>
                    <Modalheader hide={toggle} hideShow={true}>
                        {isWaiting ? (
                            <div className={'animate-fadeIn animate-pulse'}>
                                <div className={'h-8 bg-gray-200 rounded w-96'}></div>
                            </div>
                        ) : (
                            <>
                                {
                                    Object.keys(datas).length > 0 && (
                                        <>
                                            <h1 className={'ft-2xl font-medium text-gray-900'}>
                                                {datas?.mission?.title} n°{datas?.id_time_sheet} - {datas?.user?.firstname} {datas?.user?.lastname}
                                            </h1>
                                        </>
                                    )
                                }
                            </>
                        )}
                    </Modalheader>
                    <ModalBody>
                        {isWaiting ? (
                            <div className={'animate-fadeIn animate-pulse'}>
                                <div className={'flex flex-col gap-4'}>
                                    <div className={'flex flex-col gap-1'}>
                                        <div className={'h-5 bg-gray-200 rounded w-full'}></div>
                                        <div className={'h-5 bg-gray-200 rounded w-1/2'}></div>
                                    </div>
                                    <div className={'flex items-center justify-between mb-4'}>
                                        <div className={'flex gap-6 items-center ft-sm'}>
                                            <div className={'h-4 bg-gray-200 rounded w-14'}></div>
                                            <div className={'h-4 bg-gray-200 rounded w-40'}></div>
                                        </div>
                                        <div className={'flex items-center justify-end gap-6'}>
                                            <div className={'h-8 bg-gray-200 rounded w-8'}></div>
                                            <div className={'h-8 bg-gray-200 rounded w-8'}></div>
                                        </div>
                                    </div>
                                    <div className={'flex flex-col w-full gap-4'}>
                                        {[1, 2, 3, 4, 5].map((i, index)=> (
                                            <div key={index} className={'flex items-center grid grid-cols-5'}>
                                                <div className={''}>
                                                    <div className={'h-4 bg-gray-200 rounded w-10'}></div>
                                                </div>
                                                <div className={'col-span-3'}>
                                                    <div className={'flex flex-col gap-1'}>
                                                        <div className={'h-4 bg-gray-200 rounded w-32'}></div>
                                                        <div className={'h-4 bg-gray-200 rounded w-32'}></div>
                                                    </div>
                                                </div>
                                                <div className={'flex justify-end gap-6'}>
                                                    <div className={'h-8 bg-gray-200 rounded w-8'}></div>
                                                    <div className={'h-8 bg-gray-200 rounded w-8'}></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {
                                    Object.keys(datas).length > 0 && (
                                        <>
                                            <p className={'mb-4'}>
                                                Vérifiez les heures travaillées
                                                par {datas?.user.firstname} {datas?.user.lastname} pour la
                                                mission {datas?.mission.title} n°{datas?.id_time_sheet}, la semaine
                                                du {dateToEpoch(datas?.periode_start).getDate()} au {dateToEpoch(datas?.periode_end).toLocaleDateString('fr-FR', options)}.
                                            </p>
                                            <div className={'flex items-center justify-between mb-4'}>
                                                <div className={'flex gap-6 items-center ft-sm text-gray-700 font-medium'}>
                                                    JOUR
                                                    <span
                                                        className={'text-gray-500'}>{datas?.total_hours_ent ? `heures travaillées (${totalHour})` : null}  </span>
                                                </div>
                                                <div className={'flex items-center justify-end gap-6'}>
                                                    <div onClick={allClose}
                                                         className={`flex items-center justify-center h-8 w-8 rounded-md ${allTimeSheetValid ? 'bg-red-50' : ''} cursor-pointer`}>
                                                        <Close wh={24} color={allTimeSheetValid ? '#B91C1C' : '#374151'}/>
                                                    </div>
                                                    <div onClick={allValid}
                                                         className={`flex items-center justify-center h-8 w-8 rounded-md ${allTimeSheetClose ? 'bg-green-100' : ''} cursor-pointer`}>
                                                        <CheckIcon w={15} color={allTimeSheetClose ? '#047857' : '#374151'}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <TBodyHoursCompany champsBody={datas} setTotalHour={setTotalHour} isDisplaySuggested={isDisplaySuggested} setIsDisplaySuggested={setIsDisplaySuggested}/>
                                        </>
                                    )
                                }
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button size={'LG'} color={'SECONDARY'}
                                onClick={toggle}>Annuler</Button>
                        <Button size={'LG'}
                                onClick={handleNext}>Confirmer</Button>
                    </ModalFooter>
                </>
            }
        </>
    );
};

export default ToBeChecked;