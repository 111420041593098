import React from 'react';
import PageForm from "components/pageForm";
import EmptyStateCompany from "components/emptyState";

const Activity = () => {
    return (
        <>
            <PageForm>
                <div className={'col-span-full flex flex-col mt-12 gap-8'}>
                    <EmptyStateCompany type={'coming'}/>
                </div>
            </PageForm>
        </>
    );
};

export default Activity;