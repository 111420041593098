import React, {useState} from 'react';
import NotificationUnit from "components/Notifications/NotificationUnit";
import {CloseModal} from "assets/icons";
import {useNavigate} from "react-router-dom";


const NotificationNavDesktop = () => {
    const navigate = useNavigate()
    const handleNotify = () => {
        navigate('/activityCompany')
    };
    return (
        <>
            <div
                className="flex flex-row gap-4 items-center p-3 cursor-pointer"
                onClick={handleNotify}
            >
                <div className="relative">
                    <svg
                        width="18"
                        height="21"
                        viewBox="0 0 18 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 15.667H17L15.5951 14.2621C15.2141 13.881 15 13.3643 15 12.8254V9.667C15 7.05456 13.3304 4.83208 11 4.00841V3.66699C11 2.56242 10.1046 1.66699 9 1.66699C7.89543 1.66699 7 2.56242 7 3.66699V4.00841C4.66962 4.83208 3 7.05456 3 9.667V12.8254C3 13.3643 2.78595 13.881 2.40493 14.2621L1 15.667H6M12 15.667V16.667C12 18.3238 10.6569 19.667 9 19.667C7.34315 19.667 6 18.3238 6 16.667V15.667M12 15.667H6"
                            stroke="#6B7280"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <div
                        className="absolute top-0 -right-1 w-2.5 h-2.5 bg-[#FF9600] border-2 border-white rounded-full"></div>
                </div>
                <span className="ft-sm font-medium text-gray-900">Activités</span>
            </div>

        </>
    );
};

export default NotificationNavDesktop;