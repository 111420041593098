import React from 'react';
import { dateWordShort} from "helper/helper";
import UseContext from "hooks/useContext";

const ContentMotif = () => {
        const {data } = UseContext()
    const content = {
        '1':  <p className={'ft-sm break-words'}><span
                className={'text-gray-900 font-medium'}>Accroissement temporaire d’activité :</span>
            {data.motif?.message}
        </p>,
        '2':<>
            <span
                className={'text-gray-900 font-medium'}>En attente de recrutement :</span>
                <p className={'ft-sm'}><span
                className={'text-gray-700 font-medium'}>Nom : </span>{data.motif?.name}
                </p>
            <p className={'ft-sm'}><span
                className={'text-gray-700 font-medium'}>Prénom : </span>{data.motif?.prenom}
            </p>
                <p className={'ft-sm'}><span
                className={'text-gray-700 font-medium'}>Date : </span>
                    {dateWordShort(data.motif?.date)}
                </p>
        </>,
        '3':<>
              <span
                  className={'text-gray-900 font-medium'}>Remplacement d’un salarié absent :</span>
            <p className={'ft-sm'}><span
                className={'text-gray-700 font-medium'}>Nom : </span>{data.motif?.name}
            </p>
            <p className={'ft-sm'}><span
                className={'text-gray-700 font-medium'}>Prénom : </span>{data.motif?.prenom}
            </p>
            <p className={'ft-sm'}><span
                className={'text-gray-700 font-medium'}>Poste : </span>
                {data.motif?.poste}
            </p>
            <p className={'ft-sm break-words'}><span
                className={'text-gray-700 font-medium '}>Message : </span>
                {data.motif?.message}
            </p>
        </>,
        '4':<>
        <span
            className={'text-gray-900 font-medium'}>Emploi à caractère saisonnier :</span>
            {data.motif?.message}
        </>,
        '5':<>
        <span
            className={'text-gray-900 font-medium'}>Emploi à caractère saisonnier :</span>
            {data.motif?.message}
        </>

}

return (
    <div className={'flex flex-col ft-sm gap-2'}>
        {content[data.motif?.type]}
    </div>
);
}
;

export default ContentMotif;