import React, { useEffect, useState } from "react";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import { Calendar } from "assets/icons";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import DatePicker from "react-datepicker";
import UseContext from "hooks/useContext";
import { Information } from "assets/icons";

const AttenteRecrutement = () => {
    const { setData } = UseContext();
    const [message, setMessage] = useState({
        type: "2",
        name: "",
        prenom: "",
        date: "",
    });
    const [errorName, setErrorName] = useState(false);
    const [errorPrenom, setErrorPrenom] = useState(false);
    const [errorDate, setErrorDate] = useState(false);

    const { toggle } = useModal();

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        const value = type === "checkbox" ? e.target.checked : e.target.value;

        setMessage((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = () => {
        if (
            message.name === "" ||
            message.prenom === "" ||
            message.date === ""
        ) {
            if (message.name === "") {
                setErrorName(true);
            } else {
                setErrorName(false);
            }
            if (message.prenom === "") {
                setErrorPrenom(true);
            } else {
                setErrorPrenom(false);
            }
            if (message.date === "") {
                setErrorDate(true);
            } else {
                setErrorDate(false);
            }
        } else {
            setErrorName(false);
            setErrorPrenom(false);
            setErrorDate(false);
            setData((prevData) => ({ ...prevData, motif: message }));
            toggle();
        }
    };
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                En attente de recrutement
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <div className={"grid grid-cols-2 gap-6"}>
                    <div>
                        <label
                            htmlFor="name"
                            className="block ft-sm font-medium text-gray-500"
                        >
                            Nom
                        </label>
                        <div className="relative text-gray-500">
                            <input
                                name="name"
                                className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                onChange={handleChange}
                                required
                            />
                            {errorName ? (
                                <p className="text-yellow-400 flex  items-center gap-4">
                                    <Information wh={16} color={"#FFA43D"} />
                                    Un nom est requis
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="prenom"
                            className="block ft-sm font-medium text-gray-500"
                        >
                            Prénom
                        </label>
                        <div className="relative text-gray-500">
                            <input
                                name="prenom"
                                className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                onChange={handleChange}
                                required
                            />
                            {errorPrenom ? (
                                <p className="text-yellow-400 flex  items-center gap-4">
                                    <Information wh={16} color={"#FFA43D"} />
                                    Un prénom est requis
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className={"col-span-2"}>
                        <label
                            htmlFor="poste"
                            className="block ft-sm font-medium text-gray-500"
                        >
                            Date
                        </label>
                        <div className="relative text-gray-500">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={message.date}
                                onChange={(date) =>
                                    setMessage({
                                        ...message,
                                        date: date,
                                    })
                                }
                                peekNextMonth
                                locale="fr-FR"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                popperPlacement="bottom"
                                className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center cursor-pointer"
                            />
                            <div className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Calendar
                                    wh={18}
                                    color={"#666666"}
                                    strokeW={2}
                                />
                            </div>
                        </div>
                        {errorDate ? (
                            <p className="text-yellow-400 flex  items-center gap-4">
                                <Information wh={16} color={"#FFA43D"} />
                                Une date est requise
                            </p>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={"LG"} color={"SECONDARY"} onClick={toggle}>
                    Annuler
                </Button>
                <Button size={"LG"} onClick={handleSubmit}>
                    Confirmer
                </Button>
            </ModalFooter>
        </>
    );
};

export default AttenteRecrutement;
