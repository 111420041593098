import React from "react";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";

const Motif = () => {
    const { data } = UseContext();
    const { toggle } = useModal();
    const elements = [
        {
            type: "1",
            title: "Accroissement temporaire d’activité",
            modal: "cM_accroissement",
        },
        {
            type: "2",
            title: "En attente de recrutement",
            modal: "cM_attRecrutement",
        },
        {
            type: "3",
            title: "Remplacement d'un salarié absent",
            modal: "cM_remplacement",
        },

        {
            type: "5",
            title: "Emploi à caractère saisonnier",
            modal: "cM_saisonnier",
        },
        {
            type: "4",
            title: "Contrat temporaire d'usage",
            modal: "cM_temporaire",
        },
    ];

    return (
        <div>
            <div className={"ft-xl text-gray-900 font-medium mb-8"}>
                Motifs de recours
            </div>
            <div className={"flex flex-col gap-6 mx-8"}>
                {elements.map((item, index) => (
                    <div
                        key={index}
                        className={`ft-b text-gray-700 p-4 rounded-md cursor-pointer ${
                            data.motif?.type === item.type
                                ? "border border-yellow-500 bg-yellow-50"
                                : "border border-gray-200"
                        }`}
                        onClick={(e) => {
                            toggle(item.modal);
                        }}
                    >
                        {item.title}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Motif;
