import React from 'react';
import SidebarUnitDesktop
    from "components/sidebar/components/SidebarUnitDesktop";
import { MissionIcon} from "assets/icons";

const MissionLink = ({pathname}) => {
    return (
        <SidebarUnitDesktop routeName={"Mission"} routeSidebar={"/missions/end"}>
            <span className='shrink-0'>
                            <MissionIcon wh={24} color={`${pathname.includes("missions") ? '#FF9600' : '#6B7280'}`}/>
            </span>
            <span
                className={`ft-sm font-medium ml-3 ls:opacity-0 ls:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                    pathname.includes("missions") && "!text-[#FF9600]"
                }`}
            >
          {/*{language.getValue('sidebar.dashboard','Tableau de bord')}*/}
                Missions
        </span>
        </SidebarUnitDesktop>
    );
};

export default MissionLink;