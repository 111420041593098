import React, {useEffect, useState} from 'react';
import MultiDdl from "components/componentsForm/multiDdl";
import ApiDataBase from 'server'
import UseContext from "hooks/useContext";

const Prerequis = () => {
    const {page,data,setData}=UseContext()
    const [lLang,setLLang]=useState([])
    const [lTools,setLTools]=useState([])
    const [lLicences,setLLicences]=useState([])
    const [selectLng,setSelectLng]=useState([])
    const [selectTools,setSelectTools]=useState([])
    const [selectLicences,setSelectLicences]=useState([])
    const [oDefaultLng,setODefaultLng]=useState([])
    const [oDefaultTools,setODefaultTools]=useState([])
    const [oDefaultLicences,setOdefaultLicences]=useState([])


    useEffect(()=>{
        setData(prevData=>({...prevData,
            tools:selectTools,
            languages:selectLng,
            licences:selectLicences}))
    },[selectLng,selectLicences,selectTools])

    useEffect(()=>{
        if(data.tools.length >0 ){
            setODefaultTools(data.tools)
        }
    },[data])

    useEffect(()=>{
        if (page === 2){
            ApiDataBase.getAllLicence(
                (data) => {
                    setLLicences(data)
                },
                (err) => {
                    console.warn(err)
                }
            )
            ApiDataBase.getListTools(
                (data) => {
                    setLTools(data)
                },
                (err) => {
                    console.warn(err)
                }
            )
            ApiDataBase.getListLanguages(
                (data) => {
                    setLLang(data)
                },
                (err) => {
                    console.warn(err)
                }
            )
        }
    },[page])

    return (
        <div className={'flex flex-col gap-6 px-8 mt-8'}>
            <div className={' flex flex-col gap-6'}>
                <span className='ft-xl font-medium text-gray-900'>Pré-requis</span>
                <div className={'flex flex-col gap-2 '}>
                    <p className={'ft-sm text-gray-500'}>Matériel nécessaire <span className={'ft-sm text-gray-400'}>- (Optionnel)</span></p>
                   <MultiDdl data={lTools} selectArray={setSelectTools} defaultSelect={oDefaultTools}/>
                </div>
                <div className={'flex flex-col gap-2 '}>
                    <p className={'ft-sm text-gray-500'}>Permis nécessaire <span className={'ft-sm text-gray-400'}>- (Optionnel)</span></p>
                    <MultiDdl data={lLicences} selectArray={setSelectLicences}/>
                </div>
                <div className={'flex flex-col gap-2 '}>
                    <p className={'ft-sm text-gray-500'}>Langue nécessaire <span className={'ft-sm text-gray-400'}>- (Optionnel)</span></p>
                    <MultiDdl data={lLang} selectArray={setSelectLng}/>
                </div>
            </div>
        </div>
    );
};

export default Prerequis;