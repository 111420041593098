import React from 'react';
import Banner from "components/banner/banner";
import UseContext from "hooks/useContext";
import Divider from "../../../components/divider/divider";

const Other = () => {
    const {data,setData}=UseContext()
    return (
        <div className={'flex flex-col gap-4 p-8'}>
            <div className={'ft-xl text-gray-900 font-medium'}>Informations supplémentaires</div>
            <p className={'ft-sm text-gray-500'}>Profitez de cet espace pour préciser des informations supplémentaires que les candidats devraient avoir.</p>
            <div className="flex flex-col">
                <label htmlFor="message">Informations pour le candidat sélectionné</label>
                <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    className="border border-gray-300 rounded-md"
                    required
                    value={data.other}
                    maxLength={500}
                    minLength={3}
                    wrap="true"
                    onChange={(e)=>{setData(prevData=>({...prevData,other:e.target.value}))}}
                ></textarea>
            </div>
            <Divider/>
            <p className={'ft-sm text-gray-500'}>Cet espace est réservé aux informations supplémentaires que l'agence d'intérim devrait connaître.</p>
            <div className="flex flex-col">
                <label htmlFor="message">Informations pour l'agence d'intérim</label>
                <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    className="border border-gray-300 rounded-md"
                    required
                    value={data.other_interim}
                    maxLength={500}
                    minLength={3}
                    wrap="true"
                    onChange={(e)=>{setData(prevData=>({...prevData,other_interim:e.target.value}))}}
                ></textarea>
            </div>
        </div>
    );
};

export default Other;