import React from 'react';
import {useLocation} from "react-router-dom";

const NavBarTitle = () => {
    const route =useLocation()
    const {pathname}=route
    const path = pathname.split('/')
    const page=path[1]
    const PAGE ={
        'dashboard':'Tableau de bord',
        'profil':'Profil',
        'timeSheet':'Relevés d\'heures',
        'files':'Documents',
        'missions':'Missions',
        'personalInformations':'Mon Profil',
        'adminCompany':'Équipe',
        'createOfMission':'Création de missions',
        'activityCompany':'Activités'
    }
    return (
        <>
            <div className="flex">
                <span className='ft-lg text-gray-700'>{PAGE[page]}</span>
            </div>
        </>
    );
};

export default NavBarTitle;