import {InformationIcon} from "../../assets/icons";
import React, {useEffect, useState} from "react";
import Tooltip from "../tooltips/Tooltips";
import ApiDatabase from 'server'
import UseContext from "hooks/useContext";
import {dateToEpoch} from "../../helper/helper";

const costEstimateMonth = () => {
    const {companyData} = UseContext()
    const [costEstimateMonth, setCostEstimateMonth] = useState(0)
    const [isWaiting, setIsWaiting] = useState(false)
    const dateReference = new Date()

    const token = localStorage.getItem('xsrfToken')

    const getCostEstimateMonth = async () => {
        setIsWaiting(true)
        ApiDatabase.getCostEstimateMonth({
            token: token,
            idCompany: companyData._id,
            //actual start month and end month
            dates: {
                start: dateToEpoch(new Date(dateReference.getFullYear(), dateReference.getMonth(), 1)),
                end: dateToEpoch(new Date(dateReference.getFullYear(), dateReference.getMonth() + 1, 0))
            }
        }, (data) => {
            setIsWaiting(false)
            if(data.costEstimate === 0) return setCostEstimateMonth(0)
            //display euros with 2 decimals and replace , by .
            const cost_estimate = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.costEstimate).replace(',', '.')
            setCostEstimateMonth(cost_estimate)
        }, (error) => {
            setIsWaiting(false)
            console.log('error', error)
            setCostEstimateMonth(0)
        })
    }

    useEffect(() => {
        if (Object.keys(companyData).length) {
            getCostEstimateMonth()
        }
    }, [companyData]);

    return (
        <div className={'border rounded flex items-center p-6'}>
            <div className={'flex justify-between w-full'}>
                <div className={'flex flex-1 flex-col my-auto'}>
                    <div className={'ft-sm text-gray-600 flex gap-1 relative'}>
                        <div className={'my-auto'}>Coût prévisionnel</div>
                        <div className={'my-auto ft-xs'}>(BETA)</div>
                    </div>
                    {
                        isWaiting ? (
                            <>
                                <div className={'h-5 bg-gray-200 rounded w-1/2 mt-1'}></div>
                                <div className={'h-3 bg-gray-200 rounded w-1/3 mt-2'}></div>
                            </>
                        ) : (
                            <>
                                {
                                    costEstimateMonth ? (
                                        <>
                                            <div className={'ft-4xl'}>
                                                {costEstimateMonth}
                                            </div>
                                            <div className={'flex gap-1 ft-sm'}>
                                                <div className={'text-gray-700'}>
                                                    Pour le mois de
                                                </div>
                                                <div className={'font-bold'}>
                                                    {dateReference.toLocaleString('fr-FR', {month: 'long'}).charAt(0).toUpperCase() + dateReference.toLocaleString('fr-FR', {month: 'long'}).slice(1)}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className={''}>
                                            Pas de données disponible
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                <div className={'flex my-auto'}>
                    <Tooltip text={'Le coût prévisionnel mensuel vous informe sur les dépenses anticipées. Suivez-les en vérifiant vos relevés d\'heures.'}
                             placement={'top'}
                    >
                        <div>
                            <InformationIcon wh={24} color={'#6B7280'}/>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

export default costEstimateMonth