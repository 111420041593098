import React from 'react';
import {Tab, Tabs} from "components/tabs/tabs";

const PersonalInformationSubNav = () => {
    return (
        <>
            <Tabs>
                <Tab label={"myPersonalAccount"} tabName={'Mon compte'}/>
                <Tab label={'params'} tabName={'Paramètres'}/>
            </Tabs>
        </>
    );
};

export default PersonalInformationSubNav;