import React, {useEffect, useState} from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import {MESSAGE_REGEX} from "helper/helper";
import {Information} from "assets/icons";
import useModal from "components/modal/useModal";
import ApiDatabase from 'server'
import useFetchData from "hooks/useFetchData";
import useContext from "hooks/useContext";


const MessageForm = ({ message, setMessage, setValidEmail}) => {
    const {userData}=useFetchData();
    const {companyData}=useContext();

    const [validMotif, setValidMotif] = useState(false);
    const [lengthMessage, setLengthMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [validMessage, setValidMessage] = useState(false);
    const {toggle}=useModal()
    const verif = message.message.length;


    useEffect(() => {
        setValidMessage(MESSAGE_REGEX.test(message.message));

        if (verif <= 8) {
            setErrorMessage(true);
            setLengthMessage(false);
        } else {
            setErrorMessage(false);
            setLengthMessage(true);
        }
        if (message.motif !== "0") {
            setValidMotif(true);
        } else {
            setValidMotif(false);
        }
    }, [message, verif]);


    const handleMotif = (e) => {
        setMessage({...message, motif: e.target.value});
    };
    const handleMessage = (e) => {
        setMessage({...message, message: e.target.value});
    };
    const handleHide = () => {
        setValidEmail(false);
        setMessage({...message, motif: ""});
        setMessage({...message, message: ""});
    };

    const handleSubmit = () => {
        if (lengthMessage && validMotif) {
            const user_sender = {
                id: userData._id,
                name: userData.firstname + " " + userData.lastname + " (" + userData.email + ") " + " de " + companyData.name_commercial,
                email: userData.email,
            }

            const mail_messaging = {
                section_name: 'company',
                mail_object: message.motif,
                mail_content: message.message,
            }

            ApiDatabase.postSupportMessaging({user_sender, mail_messaging},
                (data) => {
                    setValidEmail(true);
                },
                (err) => {
                    console.log(err)
                })
        } else {
            setValidEmail(false);
        }
    };
    return (
        <>
                        <Modalheader hide={toggle} hideShow={true}>
                            Vous voulez nous contacter ?
                        </Modalheader>
                        {/*body*/}
                        <ModalBody>
                            {/*<BannerP type={"info"}>
                                Avez-vous pensé à Aide ? Vous pouvez vous référer aux
                                fiches disponibles avant de nous contacter. Vous trouverez
                                certaines des solutions à vos soucis...
                            </BannerP>*/}
                            <form className="flex flex-col w-full gap-4 mt-4" autoComplete={"off"}>
                                <div className="flex flex-col">
                                    <label htmlFor="motif">Motif</label>
                                    <input type="text"
                                           name="motif"
                                           onChange={handleMotif}
                                           className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-gray-900'
                                           placeholder="Motif de votre message"
                                           required
                                    />
                                    {validMotif ? "" : "Veuillez sélectionner un motif"}
                                </div>
                                <div className="flex flex-col">
                                    <label htmlFor="message">Votre Message</label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        className="border border-gray-300 rounded-md"
                                        required
                                        maxLength={500}
                                        minLength={3}
                                        wrap="true"
                                        onChange={handleMessage}
                                    ></textarea>
                                </div>
                                <span className="text-gray-400 nbLength">500 caractères </span>
                                {errorMessage ? (
                                    <p className="text-yellow-400 flex  items-center gap-4">
                                        <Information wh={16} color={'#FFA43D'}/>
                                        Veuillez faire un message de plus de 10 caractères{" "}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </form>
                        </ModalBody>
                        {/*footer*/}
                        <ModalFooter>
                            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Annuler</Button>
                            <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>
                        </ModalFooter>

        </>
    );
};
export default MessageForm