import React, {useEffect, useState} from 'react';
import Banner from "components/banner/banner";
import UseContext from "hooks/useContext";
import useFetchData from "../../../hooks/useFetchData";

const Contact = () => {
    const {userData} = useFetchData();
    const {data, setData} = UseContext()

    useEffect(() => {
        if (userData?.firstname && userData?.lastname) {
            if (data.contact_name !== undefined && data.contact_name !== null && data.contact_name === '') {
                setData(prevData => ({
                    ...prevData,
                    contact_name: userData?.firstname + ' ' + userData?.lastname
                }))
            }
            if (data.contact_poste !== undefined && data.contact_poste !== null && data.contact_poste === '') {
                setData(prevData => ({
                    ...prevData,
                    contact_poste: 'Responsable'
                }))
            }
        }
    }, [userData]);

    return (
        <div className={'flex flex-col gap-4 p-8'}>
            <Banner type={'info'}>
                Le responsable est la personne en charge de l'intérimaire sélectionné pour cette mission.
            </Banner>
            <form className='grid grid-cols-2 gap-6'>
                <div className="col-span-2 flex flex-col gap-4 ">
                    <label
                        htmlFor="metier"
                        className="block ft-xl font-medium  text-gray-900"
                    >
                        Nom complet du responsable
                    </label>
                    <div className=" text-gray-500">
                        <input
                            name="metier"
                            pattern="([A-Z])[\w+.]{1,}"
                            value={data.contact_name}
                            onChange={(e) => setData(prevData => ({
                                ...prevData,
                                contact_name: e.target.value
                            }))}
                            className="w-full h-10 pl-3 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                            type="text"
                            required
                        />
                    </div>
                </div>
                <div className="col-span-2 flex flex-col gap-4 ">
                    <label
                        htmlFor="metier"
                        className="block ft-xl font-medium  text-gray-900"
                    >
                        Fonction
                    </label>
                    <div className=" text-gray-500">
                        <input
                            name="metier"
                            pattern="([A-Z])[\w+.]{1,}"
                            value={data.contact_poste}
                            onChange={(e) => setData(prevData => ({
                                ...prevData,
                                contact_poste: e.target.value
                            }))}
                            className="w-full h-10 pl-3 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                            type="text"
                            required
                        />
                    </div>
                </div>

            </form>
        </div>
    );
};

export default Contact;