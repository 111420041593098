import React from 'react';
import PageForm from "components/pageForm";
import {Outlet} from "react-router-dom";
import PersonalInformationSubNav
    from "components/subNav/personalInformation.subNav";

const PersonalInformation = () => {
    return (
        <>
            <PageForm>
                <div className="col-span-12 bg-white sticky  top-16 ">
                    <PersonalInformationSubNav/>
                </div>
                <div className="col-span-12 mt-8 mb-28">
                    <Outlet/>
                </div>
            </PageForm>
        </>
    );
};

export default PersonalInformation;