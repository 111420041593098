import React from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const MultiDdl = ({data, selectArray,defaultSelect}) => {

    function extractArray(selected) {
        let options = []
        selected.map((d) =>
            options.push(d.value)
        )
        return options
    }
    function extract(data) {
        let options = []
        data.map((d) =>
            options.push({value: d.name, label: d.name})
        )
        return options
    }


    const handleT = (selected) => {
        selectArray(extractArray(selected))
    }
    const result = extract(data)
    return (
        <>
            <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                onChange={handleT}
                isMulti
                options={result}/>
        </>
    );
};

export default MultiDdl;