import React, {useEffect, useState} from 'react';
import {
    Calendar,
    CreditCard,
    Localisation,
    ProfilIcon
} from "assets/icons";
import Cardbody from "components/card/components/cardbody";
import Cardfooter from "components/card/components/cardfooter";
import Badge from "components/badge/badge";
import Divider from "components/divider/divider";
import {dateToEpoch, dateWordShort} from "helper/helper";
import {useNavigate} from "react-router-dom";
import ApiDataBase from "server";

const CardMission = ({data, isWaiting}) => {
    const navigate = useNavigate()
    const d0 = dateToEpoch(new Date())
    const d1 = dateToEpoch(data?.duration?.datestart)
    const d2 = dateToEpoch(data?.duration?.dateend)

    //ALERT PRIMARY VALID
    const statutMission = () => {
        if (d0.getTime() > d2.getTime()) return 'ALERT'
        if ((d0.getTime() >= d1.getTime()) && (d0.getTime() <= d2.getTime())) return 'VALID'
        if (d0.getTime() < d1.getTime()) return 'PRIMARY'
    }
    const colorFooter = {
        'ALERT': 'bg-red-300',
        'VALID': 'bg-green-300',
        'PRIMARY': 'bg-gray-300'
    }
    const contentFooter = {
        'ALERT': 'Terminée',
        'VALID': 'En cours',
        'PRIMARY': 'À venir'
    }
    return (
        <>
            {
                isWaiting ? (
                    <>
                        <div className='bg-white shadow-md w-72 cursor-pointer animate-pulse animate-fadeIn'>
                            <Cardbody>
                                <div className={'flex flex-col gap-4'}>
                                    <h4 className='ft-2xl font-bold text-gray-900 truncate my-3'>
                                        <div className={'h-4 bg-gray-200 rounded w-full'}></div>
                                    </h4>
                                    <span className='flex gap-2 items-center'>
                                        <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                    </span>
                                </div>
                                <Divider w={100}/>
                                <div className={'flex flex-col gap-4'}>
                                    <span className='flex gap-2 items-center'>
                                        <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                    </span>
                                    <span className='flex gap-2 items-center'>
                                        <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                    </span>
                                    <span className='flex gap-2 items-center'>
                                        <div className={'h-3 bg-gray-200 rounded w-2/4'}></div>
                                    </span>
                                </div>

                            </Cardbody>
                            <Cardfooter add={` flex items-center justify-end rounded-b-md bg-gray-300`}>
                                <div className={'h-3 bg-gray-200 rounded w-1/4'}></div>
                            </Cardfooter>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='bg-white shadow-md w-72 cursor-pointer animate-fadeIn'
                             onClick={(e) => {
                                 navigate(`/mission/${data._id}`)
                             }}
                        >
                            <Cardbody>
                                <div className={'flex flex-col gap-4'}>
                                    <h4 className='ft-2xl font-bold text-gray-900 truncate'>
                                        {data.title}
                                    </h4>
                                    <span className='flex gap-2 items-center'>
                                        <Localisation color={'#374151'} wh={14}/>
                                        {data.address[0]?.city}
                                    </span>
                                </div>
                                <Divider w={100}/>
                                <div className={'flex flex-col gap-4'}>
                                    <span className='flex gap-2 items-center'>
                                        <ProfilIcon wh={24} color={'#374151'}/>
                                        {data.userInfo[0]?.firstname ?? 'En attente d\'attribution'} {data.userInfo[0]?.lastname ?? ''}
                                    </span>
                                    <span className='flex gap-2 items-center'>
                                        <Calendar color={'#374151'} wh={24}/>
                                        {dateWordShort(data.duration.datestart)} > {dateWordShort(data.duration.dateend)}
                                    </span>
                                    <span className='flex gap-2 items-center'>
                                        <CreditCard color={'#374151'} wh={24}/>
                                        {data.salary.wage_per_hour !== 0 ? data.salary.wage_per_hour + ' €/h' : 'Selon expérience'}
                                    </span>
                                </div>
                            </Cardbody>
                            <Cardfooter add={` flex items-center justify-end rounded-b-md ${colorFooter[statutMission()]}`}>
                                <Badge type={statutMission()}>{contentFooter[statutMission()]}</Badge>
                            </Cardfooter>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default CardMission;