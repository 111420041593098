import React, {useState} from 'react';
import UseModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import useFetchData from "hooks/useFetchData";
import AvatarUser from "components/avatar/avatar.user";
import Chip from "components/chip/chip";
import Button from "components/button";
import {Delete, Dots, Modify} from "assets/icons";

const AdminCard = ({item}) => {
    const {toggle}=UseModal()
    const {setAdminData,companyData}=UseContext()
    const {userData}=useFetchData()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const token = localStorage.getItem('xsrfToken')
    const handleDots = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const contentAdmin={
        200:'Observateur',
        205:'Manageur',
        210:'Responsable',
        215:'Directeur',
        220:'Superviseur',
        225:'Administrateur'
    }
    const handleUser=()=>{
        if(userData.email === item.email ){
            return'WARNING'
        }else{
            return 'PRIMARY'
        }
    }
    const handleModify = (e) => {
        e.preventDefault()
        setAdminData({
            id:item._id,
            email:item.email,
            firstname:item.firstname,
            lastname:item.lastname,
            role:item.role,
            token: token
        })
        toggle('admin_u_modify')

    }
    const handleDeleted = (e) => {
        e.preventDefault()
        setAdminData({
            id:item._id,
            id_company:companyData._id,
            email:item.email,
            firstname:item.firstname,
            lastname:item.lastname,
            role:item.role,
            token: token
        })
        toggle('admin_d_delete')
    }
    return (
        <>
            <tr>
                <td className="ml-3">
                    <div className={'flex items-center gap-4'}>
                        <AvatarUser size={'SM'}/>
                        <div className="flex flex-col items-start justify-center">
                            <p>{item.firstname} {item.lastname} {item.role === 225 ? '(vous)' : null}</p>
                            <p>{item.email}</p>
                        </div>
                    </div>
                </td>
                <td className="ml-3">
                    <div className={'flex gap-4'}>
                        <Chip type={'INDICATOR'} color={handleUser(item.email)}>{contentAdmin[item.role]}</Chip>
                        {item.validation != 2 ? <Chip type={'INDICATOR'} color={'PRIMARY'}>En attente</Chip> : ''}
                    </div>
                </td>
                {item.role === 225 ? null:


                <td className="ml-3 p-3">
                    <div className="relative flex items-center justify-center" onClick={handleDots}>
                        <Button size={'SMI'} color={'DEFAULT'}>
                            <Dots w={14} h={4} color={'#374151'}/>
                        </Button>
                        <div className={`${
                            dropdownOpen ? "" : "hidden"
                        }  absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg w-48 px-4 py-2 -top-20 right-8 z-30 bg-gray-50 flex flex-col gap-2`}>
                            <div className='flex flex-row items-center gap-3 cursor-pointer ft-sm '
                                 onClick={(e)=>{handleModify(e)}}>
                                <Modify wh={14} color={'#111827'}/>
                                Modifier
                            </div>
                            <div
                                className='flex flex-row items-center gap-3 cursor-pointer ft-sm text-red-600'
                                onClick={(e)=>{handleDeleted(e)}}>
                                <Delete wh={14} color={'#DC2626'}/>
                                Supprimer
                            </div>
                        </div>
                    </div>
                </td>
                }
            </tr>
        </>
    );
};

export default AdminCard;