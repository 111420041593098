import React from 'react';
import PageForm from "components/pageForm";
import ProfileCompletion from "components/Dashboard/profileCompletion";
import ProfileDocuments from "components/Dashboard/profileDocuments";
import useFetchData from "../hooks/useFetchData";
import CostEstimateMonth from "../components/Dashboard/costEstimateMonth";

const Dashboard = () => {
    const {userData} = useFetchData();
    const rUser = localStorage.getItem('rUser')

    return (
        <>
            <PageForm>
                <div className={'col-span-12 md:m-8'}>
                    {/* Profile completion */}
                    {
                        (rUser !== '200' && rUser !== '205') && (
                            <ProfileCompletion/>
                        )
                    }
                    {
                        ((rUser === '225' || rUser === '220' || rUser === '215' || rUser === '210') && userData.is_cost_estimate) ? (
                            <>
                                <div className={'grid grid-cols-1 lg:grid-cols-2 gap-2'}>
                                    {/* Documents */}
                                    <ProfileDocuments/>

                                    {/* Cost estimate  month*/}
                                    <CostEstimateMonth/>
                                </div>
                            </>
                        ) : (
                            <>
                                {/* Documents */}
                                <ProfileDocuments/>
                            </>
                        )
                    }
                </div>
            </PageForm>
        </>
    );
};

export default Dashboard;