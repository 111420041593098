import {
    ContractDisabledIcon,
    ContractIcon,
    PayslipDisabledIcon,
    PayslipIcon,
    TimeSheetDisabledIcon,
    TimeSheetIcon,
    Mission2DisabledIcon,
    Mission2Icon,
} from "assets/icons";
import Divider from "components/divider/divider";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";
import useFetchData from "hooks/useFetchData";
import UseContext from "../../hooks/useContext";
import ApiDataBase from "../../server";
import ApiDatabase from "../../server";
import {dateToEpoch} from "../../helper/helper";

const ProfileDocuments = () => {
    const {userData} = useFetchData();
    const {companyData, refreshTime} = UseContext()
    const [dataMission, setDataMission] = useState([])
    const [inProgressMission, setInProgressMission] = useState([])
    const [toBeSignedContracts, setToBeSignedContracts] = useState([])
    const [toBeCheckTimeSheets, setToBeCheckTimeSheets] = useState([])

    const [nbMission, setNbMission] = useState(0);
    const [nbContract, setNbContract] = useState(0);
    const [nbInvoice, setNbInvoice] = useState(0);
    const [nbTimesheet, setNbTimesheet] = useState(0);

    const rUser = localStorage.getItem('rUser')

    useEffect(() => {
        if (Object.keys(companyData).length) {
            ApiDataBase.getMissions({idC: companyData._id},
                (data) => {
                    setDataMission(data)
                },
                (err) => {
                    console.warn(err)
                })
        }

        let nbInvoiceToSign = 0;
        if(companyData?.factures?.length){
            for (let i = 0; i < companyData?.factures.length; i++) {
                if(companyData?.factures[i].status === false){
                    nbInvoiceToSign++;
                }
            }
        }
        setNbInvoice(nbInvoiceToSign)
    }, [companyData])

    useEffect(() => {
        let tampon = []
        for (let m in dataMission) {
            const d0 = new Date()
            const d1 = dateToEpoch(dataMission[m].duration?.datestart)
            const d2 = dateToEpoch(dataMission[m].duration?.dateend)
            if ((d0.getTime() >d1.getTime()) && (d0.getTime() < d2.getTime())) {
                tampon.push(dataMission[m])
            }
        }
        setInProgressMission(tampon)
    }, [dataMission]);

    useEffect(() => {
        if(Object.keys(companyData).length){
            ApiDatabase.getContracts({id: companyData._id, pStatus:0},
                (data) => {
                    setToBeSignedContracts(data)
                },
                (err) => {
                    console.log(err)
                })
        }
    }, [companyData, refreshTime])

    useEffect(() => {
        if(Object.keys(companyData).length) {
            ApiDatabase.getTimeSheets({id: companyData._id, p: '1'},
                (data) => {
                    setToBeCheckTimeSheets(data)
                },
                (err) => {
                    console.log(err)
                })
        }
    }, [companyData, refreshTime])

    useEffect(() => {
        setNbMission(inProgressMission.length ? inProgressMission.length : 0);
        setNbContract(toBeSignedContracts.length ? toBeSignedContracts.length : 0);
        setNbTimesheet(toBeCheckTimeSheets.length ? toBeCheckTimeSheets.length : 0);
    }, [inProgressMission, toBeSignedContracts, toBeCheckTimeSheets])

    const documentsToComplete = [
        {
            text: 'Mission en cours',
            textPlural: 'Missions en cours',
            number: nbMission,
            textColor: '',
            textColorDisabled: 'text-gray-400',
            link: '/missions/inProgress',
            icon: <Mission2Icon wh={64}/>,
            iconDisabled: <Mission2DisabledIcon wh={64}/>,
            allowedRoles: ['200', '210', '215', '220', '225']
        },
        {
            text: 'Contrat à signer',
            textPlural: 'Contrats à signer',
            number: nbContract,
            textColor: '',
            textColorDisabled: 'text-gray-400',
            link: '/files/contract/toBeSigned',
            icon: <ContractIcon wh={64}/>,
            iconDisabled: <ContractDisabledIcon wh={64}/>,
            allowedRoles: ['200', '210', '215', '225']
        },
        {
            text: 'Facture à régler',
            textPlural: 'Factures à régler',
            number: nbInvoice,
            textColor: '',
            textColorDisabled: 'text-gray-400',
            link: '/files/invoices/toBeSet',
            icon: <PayslipIcon wh={64}/>,
            iconDisabled: <PayslipDisabledIcon wh={64}/>,
            allowedRoles: ['200', '210', '215', '225']
        },
        {
            text: 'Relevé d\'heure à vérifier',
            textPlural: 'Relevés d\'heures à vérifier',
            number: nbTimesheet,
            textColor: '',
            textColorDisabled: 'text-gray-400',
            link: '/timeSheet/toBeChecked',
            icon: <TimeSheetIcon wh={64}/>,
            iconDisabled: <TimeSheetDisabledIcon wh={64}/>,
            allowedRoles: ['200', '205', '210', '215', '220', '225']
        },
    ];

    return (
        <>
            <div className={'grid grid-cols-2 gap-1'}>
                {
                    documentsToComplete.map((document, key) => {
                        if (document.allowedRoles.includes(rUser)) {
                            return (
                                <NavLink to={document.link} key={key} className={'flex justify-between px-5 py-4'}>
                                    <div className={`my-auto ${document.number > 0 ? document.textColor : document.textColorDisabled}`}>
                                        <div className={'text-3xl font-bold'}>{document.number}</div>
                                        <div className={'text-xs'}>{document.number > 1 ? document.textPlural : document.text}</div>
                                    </div>
                                    <div className={'my-auto ml-3'}>
                                        {document.number > 0 ? document.icon : document.iconDisabled}
                                    </div>
                                </NavLink>
                            )
                        }
                    })
                }
            </div>
        </>
    )
}

export default ProfileDocuments;