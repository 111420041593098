import React, { useEffect, useState } from "react";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import { Information } from "assets/icons";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import useModal from "components/modal/useModal";
import { MESSAGE_REGEX } from "helper/helper";
import UseContext from "hooks/useContext";

const Accroissement = () => {
    const { setData } = UseContext();
    const [validMotif, setValidMotif] = useState(false);
    const [message, setMessage] = useState({
        type: "1",
        message: "",
    });
    const [lengthMessage, setLengthMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [validMessage, setValidMessage] = useState(false);
    const { toggle } = useModal();
    const verif = message.message.length;
    useEffect(() => {
        setValidMessage(MESSAGE_REGEX.test(message.message));

        if (verif <= 8) {
            setErrorMessage(true);
            setLengthMessage(false);
        } else {
            setErrorMessage(false);
            setLengthMessage(true);
        }
        if (message.motif !== "0") {
            setValidMotif(true);
        } else {
            setValidMotif(false);
        }
    }, [message, verif]);
    const handleMessage = (e) => {
        setMessage({ ...message, message: e.target.value });
    };
    const handleSubmit = () => {
        if (lengthMessage) {
            setData((prevData) => ({ ...prevData, motif: message }));
            toggle();
        }
    };
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Accroissement temporaire d'activité
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <form className="flex flex-col w-full gap-4 mt-4">
                    <div className="flex flex-col">
                        <label htmlFor="message">Votre Description</label>
                        <textarea
                            name="message"
                            id="message"
                            cols="30"
                            rows="5"
                            className="border border-gray-300 rounded-md"
                            required
                            maxLength={250}
                            minLength={3}
                            wrap="true"
                            onChange={handleMessage}
                        ></textarea>
                    </div>
                    <span className="text-gray-400 nbLength">
                        250 caractères{" "}
                    </span>
                    {errorMessage ? (
                        <p className="text-yellow-400 flex  items-center gap-4">
                            <Information wh={16} color={"#FFA43D"} />
                            Veuillez faire un message de plus de 10 caractères{" "}
                        </p>
                    ) : (
                        ""
                    )}
                </form>
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={"LG"} color={"SECONDARY"} onClick={toggle}>
                    Annuler
                </Button>
                <Button size={"LG"} onClick={handleSubmit}>
                    Confirmer
                </Button>
            </ModalFooter>
        </>
    );
};

export default Accroissement;
