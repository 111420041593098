import React from 'react';
import PageForm from "components/pageForm";
import MissionSubNav from "components/subNav/mission.subNav";
import {Outlet} from "react-router-dom";

const Mission = () => {
    return (
        <>
            <PageForm>
                <div className="flex items-center justify-end col-span-12 bg-white sticky top-16 h-16 ">
                <MissionSubNav/>
                </div>
                <div className="col-span-12 mt-8 mb-28">
                    <Outlet/>
                </div>
            </PageForm>
        </>
    );
};

export default Mission;