import React from 'react';
import Divider from "components/divider/divider";

const TBodyHoursCheck = ({champsBody}) => {
    return (
        <>
            {champsBody?.days.map((item, index) =>
                <>
                    <div
                        className={'flex items-center grid grid-cols-5 '}>
                        <div
                            className={`ft-sm w-auto text-gray-900 font-medium ${item.validated === 2 ?'text-yellow-500':null}`}>
                            {item.name}
                        </div>
                        <div
                            className={'col-span-3 flex flex-col gap-2 ft-sm text-gray-500'}>
                            <span className={`ft-sm text-gray-900 font-medium ${item.validated === 2 ?'text-yellow-500':null}`}>{item?.periode_one_company?.start !== '00:00' ? ((item.day_hours_ent !== '0' && item.day_hours_ent !== '0h' && item.day_hours_ent !== '0h0') ? `${item.day_hours_ent} travaillées` : 'Jour non travaillé') : null}</span>
                            <div className={''}>
                                {
                                    (!item?.periode_one_company || item.validated !== 2) ? (
                                        <>
                                            {item?.periode_one && item?.periode_one?.start !== '' ? `${item?.periode_one.start} > ${item?.periode_one.end}` : null}
                                            {item?.periode_two && item?.periode_two?.start !== '' ? `, ${item?.periode_two?.start} > ${item?.periode_two?.end}` : null}
                                            {item?.periode_three && item?.periode_three?.start !== '' ? `, ${item?.periode_three?.start} > ${item?.periode_three?.end}` : null}
                                        </>
                                    ) : (
                                        <>
                                            <del>
                                                {item?.periode_one && item?.periode_one?.start !== '' ? `${item?.periode_one.start} > ${item?.periode_one.end}` : null}
                                                {item?.periode_two && item?.periode_two?.start !== '' ? `, ${item?.periode_two?.start} > ${item?.periode_two?.end}` : null}
                                                {item?.periode_three && item?.periode_three?.start !== '' ? `, ${item?.periode_three?.start} > ${item?.periode_three?.end}` : null}
                                            </del>
                                            <div className={'text-yellow-500'}>
                                                {item?.periode_one_company && item?.periode_one_company?.start !== '00:00' ? (item?.periode_one_company?.start !== '' ? `${item?.periode_one_company.start} > ${item?.periode_one_company.end}` : null) : 'Jour non travaillé'}
                                                {item?.periode_two_company && item?.periode_two_company?.start !== '' ? `, ${item?.periode_two_company?.start} > ${item?.periode_two_company?.end}` : null}
                                                {item?.periode_three_company && item?.periode_three_company?.start !== '' ? `, ${item?.periode_three_company?.start} > ${item?.periode_three_company?.end}` : null}
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <Divider w={100}/>
            </>
            )}
        </>
    );
};

export default TBodyHoursCheck;