import React, {useEffect, useState} from 'react';
import AddressGeoApi from "components/AddressGeoApi/AddressGeoApi";
import DatePicker, {registerLocale} from "react-datepicker";
import {Calendar} from "assets/icons";
import UseContext from "hooks/useContext";
import {enGB, fr} from "date-fns/locale";
import {dateToEpoch} from "../../../helper/helper";
import ApiDatabase from "../../../server";

registerLocale('en-GB', enGB)
registerLocale('fr-FR', fr)

const Location = () => {
    const {setData, data, setIsDateValid, companyData} = UseContext()
    const [address, setAddress] = useState('');
    const [error, setError] = useState(false)
    const [error1, setError1] = useState(false)

    useEffect(() => {
        if (Object.keys(address).length > 0) setData(prevData => ({
            ...prevData,
            location: address
        }))
    }, [address])

    useEffect(() => {
        if (Object.keys(data.location).length > 0) setAddress(data.location)
    }, [data.location])

    useEffect(() => {
        let dday = dateToEpoch(new Date())
        if (data.dateStart && data.dateEnd) {
            const d0 = dateToEpoch(data.dateStart)
            const d1 = dateToEpoch(data.dateEnd)
            if (d0 === d1) {
                setError(false)
            } else {
                setError(false)
                setError1(false)
            }
            if (d1 < d0) {
                setError(true)
            }
            if (d0 === dday) {
                setError1(false)
            }
            if (dday > d0) {
                setError1(true)
            } else if (dday === d0) {
                setError1(false)
            }
        }
    }, [data])

    useEffect(() => {
        if (!error && !error1) {
            setIsDateValid(true)
        } else {
            setIsDateValid(false)
        }
    }, [error, error1])

    const handleDateEnd = (date) => {

        if (dateToEpoch(data.dateEnd) !== dateToEpoch(date)) {
            setData(prevData => ({
                ...prevData,
                schedule: [],
                isConfirmedSchedule: false,
            }))
        }
        if (dateToEpoch(date) && dateToEpoch(data.dateStart)) {
            if (dateToEpoch(date) === dateToEpoch(data.dateStart)) {
                setData(prevData => ({
                    ...prevData,
                    isScheduleCheck: true,
                    isScheduleRequired: true,
                }))
            } else {
                setData(prevData => ({
                    ...prevData,
                    isScheduleCheck: true,
                    isScheduleRequired: false,
                }))
            }
        }

        setData(prevData => ({
            ...prevData,
            dateEnd: date
        }))
    }

    const handleDateStart = (date) => {

        if (dateToEpoch(data.dateStart) !== dateToEpoch(date)) {
            setData(prevData => ({
                ...prevData,
                schedule: [],
                isConfirmedSchedule: false,
            }))
        }
        if (dateToEpoch(data.dateEnd) && dateToEpoch(date)) {
            if (dateToEpoch(data.dateEnd) === dateToEpoch(date)) {
                setData(prevData => ({
                    ...prevData,
                    isScheduleCheck: true,
                    isScheduleRequired: true,
                }))
            } else {
                setData(prevData => ({
                    ...prevData,
                    isScheduleCheck: true,
                    isScheduleRequired: false,
                }))
            }
        }

        setData(prevData => ({
            ...prevData,
            dateStart: date
        }))
    }

    useEffect(() => {
        if (Object.keys(data.location).length === 0) {
            ApiDatabase.getAddress({id: companyData.location}, (res) => {
                if(res){
                    setAddress(res)
                }
            }, (error) => {
                console.log(error)
            })
        }
    }, [data.location]);

    return (
        <div className={'flex flex-col mx-auto gap-8 p-8'}>
            <div className={'flex flex-col gap-4'}>
                <p className={'ft-xl text-gray-900 font-medium'}>Lieu de la mission</p>
                <AddressGeoApi addressGeoValue={setAddress}
                               addressValue={address}
                               isAddressCompany={true}
                               page={'createMission'}
                />
            </div>
            <div className={'flex flex-col gap-4'}>
                <p className={'ft-xl text-gray-900 font-medium'}>Date de la mission</p>
                <form className={'grid grid-cols-2 gap-4'}>
                    <div className="col-span-1">
                        <label
                            htmlFor="dateStart"
                            className="block ft-sm font-medium  text-gray-500"
                        >
                            Date de début
                        </label>
                        <div className='relative'>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={data.dateStart}
                                onChange={(date) => handleDateStart(date)}
                                locale='fr-FR'
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown disabledKeyboardNavigation
                                dropdownMode="select"
                                popperPlacement="bottom"
                                className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg pl-8 cursor-pointer'
                            />
                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Calendar wh={18} color={'#666666'}
                                          strokeW={2}/>
                            </div>
                        </div>
                        {error1 ?
                            <span className='ft-sm text-red-500'>Veuillez indiquer une date valide</span> : ''}
                    </div>
                    <div className="col-span-1">
                        <label
                            htmlFor="dateStart"
                            className="block ft-sm font-medium  text-gray-500"
                        >
                            Date de Fin
                        </label>
                        <div className='relative'>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={data.dateEnd}
                                onChange={(date) => handleDateEnd(date)}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown disabledKeyboardNavigation
                                locale='fr-FR'
                                dropdownMode="select"
                                popperPlacement="bottom"
                                className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg pl-8 cursor-pointer'
                            />
                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Calendar wh={18} color={'#666666'}
                                          strokeW={2}/>
                            </div>
                        </div>
                        {error ?
                            <span className='ft-sm text-red-500'>Veuillez indiquer une date valide</span> : ''}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Location;