import React from "react";
import { useNavigate } from "react-router-dom";
import { EndCreateMission } from "assets/icons";
import Banner from "components/banner/banner";
import Button from "components/button/index.js";
const End = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate("/missions/comingSoon");
    };
    return (
        <>
            <div className={"flex flex-col px-6 mt-4 gap-4"}>
                <p className={"ft-sm text-yellow-500"}>Félicitations !</p>
                <h1 className={"ft-xl text-gray-900 font-medium"}>
                    Missions crée avec succès 🎉
                </h1>
                <p className={"ft-sm text-gray-500"}>
                    Félicitations, votre mission est disponible pour tous les
                    intérimaires.
                </p>
                <Banner type={"info"}>
                    Gérez vos missions qui n’ont pas encore débutées dans la
                    page “À venir” de Missions.
                </Banner>
                <div className={"mx-auto"}>
                    <EndCreateMission w={350} h={180} />
                </div>
            </div>
            <div className="flex justify-end mt-10">
                <Button
                    size={"LG"}
                    color={"SECONDARY"}
                    onClick={handleNavigation}
                >
                    Missions à venir
                </Button>
            </div>
        </>
    );
};

export default End;
