import React from 'react';

const Indicator = ({formIndex}) => {
    const progressText = {
        0:'0%',
        1:'10%',
        2:'20%',
        3:'30%',
        4:'40%',
        5:'50%',
        6:'60%',
        7:'70%',
        8:'80%',
        9:'90%',
        10:'100%'
    }
    const progressBar = {
        0:'w-[0%]',
        1:'w-[10%]',
        2:'w-[20%]',
        3:'w-[30%]',
        4:'w-[40%]',
        5:'w-[50%]',
        6:'w-[60%]',
        7:'w-[70%]',
        8:'w-[80%]',
        9:'w-[90%]',
        10:'w-[100%]'
    }
    return (
        <div className={'col-span-6'}>
            <span>
                Mission crée à {progressText[formIndex]}
            </span>
            <div className={'h-2 w-72 bg-gray-300 rounded-md'}>
                <div className={`${progressBar[formIndex]} bg-yellow-500 h-full rounded-md`}></div>
            </div>
        </div>
    );
};

export default Indicator;