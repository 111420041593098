import React, {useState, useEffect} from 'react';
import UseModal from "components/modal/useModal";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import {Information, Mail} from "assets/icons";
import ApiDatabase from 'server'
import UseContext from "hooks/useContext";
import {toast} from "react-toastify";
import {EMAIL_REGEX, NAME_REGEX} from "../../../../../helper/helper";

const AddRole = () => {
    const {toggle} = UseModal()
    const {
        companyData,
        setRefreshStaff,
        refreshStaff,
        refreshCompany,
        setRefreshCompany
    } = UseContext()
    const [validName, setValidName] = useState(false);
    const [validPrenom, setValidPrenom] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const token = localStorage.getItem('xsrfToken')

    const [data, setData] = useState({
        id_entreprise: companyData._id,
        nEntreprise: companyData.reason_social,
        email: '',
        firstname: '',
        lastname: '',
        role: '200',
        token: token
    })

    useEffect(() => {
        setValidName(NAME_REGEX.test(data.lastname));
        setValidPrenom(NAME_REGEX.test(data.firstname));
        setValidEmail(EMAIL_REGEX.test(data.email));
    }, [data.firstname, data.lastname, data.email]);

    const handleChange = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        setData(prevData => ({...prevData, [name]: value}))
    }
    const cond1 = (f) => f.firstname !== '' && f.lastname !== '' && f.role !== ''
    const cond2 = validEmail && validName && validPrenom
    const handleSubmit = (e) => {
        e.preventDefault()
        if (cond1(data) && cond2) {
            ApiDatabase.postAddStaff({adminData: data},
                (data) => {
                    if (data.errors) {
                        toast.error(data.errors)
                    } else {
                        setRefreshStaff(!refreshStaff)
                        toast.success('Invitation effectuée')
                        toggle()
                    }
                },
                (err) => {
                    console.log(err)
                    if (err?.response?.data?.message) toast.error(err.response.data.message)
                    else toast.error('Une erreur est survenu')
                }
            )
        } else {
            toast.error('Veuillez remplir correctement tous les champs')
        }
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Inviter par e-mail
            </Modalheader>
            <ModalBody>
                <div className="grid grid-cols-2 gap-6">
                    <div className="col-span-2">
                        <label htmlFor="email"
                               className="block ft-sm font-medium  text-gray-500">Email</label>
                        <div className="relative text-gray-500">
                            <input type="email" name='email'
                                   className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                   onChange={handleChange}
                                   required/>
                            <div
                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                <Mail wh={18} color={'#666666'} strokeW={2}/>
                            </div>
                        </div>
                        <p
                            className={
                                data.email && !validEmail
                                    ? "text-yellow-500 flex flex-row gap-4 items-center"
                                    : "hidden"
                            }
                        >
                            <span>
                                <Information wh={14} color={"#FF9600"}/>
                            </span>
                            Veuillez indiquer un email valide.
                        </p>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="firstname"
                               className="block ft-sm font-medium text-gray-500">Prénom</label>
                        <div className="relative text-gray-500">
                            <input type="text" name={'firstname'}
                                   className={'w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg'}
                                   onChange={handleChange} required/>
                        </div>
                        <p
                            className={
                                data.firstname && !validPrenom
                                    ? "text-yellow-500 flex flex-row gap-4 items-center"
                                    : "hidden"
                            }
                        >
                            <span>
                                <Information wh={14} color={"#FF9600"}/>
                            </span>
                            Veuillez indiquer un prénom valide.
                        </p>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="lastname"
                               className="block ft-sm font-medium text-gray-500">Nom</label>
                        <div className="relative text-gray-500">
                            <input type="text" name='lastname'
                                   className={'w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg'}
                                   onChange={handleChange} required/>
                        </div>
                        <p
                            className={
                                data.lastname && !validName
                                    ? "text-yellow-500 flex flex-row gap-4 items-center"
                                    : "hidden"
                            }
                        >
                            <span>
                                <Information wh={14} color={"#FF9600"}/>
                            </span>
                            Veuillez indiquer un nom valide.
                        </p>
                    </div>
                    <div className="col-span-2">
                        {/*'200':'Observateur',*/}
                        {/*'205':'Manageur',*/}
                        {/*'210':'Responsable',*/}
                        {/*'215':'Directeur',*/}
                        {/*'220':'Superviseur'*/}
                        {/*'225':'Administrateur'*/}
                        <label htmlFor="role"
                               className="block ft-sm font-medium text-gray-500">Rôle</label>
                        <select name="role" id=""
                                className={'w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg'}
                                onChange={handleChange}>
                            <option value="200">Observateur</option>
                            <option value="205">Manageur</option>
                            <option value="210">Responsable</option>
                            <option value="215">Directeur</option>
                            <option value="220">Superviseur</option>
                        </select>
                        {
                            data.role && (
                                <div className={'text-gray-500 ft-sm mt-3'}>
                                    {
                                        data.role === '200' && 'L’observateur peut uniquement consulter l’ensemble des factures, relevés d’heures, contrats et missions crées par l’entreprise.'
                                    }
                                    {
                                        data.role === '205' && 'Le manageur peut uniquement vérifier des relevés d’heures.'
                                    }
                                    {
                                        data.role === '210' && 'Le responsable peut signer des contrats, importer des factures, vérifier des relevés d’heures, et créer des missions.'
                                    }
                                    {
                                        data.role === '215' && 'Le directeur peut signer des contrats, importer des factures, vérifier des relevés d’heures, et créer des missions et modifier le profil de l’entreprise.'
                                    }
                                    {
                                        data.role === '220' && 'Le superviseur peut créer des missions et vérifier des relevés d\'heures.'
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'}
                        onClick={handleSubmit}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default AddRole;