import UseContext from "../../../hooks/useContext";
import React, {useEffect, useState} from "react";
import AddressGeoApi from "../../../components/AddressGeoApi/AddressGeoApi";
import Button from "../../../components/button";
import {ArrowLeft, ArrowRight, ChevronLeft, ChevronRight, Plus, TrashIcon} from "../../../assets/icons";
import ScheduleWeekList from "../../../components/ScheduleWeekList/ScheduleWeekList";
import EmptyStateCompany from "../../../components/emptyState";
import {dateToEpoch} from "../../../helper/helper";

const Schedule = () => {
    const {setData, data, updSchedule, setUpdSchedule} = UseContext()
    const [scheduleWeeks, setScheduleWeeks] = useState([])
    const [scheduleWeekDisplay, setScheduleWeekDisplay] = useState(0)
    const [isScheduleChecked, setIsScheduleChecked] = useState(data.isScheduleCheck ? data.isScheduleCheck : false)
    const [error, setError] = useState(false)

    //Display planning
    useEffect(() => {
        if (data.schedule.length === 0) {
            if (data.dateStart && data.dateEnd) {
                const dateStartSchedule = dateToEpoch(new Date(data.dateStart))
                const dateEndSchedule = dateToEpoch(new Date(data.dateEnd))

                //return days between two given in parameter
                const weeksNew = timeSheets(dateStartSchedule, dateEndSchedule)
                const daysArrayNew = []
                for (let w in weeksNew) {
                    const daysNew = [];
                    for (let i = 0; i < weeksNew[w].length; i++) {
                        daysNew.push({
                            date: weeksNew[w][i],
                            week: w
                        })
                    }
                    daysArrayNew.push(daysNew)
                }
                let daysArrayNew2 = []
                let daysArrayCanBe2 = []
                for (let i = 0; i < daysArrayNew.length; i++) {
                    if (!daysArrayCanBe2.includes(i)) {
                        let isDaysArrayCanBe2 = false;
                        if (daysArrayNew[i + 1]) {
                            if (daysArrayNew[i].length + daysArrayNew[i + 1].length <= 7) {
                                isDaysArrayCanBe2 = true;
                                daysArrayCanBe2.push(i, i + 1)
                            }
                        }
                        if (isDaysArrayCanBe2) {
                            // add daysArrayNew[i] and daysArrayNew[i + 1] in daysArrayNew2
                            daysArrayNew2.push({
                                name: `Semaine ${('0' + (i + 1)).slice(-2)} & ${('0' + (i + 2)).slice(-2)}`,
                                days: [...daysArrayNew[i], ...daysArrayNew[i + 1]]
                            })
                        } else {
                            daysArrayNew2.push({name: `Semaine ${('0' + (i + 1)).slice(-2)}`, days: daysArrayNew[i]})
                        }
                    }
                }
                setScheduleWeeks(daysArrayNew2);
                data.schedule = daysArrayNew2;
            }
        }
        else {
            setScheduleWeeks(data.schedule)
        }
        setUpdSchedule(false)
    }, [data, updSchedule])

    useEffect(() => {
        if (scheduleWeeks.length > 0) {
            setData({...data, schedule: scheduleWeeks})
        }
    }, [scheduleWeeks, updSchedule]);

    const timeSheets = (start, end)=>{
        const weeks = [];

        let startDate = dateToEpoch(new Date(start));
        let endDate = dateToEpoch(new Date(end));
        let week = [];
        while (startDate <= endDate) {

            week.push(dateToEpoch(startDate));

            if(startDate >= endDate || startDate.getDay() === 0){
                weeks.push(week);
                week = [];
            }
            startDate.setDate(startDate.getDate() + 1);
        }

        return weeks;
    }

    useEffect(() => {
        setData({...data, isScheduleCheck: isScheduleChecked})
    }, [isScheduleChecked])

    return (
        <div className={'flex flex-col mx-auto gap-6 p-8'}>
            <div className={'flex justify-between'}>
                <div className={'ft-xl text-gray-900 font-medium my-auto'}>
                    Planning de la mission
                </div>
                {
                    !data.isScheduleRequired ? (
                        <div className={'my-auto flex'}>
                            <label className="relative inline-flex items-center cursor-pointer my-auto">
                                <input type="checkbox"
                                       onChange={() => setIsScheduleChecked(!isScheduleChecked)}
                                       className="sr-only peer"
                                       checked={isScheduleChecked}
                                />
                                <div
                                    className="w-14 h-7 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-500"></div>
                            </label>
                        </div>
                    ) : null
                }
            </div>
            {
                isScheduleChecked ? (
                    <div className={''}>
                        {
                            scheduleWeeks?.map((scheduleWeek, index) => {
                                return (
                                    <ScheduleWeekList key={index}
                                                      indexScheduleWeek={index}
                                                      scheduleWeek={scheduleWeek}
                                                      isPrevious={!!scheduleWeeks[index - 1]}
                                                      isNext={!!scheduleWeeks[index + 1]}
                                                      isHidden={scheduleWeekDisplay !== index}
                                                      setScheduleWeekDisplay={setScheduleWeekDisplay}
                                    />
                                )
                            })
                        }
                    </div>
                ) : (
                    <div className={'my-20'}>
                        <EmptyStateCompany type={'schedule'}/>
                    </div>
                )
            }
        </div>
    )
}

export default Schedule;