// *********** REGEX ***********
// Regex Name
module.exports.NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
//Regex Verify
module.exports.EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
module.exports.PWD_REGEX = /^(?=.{8,40}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/;

module.exports.MESSAGE_REGEX = /^[a-zA-Z\d?$@#()'!,+\-=_:.&€£*%\s]+$/

// *********** Function ***********
// Calcul de l'age selon une date en format string
module.exports.getAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

module.exports.checkPassword = (data) => {
    //javascript regular expression pattern
    const lower = new RegExp('(?=.*[a-z])')
    const upper = new RegExp('(?=.*[A-Z])')
    const number = new RegExp('(?=.*[0-9])')
    const special = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/)
    const length = new RegExp('(?=.{8,40})')
    const check = {
        lower: false,
        upper: false,
        number: false,
        special: false,
        length: false
    }
    check.lower = lower.test(data);
    check.length = length.test(data);
    check.upper = upper.test(data);
    check.number = number.test(data);
    check.special = special.test(data)

    return check
}

module.exports.delay = (ms) => new Promise(
    resolve => setTimeout(resolve, ms)
)

module.exports.getCookie = (key) => {
    const cookie = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return cookie ? cookie.pop() : cookie;
}

module.exports.dateDiff = (date) => {
    const diff = {}
    const date1 = new Date(date)
    const date2 = new Date()
    let diff_temps = date2 - date1
    diff_temps = Math.floor(diff_temps / 1000)
    diff.sec = diff_temps % 60
    diff_temps = Math.floor((diff_temps - diff.sec) / 60)
    diff.min = diff_temps % 60
    diff_temps = Math.floor((diff_temps - diff.min) / 60)
    diff.hour = diff_temps % 24
    diff.day = Math.floor((diff_temps - diff.hour) / 24)
    return diff
}

module.exports.dateDiffExp = (date) => {
    const ynew = date.end.getFullYear();
    const mnew = date.end.getMonth();
    const dnew = date.end.getDate();
    const yold = date.start.getFullYear();
    const mold = date.start.getMonth();
    const dold = date.start.getDate();
    let diff = date.end - date.start;
    if (mold > mnew) diff--;
    else {
        if (mold === mnew) {
            if (dold > dnew) diff--;
        }
    }
    return diff;
}

module.exports.statutMission = (date) => {
    const status = {}
    const d0 = this.dateToEpoch(new Date())
    const d1 = this.dateToEpoch(new Date(date?.datestart))
    const d2 = this.dateToEpoch(new Date(date?.dateend))
    // a venir
    if (d0.getTime() < d1.getTime()) {
        status.text = 'A venir'
        status.color = ''
    }
    // en cours
    else if ((d0.getTime() > d1.getTime()) && (d0.getTime() < d2.getTime())) {
        status.text = 'En cours'
        status.color = 'INFO'
    }
    // terminée
    else if (d0.getTime() > d2.getTime()) {
        status.text = 'Terminée'
        status.color = 'ALERT'
    }

    return status
}

module.exports.dateText = (d) => {
    const date = {}
    const month = {
        '01': 'Janv.',
        '02': 'Fev.',
        '03': 'Mars',
        '04': 'Avril',
        '05': 'Mai',
        '06': 'Juin',
        '07': 'Juil.',
        '08': 'Août',
        '09': 'Sept.',
        '10': 'Oct.',
        '11': 'Nov.',
        '12': 'Dec.',
    }
    const dayS = d?.datestart.split('/')

    date.start = dayS[0] + ' ' + month[dayS[1]] + ' ' + dayS[2]
    const dayE = d?.dateend.split('/')
    date.end = dayE[0] + ' ' + month[dayE[1]] + ' ' + dayE[2]
    return date
}

module.exports.dateExp = (d) => {
    const pD = new Date(d)
    const opt = {year: 'numeric', month: 'long'}
    return pD.toLocaleDateString('FR-fr', opt)
}

module.exports.dateWord = (d) => {
    const pD = new Date(d)
    const opt = {year: 'numeric', month: 'long', day: 'numeric',timeZone: "Europe/Paris"}
    return pD.toLocaleDateString('FR-fr', opt)
}
module.exports.dateWordShort = (d) => {
    const pD = new Date(d)
    const opt = {year: 'numeric', month: 'numeric', day: 'numeric',timeZone: "Europe/Paris"}
    return pD.toLocaleDateString('FR-fr', opt)
}


module.exports.tContract = (c) => {
    const tContract = {
        1: 'CDI',
        2: 'CDD',
        3: 'Interim',
        4: 'Saisonnier',
        5: 'Temps partiel'
    }
    return tContract[c]
}

module.exports.sBadge = (v) => {
    const type = {
        0: 'WARNING',
        1: 'VALID',
        2: 'ALERT'
    }
    return type[v]
}

module.exports.tBadge = (v) => {
    const type = {
        0: 'En attente',
        1: 'Validée',
        2: 'Refusée'
    }
    return type[v]
}

module.exports.pFormation = (f) => {
    const type = {
        'cap': 'CAP ou équivalents',
        'bep': 'BEP ou équivalents',
        "bac": "Bac (général, technique ou professionnel )ou équivalents",
        "bac_2": "Bac+2 (BTS, DUT ou équivalents)",
        "bac_3": "Bac+3(Licence ou équivalents)",
        "bac_4": "Bac+4 (Maitrise ou équivalents)",
        "bac_5": "Bac+5 et plus (Master ou équivalents)",
    }
    return type[f]
}

module.exports.tReplace = (t) => {
    return t.replace(' ', '_')
}

module.exports.dateToEpoch = (date) => {
    return new Date(new Date(date).toLocaleDateString("en-EN", {timeZone: "Europe/Paris"}));
}

module.exports.dateWordDay=(d)=>{
    const pD = new Date(d)
    const opt = {year: 'numeric', month: 'long', day: 'numeric',weekday:'long',timeZone: "Europe/Paris"}
    return pD.toLocaleDateString('FR-fr', opt)
}

module.exports.calculateHours = (period_start, period_end, substraction) => {
    const period_start_split = period_start.split(':');
    const period_end_split = period_end.split(':');
    const h1 = parseInt(period_start_split[0]);
    const m1 = parseInt(period_start_split[1]);
    const h2 = parseInt(period_end_split[0]);
    const m2 = parseInt(period_end_split[1]);

    const totalMinutes1 = h1 * 60 + m1; // Convertir la première heure en minutes totales
    const totalMinutes2 = h2 * 60 + m2; // Convertir la deuxième heure en minutes totales

    const differenceMinutes = substraction ? Math.abs(totalMinutes1 - totalMinutes2) : (totalMinutes1 + totalMinutes2); // Calculer la différence ou la somme en minutes

    const hours = Math.floor(differenceMinutes / 60); // Extraire le nombre d'heures
    const minutes = differenceMinutes % 60; // Extraire le nombre de minutes

    // Formatage du résultat
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
}

module.exports.formatHours = (hour) => {
    const hourSplit = hour.split(':');
    const h = hourSplit[0];
    const m = hourSplit[1];

    //if the fist letter of hour is '0' when his length is 2, remove it and if minutes is '00', remove it, add 0 before minute if length is 1 and add 'h' between hours and minutes
    return (h[0] === '0' && h.length === 2 ? h[1] : h) + 'h' + (m === '00' ? '' : (m.length === 1 ? '0' + m : m));
}

module.exports.hoursStringToHoursNumber = (hours) => {
    const hoursSplit = hours.split(':');
    const h = parseInt(hoursSplit[0]);
    const m = parseInt(hoursSplit[1]);

    return h + m / 60;
}