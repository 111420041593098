import React from 'react';
import {Tab, Tabs} from "components/tabs/tabs";
import {TabF, TabsF} from "components/tabs/tabfile";
import {useLocation} from "react-router-dom";

const FilesSubNav = () => {
    const route = useLocation()
    const {pathname}=route
    function subNav (p){
        if(p.includes('contract')){
            return <TabsF>
                <TabF label={"contract/toBeSigned"} tabName={'À signer'}/>
                <TabF label={"contract/signed"} tabName={'Signé'}/>
            </TabsF>
        }else if(p.includes('invoices')){
            return <TabsF>
                <TabF label={"invoices/toBeSet"} tabName={'À régler'}/>
                <TabF label={"invoices/settle"} tabName={'Réglé'}/>
            </TabsF>
        }else{
            return <></>
        }
    }
    return (
        <>
            <Tabs>
                <Tab label={"contract"} tabName={'Contrats'}/>
                <Tab label={'invoices'} tabName={'Factures'}/>
            </Tabs>
            {subNav(pathname)}
        </>
    );
};

export default FilesSubNav;