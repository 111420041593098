import React, {useEffect, useState} from 'react';
import ApiDataBase from 'server'
import UseContext from "hooks/useContext";
import Banner from "../../../components/banner/banner";


const LegalInformationContent = () => {
    const {companyData}=UseContext()
    const [selectConvention, setSelectConvention] = useState('')
    const [selectJuridique, setSelectJuridique] = useState('')
    const [conventions,setConventions]=useState([])
    const formJuridique = [
        {name: "EI"}, {name: "EIRL"}, {name: "EURL"}, {name: "SA"}, {name: "SARL"}, {name: "SAS"}, {name: "SASU"}]
    const jourOff = [
        {name: "Jour de l'an"},
        {name: "Lundi de pâques"},
        {name: "Fête du Travail"},
        {name: "Fête de la Victoire de 1945"},
        {name: "Jeudi de l'Ascension"},
        {name: "Lundi de pentecôte"},
        {name: "Fête nationale"},
        {name: "Assomption"},
        {name: "Toussaint"},
        {name: "Armistice de 1918"},
        {name: "Noël"},
        {name: "Abolition de l'esclavage Martinique"}]
    useEffect(()=>{
        ApiDataBase.getAllConventions(
            (data) => {
                setConventions(data)
            },
            (err) => {
                console.warn(err)
            }
        )
    },[])
    return (
        <div className={'flex flex-col mx-auto gap-8 w-[750px] border border-gray-300 p-4 rounded-md'}>
            <div className={'my-1'}>
                <Banner type={'info'}>
                    Un conseiller Jobbiz va rapidement vous joindre afin de compléter votre profil et recrutez vos premiers intérimaires sur Jobbiz.
                </Banner>
            </div>
            <div className={'ft-xl text-gray-900'}>Informations légales</div>
            <div className={'grid grid-cols-2 gap-4'}>
                <div>
                    <label
                        className='ft-sm text-gray-500'>Raison sociale</label>
                    <input type="text"
                           disabled
                           value={companyData?.reason_social}
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100  border rounded-lg'/>
                </div>
                <div></div>
                <div>
                    <label
                        className='ft-sm text-gray-500'>Siret</label>
                    <input type="text"
                           disabled
                           value={companyData?.siret}
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100  border rounded-lg'/>
                </div>
                <div>
                    <label
                        className='ft-sm text-gray-500'>Effectif</label>
                    <input type="text"
                           disabled
                           value={companyData?.staff}
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100  border rounded-lg'/>
                </div>
                <div><label
                    className='ft-sm text-gray-500'>Code APE</label>
                    <input type="text"
                           disabled
                           value={companyData?.code_ape}
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100  border rounded-lg'/>
                </div>
                <div>
                    <label
                        className='ft-sm text-gray-500'>Capital social</label>
                    <input type="text"
                           disabled
                           value={companyData?.capital ? companyData?.capital + ' €' : ''}
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100  border rounded-lg'/>
                </div>
                <div>
                    <label
                        className='ft-sm text-gray-500'>Forme juridique</label>
                    <input type="text"
                           disabled
                           value={companyData?.status}
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100  border rounded-lg'/>
                </div>
                <div>
                    <label
                        className='ft-sm text-gray-500'>N° convention collective</label>
                    <input type="text"
                           disabled
                           value={companyData?.conv_collective}
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100  border rounded-lg'/>
                </div>
                <div>
                    <label
                        className='ft-sm text-gray-500'>N° accord d’entreprise</label>
                    <input type="text"
                           disabled
                           value={companyData?.accord_company}
                           className='w-full h-10 pl-3 pr-8 ft-b bg-gray-100  border rounded-lg'/>
                </div>
            </div>

        </div>
    );
};

export default LegalInformationContent;