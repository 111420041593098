import React from 'react';
import NotificationNavDesktop from "./NavbarComponents/NotificationNavDesktop";
import LogoutNavDesktop from "./NavbarComponents/LogoutNavDesktop";
import MessagerieNavDesktop from "./NavbarComponents/MessagerieNavDesktop";
import {useLocation} from "react-router-dom";
import SwitchCompany from "./NavbarComponents/SwitchCompany";

const NavbarDesktop = () => {
    const route=useLocation()
    const {pathname}=route
    return (
        <>
            {
                pathname === '/adminCompany' ?
                    <LogoutNavDesktop/>
                    :
                    <>
                        <NotificationNavDesktop/>
                        <MessagerieNavDesktop/>
                        <SwitchCompany/>
                        <LogoutNavDesktop/>
                    </>
            }
        </>
    );
};

export default NavbarDesktop;