import React, {useEffect} from 'react';
import Button from "components/button";
import UseContext from "hooks/useContext";
import LoadingButton from "../../components/button/loading";

const FooterButton = ({oC1,oC2,isLoading}) => {
    const {page} = UseContext()

    const elements = {
        0: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        1: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        2: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        3: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        4: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        5: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        6: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        7: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        8: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        9: <Button size={'LG'} onClick={oC1}>Suivant</Button>,
        10: isLoading ? <LoadingButton size={"LG"}>Création de la mission</LoadingButton> : <Button size={'LG'} onClick={oC2}>Confirmer</Button>,
    }

    return (
        <>
            {elements[page]}
        </>
    );
};

export default FooterButton;