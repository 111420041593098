import React, { useEffect, useState } from "react";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import Modalheader from "components/modal/components/header";
import useModal from "components/modal/useModal";
import ModalBody from "components/modal/components/body";
import { Information } from "assets/icons";
import { MESSAGE_REGEX } from "helper/helper";
import UseContext from "hooks/useContext";

const Remplacement = () => {
    const { setData } = UseContext();
    const { toggle } = useModal();
    const [infos, setInfos] = useState({
        type: "3",
        name: "",
        prenom: "",
        poste: "",
        message: "",
    });
    const [message, setMessage] = useState("");
    const [lengthMessage, setLengthMessage] = useState(false);
    const [validMessage, setValidMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorPrenom, setErrorPrenom] = useState(false);
    const [errorPoste, setErrorPoste] = useState(false);

    const verif = infos.message.length;

    useEffect(() => {
        setValidMessage(MESSAGE_REGEX.test(infos.message));
        if (verif <= 8) {
            setErrorMessage(true);
            setLengthMessage(false);
        } else {
            setErrorMessage(false);
            setLengthMessage(true);
        }
    }, [infos.message, verif]);

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        const value = type === "checkbox" ? e.target.checked : e.target.value;

        setInfos((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        if (
            infos.message === "" ||
            infos.name === "" ||
            infos.prenom === "" ||
            infos.poste === ""
        ) {
            if (infos.message === "") setErrorMessage(true);
            else setErrorMessage(false);
            if (infos.name === "") setErrorName(true);
            else setErrorName(false);
            if (infos.prenom === "") setErrorPrenom(true);
            else setErrorPrenom(false);
            if (infos.poste === "") setErrorPoste(true);
            else setErrorPoste(false);
        } else {
            setErrorMessage(false);
            setErrorName(false);
            setErrorPrenom(false);
            setErrorPoste(false);
            setData((prevData) => ({ ...prevData, motif: infos }));
            toggle();
        }
    };
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Remplacement d’un salarié absent
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <div className={"grid grid-cols-2 gap-8"}>
                    <div>
                        <label
                            htmlFor="name"
                            className="block ft-sm font-medium text-gray-500"
                        >
                            Nom
                        </label>
                        <div className="relative text-gray-500">
                            <input
                                name="name"
                                className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                onChange={handleChange}
                                required
                            />
                            {errorName ? (
                                <p className="text-yellow-400 flex  items-center gap-4">
                                    <Information wh={16} color={"#FFA43D"} />
                                    Veuillez indiquer un nom
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="prenom"
                            className="block ft-sm font-medium text-gray-500"
                        >
                            Prénom
                        </label>
                        <div className="relative text-gray-500">
                            <input
                                name="prenom"
                                className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                onChange={handleChange}
                                required
                            />
                            {errorPrenom ? (
                                <p className="text-yellow-400 flex  items-center gap-4">
                                    <Information wh={16} color={"#FFA43D"} />
                                    Veuillez indiquer un prénom
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className={"col-span-2"}>
                        <label
                            htmlFor="poste"
                            className="block ft-sm font-medium text-gray-500"
                        >
                            Poste remplacé
                        </label>
                        <div className="relative text-gray-500">
                            <input
                                name="poste"
                                className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg "
                                type="text"
                                onChange={handleChange}
                                required
                            />
                            {errorPoste ? (
                                <p className="text-yellow-400 flex  items-center gap-4">
                                    <Information wh={16} color={"#FFA43D"} />
                                    Veuillez indiquer un poste
                                </p>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-5 flex flex-col">
                    <label htmlFor="message">
                        Décrivez précisément le motif de l’absence de votre
                        salarié.
                    </label>
                    <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="5"
                        className="border border-gray-300 rounded-md"
                        required
                        maxLength={250}
                        minLength={3}
                        wrap="true"
                        onChange={handleChange}
                    ></textarea>
                </div>
                <span className="text-gray-400 nbLength">250 caractères </span>
                {errorMessage ? (
                    <p className="text-yellow-400 flex  items-center gap-4">
                        <Information wh={16} color={"#FFA43D"} />
                        Veuillez faire un message de plus de 10 caractères{" "}
                    </p>
                ) : (
                    ""
                )}
            </ModalBody>
            {/*footer*/}
            <ModalFooter>
                <Button size={"LG"} color={"SECONDARY"} onClick={toggle}>
                    Annuler
                </Button>
                <Button size={"LG"} onClick={handleSubmit}>
                    Confirmer
                </Button>
            </ModalFooter>
        </>
    );
};

export default Remplacement;
