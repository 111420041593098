import React from 'react';
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import NotificationUnit from "components/Notifications/NotificationUnit";
import useModal from "components/modal/useModal";


const ModalNotification = () => {
    const {toggle} = useModal()

    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Notifications
            </Modalheader>
            {/*body*/}
            <ModalBody>
                <NotificationUnit/>
                <NotificationUnit/>
                <NotificationUnit/>
                <NotificationUnit/>
            </ModalBody>

        </>
    );
};

export default ModalNotification;