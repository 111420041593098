import React, {useEffect, useState} from 'react';
import Button from "components/button";
import {ChevronRight, Close, CloseModal, InformationIcon} from "assets/icons";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import Tooltip from "components/tooltips/Tooltips";
import {calculateHours, formatHours, hoursStringToHoursNumber} from "../../helper/helper";
import ApiDatabase from "server";

const CreateMissionSubNav = () => {
    const {page, missionCostEstimate, setMissionCostEstimate, data, companyData}=UseContext()
    const [totalHours, setTotalHours] = useState('00:00')
    const [totalHoursNumber, setTotalHoursNumber] = useState(0)
    const [coef, setCoef] = useState(0)
    const {toggle} = useModal()
    const token = localStorage.getItem('xsrfToken')

    const handleClose = (e) => {
        e.preventDefault()
        toggle('cM_close_process')
    }

    useEffect(() => {
        if(data && companyData && coef !== null){
            if(data.schedule.length > 0 && parseFloat(data.salary) && data.isScheduleCheck){
                setMissionCostEstimate(0)
                setTotalHoursNumber(0)
                let total_hours_string = '00:00'
                let cost_estimate = 0
                for (let i = 0; i < data.schedule.length; i++) {
                    if(data.schedule[i].days?.length){
                        for (let j = 0; j < data.schedule[i].days.length; j++) {
                            if(data.schedule[i].days[j].periods){
                                const period_one_start = data.schedule[i].days[j].periods.periode_one_start ?? '00:00'
                                const period_one_end = data.schedule[i].days[j].periods.periode_one_end ?? '00:00'
                                const period_two_start = data.schedule[i].days[j].periods.periode_two_start ?? '00:00'
                                const period_two_end = data.schedule[i].days[j].periods.periode_two_end ?? '00:00'
                                const period_three_start = data.schedule[i].days[j].periods.periode_three_start ?? '00:00'
                                const period_three_end = data.schedule[i].days[j].periods.periode_three_end ?? '00:00'

                                const period_one_hours = calculateHours(period_one_start, period_one_end, true)
                                const period_two_hours = calculateHours(period_two_start, period_two_end, true)
                                const period_three_hours = calculateHours(period_three_start, period_three_end, true)

                                const period_first = calculateHours(period_one_hours, period_two_hours, false)
                                const total_hours = calculateHours(period_first, period_three_hours, false)
                                const total_hours_number = hoursStringToHoursNumber(total_hours)

                                total_hours_string = calculateHours(total_hours_string, total_hours, false)
                                cost_estimate += (total_hours_number * parseFloat(data.salary))
                            }
                        }
                    }
                }
                setTotalHours(formatHours(total_hours_string))

                if(hoursStringToHoursNumber(total_hours_string) <= 4) cost_estimate = '100.00'
                else if (hoursStringToHoursNumber(total_hours_string) > 4 && hoursStringToHoursNumber(total_hours_string) <= 7) cost_estimate = '175.00'
                else cost_estimate = new Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(cost_estimate * coef).replace(',', '.')

                setTotalHoursNumber(hoursStringToHoursNumber(total_hours_string))
                setMissionCostEstimate(cost_estimate)
            } else {
                setMissionCostEstimate(0)
            }
        }
    }, [data, coef])

    const getCoefficient = () => {
        ApiDatabase.getBestCoefficient({token: token, wagePerHour: data.salary.replace(',', '.'), idCompany: companyData._id}, (res) => {
            if(res !== null){
                const coefficient = parseFloat(res)
                setCoef(coefficient)
            } else {
                setCoef(res)
            }
        }, (error) => {
            setCoef(null)
            console.log(error)
        })
    }

    useEffect(() => {
        if(data.salary) getCoefficient()
        else setCoef(0)
    }, [data.salary])

    const missionPages = ['Secteur d’activité', 'Pré-requis', 'Motif de recours', 'Lieu & Date', 'Planning', 'Rémunération', 'Invitation', 'Responsable', 'Informations sup.', 'Confirmation']

    useEffect(() => {
        //trigger function to use scrollToView on the selected div in react
        const scrollToView = (item) => {
            const element = document.getElementsByClassName(`mission_page_${page}`)[item]
            element?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
        }
        scrollToView(page)
    }, [page])

    return (
        <>
            {
                (page > 0 && page < 10) && (
                    <div className={'flex justify-between px-5 gap-3 border-b select-none min-h-[66px] max-h-[66px]'}>
                        <div className={'my-auto flex gap-1 whitespace-nowrap overflow-x-auto no-scrollbar ft-sm pr-5 py-4'}>
                            {
                                missionPages.map((missionPage, index) => (
                                    <div key={index} className={"flex items-center gap-1"}>
                                        <div className={`mission_page_${page} my-auto py-2 px-3 border rounded-lg ${(page - 1 === index) ? 'bg-yellow-50 border-yellow-300 text-yellow-500' : ((page - 1 > index) ? 'bg-yellow-50 text-yellow-500 border-white' : 'bg-gray-50 border-white')}`}>
                                            {missionPage}
                                        </div>
                                        {
                                            (index < missionPages.length - 1) && (
                                                <div className={'my-auto'}>
                                                    <ChevronRight wh={16} color={'#6B7280'}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        <div className={'my-auto flex gap-2 py-4 border-l pl-3'}>
                            <div className={'my-auto flex gap-1 min-w-[200px]'}>
                                {
                                    <Tooltip text={`${
                                        (missionCostEstimate && coef !== null) ?
                                            (
                                                (totalHoursNumber <= 4) ? '(Calcul basé sur des heures de travail normales) BETA \n\nTarif forfaitaire de 100€ appliqué pour les missions de moins de 4h' : (
                                                    (totalHoursNumber > 4 && totalHoursNumber <= 7) ? '(Calcul basé sur des heures de travail normales) BETA \n\nTarif forfaitaire de 175€ appliqué pour les missions de moins de 7h' : `(Calcul basé sur des heures de travail normales) BETA \n\n Heures normales :\n ${totalHours} x ${data.salary}€ x ${coef}`
                                                )
                                            ) : 
                                            'Obtenez une estimation de coût en ajoutant un planning et une rémunération horaire. BETA'}`
                                    }
                                             placement="bottom"
                                    >
                                        <div className={'flex flex-col'}>
                                            <div className={'flex gap-1'}>
                                                <div className={'my-auto'}>
                                                    <InformationIcon wh={(missionCostEstimate || coef === null) ? 16 : 20} color={'#374151'}/>
                                                </div>
                                                <div className={`my-auto ${(missionCostEstimate || coef === null) ? 'text-gray-500 ft-xs' : 'ft-sm'}`}>
                                                    {(missionCostEstimate || coef !== null) ? 'Estimateur de coût :' : 'Estimateur de coût'}
                                                </div>
                                            </div>
                                            <div className={`flex ${(!missionCostEstimate && coef !== null) && 'hidden'}`}>
                                                <div className={`${coef !== null && 'border-b border-gray-700 border-dashed'}`}>
                                                    {(missionCostEstimate && coef !== null) ? '€ ' + missionCostEstimate : 'Bientôt disponible'}
                                                </div>
                                            </div>
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                            <div className={'my-auto'}>
                                <div className={'cursor-pointer border rounded p-1'}>
                                    <Close wh={20} color={'#374151'} onClick={handleClose}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default CreateMissionSubNav;