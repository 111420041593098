import React, {useEffect, useState} from 'react';
import UseContext from "hooks/useContext";
import UseModal from "components/modal/useModal";
import ApiDatabase from "server";
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import TBodyHoursCheck
    from "pages/timeSheet/components/tBodyHoursCheck";
import {dateToEpoch} from "../../../../../helper/helper";

const Checked = () => {
    const {
        objID,
        refreshTime
    } = UseContext()
    const [datas, setDatas] = useState([])
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }
    const {toggle} = UseModal()


    useEffect(() => {
        ApiDatabase.getTimeSheetInfo({id: objID},
            (data) => {
                setDatas(data[0])
            },
            (err) => {
                console.log(err)
            }
        )
    }, [refreshTime, objID])
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                {
                    Object.keys(datas).length > 0 && (
                        <>
                            <h1 className={'ft-2xl font-medium text-gray-900'}>
                                {datas?.mission.title} n°{datas?.id_time_sheet} - {datas?.user?.firstname} {datas?.user?.lastname}
                            </h1>
                        </>
                    )
                }
            </Modalheader>
            <ModalBody>
                {
                    Object.keys(datas).length > 0 && (
                        <>
                            <p className={'mb-4'}>
                                Consulter les heures travaillées par {datas?.user?.firstname} {datas?.user?.lastname} pour la mission {datas?.mission.title} n°{datas?.mission.id_mission}, la semaine du {dateToEpoch(datas?.periode_start).getDate()} au {dateToEpoch(datas?.periode_end).toLocaleDateString('fr-FR', options)}.
                            </p>
                            <div className={'flex items-center justify-between mb-4'}>
                                <div className={'flex gap-6 items-center ft-sm text-gray-700 font-medium'}>
                                    JOUR
                                    <span className={'text-gray-500 uppercase'}>{datas?.total_hours_ent ? `heures travaillées (${datas?.total_hours_ent})` : null}  </span>
                                </div>
                            </div>
                            <TBodyHoursCheck champsBody={datas}/>
                        </>
                    )
                }
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'}
                        onClick={toggle}>Annuler</Button>
                <Button size={'LG'}
                        onClick={toggle}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};

export default Checked;