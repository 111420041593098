import React from 'react';
import EmptyState from "components/emptyState";

const BonusesCompensationContent = () => {
    return (
        <>
            <EmptyState type={'coming'}/>
        </>
    );
};

export default BonusesCompensationContent;