import React, {useState} from 'react';
import Modalheader from "components/modal/components/header";
import UseModal from "components/modal/useModal";
import ModalBody from "components/modal/components/body";
import Banner from "components/banner/banner";
import {CheckIcon} from "assets/icons";
import ModalFooter from "components/modal/components/footer";
import Button from "components/button";
import ApiDatabase from 'server'
import UseContext from "hooks/useContext";
import {toast} from "react-toastify";


const ValidateTime = ({oc1}) => {
    const {
        objID,
        setRefreshTime,
        refreshTime,
        timeCheck,
    } = UseContext()
    const {toggle} = UseModal()
    const [check, setCheck] = useState(false)
    const [err, setErr] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const token = localStorage.getItem('xsrfToken')

    const handleCheck = (e) => {
        setCheck(!check)
        if (err) setErr(false)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (check) {
            setErr(false)

            if (objID && timeCheck) {
                ApiDatabase.postUpdateTimeSheet({id:objID,time:timeCheck, token},
                    (data)=>{
                        setRefreshTime(!refreshTime)
                        toggle()
                        toast.success('Le relevé d\'heure "Relevé d\'heure" est complétée.')
                        setIsLoading(false)
                    },
                    (err)=>{
                        setIsLoading(false)
                        console.log(err)})
            } else {
                toast.error('Une erreur est survenue.')
                setIsLoading(false)
            }
        } else {
            setErr(true)
        }

    }


    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                <h1 className={'ft-2xl font-medium text-gray-900'}>
                    Confirmer les horaires
                </h1>
            </Modalheader>
            <ModalBody>
                <Banner type={'alert'}>
                    Une fois les heures confirmées, il est impossible
                    de les contester.
                </Banner>
                <div
                    className={`w-full ft-sm mt-4 flex items-start justify-center gap-4 border b-gray-50 p-2 rounded-md cursor-pointer ${err ? 'border-red-700 border-2' : null}`}
                    onClick={handleCheck}>
                    <div
                        className={`flex items-center justify-center mt-1 h-4 w-4 border border-gray-300 rounded-sm bg-white ${check ? 'bg-yellow-600 border-yellow-600' : ''} ${err ? 'border-red-700 border-2' : null} focus:outline-none transition duration-200  cursor-pointe `}>{check ?
                        <CheckIcon w={14} h={10}
                                   color={'#ffff'}/> : ''}</div>
                    <div className={'flex flex-col w-[500px]'}>
                        <span
                            className={`ft-sm font-bold text-gray-700 ${err ? 'text-red-700' : null} `}>Attestation sur l’honneur</span>
                        <p className="ft-sm font-medium text-gray-500">
                            J’ai lu et vérifié l’ensemble des
                            informations présentes sur ce relevé
                            d’heures.
                        </p>

                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size={'LG'} color={'SECONDARY'} onClick={oc1}>Annuler</Button>
                {isLoading ? <Button size={'LG'} color={'DISABLED'} disabled>Confirmation ...</Button> : <Button size={'LG'} onClick={handleSubmit}>Confirmer</Button>}
            </ModalFooter>
        </>
    );
};

export default ValidateTime;