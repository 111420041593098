import React, {useState, useCallback, useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

function Tabs({children}) {
    const pathname = useLocation().pathname;
    const navigate = useNavigate()
    const navigateLink = (label) => {
        if(label === 'contract'){
            return 'contract/toBeSigned'
        }
        else if(label === 'invoices'){
            return 'invoices/toBeSet'
        }
        else{
            return label
        }
    }

    const tabs = children.map(child => (

        <div onClick={e => {
            e.preventDefault()
            navigate(`./${navigateLink(child.props.label)}`,{replace:true})
        }}
             className={`inline-block  py-3 px-4 cursor-pointer ${pathname.includes(child.props.label) ? 'border-b-2 border-b-yellow-500 text-yellow-500' : 'hover:border-b-2 hover:border-b-gray-500'}`}
             key={child.props.label}
        >
            {child.props.tabName}
        </div>
    ))

    const tabContent = children.filter(child => pathname.includes(child.props.label))
    return (
        <div>
            <div className='ml-3 flex gap-4  bg-white border-b-0 border-gray-100'>{tabs}</div>
            <div className=''>{tabContent}</div>
        </div>
    )
}

function Tab(props) {
    return <>{props.children}</>
}

export {Tabs, Tab}