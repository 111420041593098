import React from 'react';
import Chip from "components/chip/chip";
import {DocumentIcon, Signer} from "assets/icons";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import ApiDatabase from "server"
import {dateWordShort} from "helper/helper";

const Tbodycontract = ({champsBody}) => {
    const {toggle} = useModal()
    const {
        setSignatureLink,
        setDataYousign
    } = UseContext()
    const rUser = localStorage.getItem('rUser')


    const colorContract = {
        '0': 'ALERT',
        '1': 'INFO'
    }
    const contentContract = {
        '0': 'À signer',
        '1': 'Signé'
    }
    const handleSign = (e, contract, item) => {
        e.preventDefault()

        ApiDatabase.postSignature({
                id_mission: item,
                contract,
                token: localStorage.getItem('xsrfToken')
            },
            (data) => {
                setDataYousign({
                    yousign_id: data.pYousignId,
                    contract_id: contract._id,
                    mission_id: item,
                    filename: contract.fileName,
                    filepath: contract.filePath
                })
                setSignatureLink(data.pSignatureYousign)
                toggle('doc_c_signature')
            },
            (err) => {
                console.log(err)
            })
    }

    return (
        <>
            {champsBody.map((item, key) =>
                <tr key={key} className={'border-b-2 border-gray-100 '}>
                    <td className={'py-5'}>{item?.contract_company[0]?.id_bestt ?? null}</td>
                    <td className={'py-5'}>{item?.userCollection[0]?.firstname ?? null} {item.userCollection[0]?.lastname ?? null}</td>
                    <td className={'py-5'}>{item?.title ?? null}</td>
                    <td className={'py-5'}>{item?.id_mission ?? null}</td>
                    {item?.contract_company?.map((contract, key) =>
                        <td key={key} className={'py-5'}>
                            <Chip type={'INDICATOR'} color={colorContract[contract.status]}>{contentContract[contract.status]}</Chip>
                        </td>
                    )}
                    {item?.contract_company?.map((contract, key) =>
                        <td key={key} className={'py-5'}>
                            {dateWordShort(contract.createdAt)}
                        </td>)}
                    {item?.contract_company?.map((contract, key) =>
                        <td key={key} className={'py-5 flex items-center justify-center cursor-pointer'}>
                            {
                                contract.status === '0' && rUser !== '200' ?
                                    <span onClick={(e) => {
                                        handleSign(e, contract, item?.id_mission)
                                    }}>
                                        <Signer wh={24}/>
                                    </span>
                                    :
                                    <a target='_blank' href={`${process.env.REACT_APP_COMPANY_PDF}${contract.filePath}`}>
                                        <DocumentIcon wh={24} color={'#111827'}/>
                                    </a>
                            }
                        </td>
                    )}
                </tr>
            )}
        </>
    );
};

export default Tbodycontract;