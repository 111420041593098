import React, {useEffect, useState} from "react";
import ApiDatabase from "../../server";
import {toast} from "react-toastify";
import UseContext from "../../hooks/useContext";
import {NegativeThumb, PositiveThumb} from "../../assets/icons";
import Badge from "../badge/badge";
import ExternalTooltip from "../tooltips/ExternalTooltip";
import useModal from "../modal/useModal";

const RatingTempWorker = ({review, isTutoCanBeDisplayed, idMission, idTempWorker, idTimeSheet}) => {
    const {userDatas, companyData, setIdTempWorker, setIdMission, setIdTimeSheet, updateRatingTempWorker, setUpdateRatingTempWorker} = UseContext()
    const [reviewTempWorker, setReviewTempWorker] = useState(review)
    const {toggle}=useModal()

    const token = localStorage.getItem('xsrfToken')
    const [confirmTutoRateProfileCompany, setConfirmTutoRateProfileCompany] = useState(false);
    const [onClickConfirm, setOnClickConfirm] = useState(false);

    useEffect(() => {
        if(onClickConfirm && !confirmTutoRateProfileCompany){
            if(token){
                ApiDatabase.putCheckTutoRateProfileCompany({token}, (data) => {
                    setConfirmTutoRateProfileCompany(true)
                }, (err) => {
                    setConfirmTutoRateProfileCompany(false)
                })
            } else {
                toast.error('Une erreur est survenue, veuillez réessayer plus tard.')
            }
        }
    }, [onClickConfirm]);

    useEffect(() => {
        if(Object.keys(userDatas).length > 0){
            setConfirmTutoRateProfileCompany(userDatas.tutoRateProfileCompany ?? false)
        }
    }, [userDatas]);

    const toggleModalRatingTempWorker = () => {
        if(idTempWorker) setIdTempWorker(idTempWorker)
        if(idMission) setIdMission(idMission)
        if(idTimeSheet) setIdTimeSheet(idTimeSheet)
        toggle('rating_temp_worker')
    }

    useEffect(() => {
        if(updateRatingTempWorker){
            ApiDatabase.getTempWorkerRating({token, idCompany: companyData?._id, idTempWorker, idMission}, (data) => {
                setReviewTempWorker(data)
                setUpdateRatingTempWorker(false)
            }, (err) => {
                console.log(err)
            })
        }
    }, [updateRatingTempWorker]);

    return (
        <>
            {reviewTempWorker ? (
                <>
                    {
                        reviewTempWorker.type ? (
                            reviewTempWorker.type === 'positive' ? (
                                <div onClick={() => toggleModalRatingTempWorker()}>
                                    <PositiveThumb wh={24} color={'#FF9600'}/>
                                </div>
                            ) : (
                                <div onClick={() => toggleModalRatingTempWorker()}>
                                    <NegativeThumb wh={24} color={'#6B7280'}/>
                                </div>
                            )
                        ) : (
                            <>
                                {
                                    (confirmTutoRateProfileCompany || !isTutoCanBeDisplayed ) ? (
                                        <div onClick={() => toggleModalRatingTempWorker()}>
                                            <Badge type={'DEFAULT'}>Retravailler</Badge>
                                        </div>
                                    ) : (
                                        <ExternalTooltip title={'Retravailler avec un candidat'}
                                                         description={'Partagez vos avis sur les candidats pour des recommandations plus ciblées pour vos futures missions.'}
                                                         confirm={confirmTutoRateProfileCompany}
                                                         setOnClickConfirm={setOnClickConfirm}
                                        >
                                            <Badge type={'DEFAULT'}>Retravailler</Badge>
                                        </ExternalTooltip>
                                    )
                                }
                            </>
                        )
                    }
                </>
            ) : (
                <>
                    {
                        (confirmTutoRateProfileCompany || !isTutoCanBeDisplayed ) ? (
                            <Badge type={'DEFAULT'} onClick={() => toggle('rating_temp_worker')}>Retravailler</Badge>
                        ) : (
                            <ExternalTooltip title={'Retravailler avec un candidat'}
                                             description={'Partagez vos avis sur les candidats pour des recommandations plus ciblées pour vos futures missions.'}
                                             confirm={confirmTutoRateProfileCompany}
                                             setOnClickConfirm={setOnClickConfirm}
                            >
                                <Badge type={'DEFAULT'}>Retravailler</Badge>
                            </ExternalTooltip>
                        )
                    }
                </>
            )}
        </>
    )
}

export default RatingTempWorker;